import * as React from 'react'
import ReactDOM from 'react-dom'
import { inject, observer } from 'mobx-react'
import { ModalMessageUi } from './ui'

const ModalMessage = ({
  content,
  setModalContent
}: {
  content?: any
  setModalContent?: any
}) => {
  if (!content) return null
  const clearMessage = () => {
    setModalContent(null)
  }
  return ReactDOM.createPortal(
    <ModalMessageUi content={content} onClose={clearMessage}></ModalMessageUi>,
    document.getElementById('modal-portal')
  )
}

export default inject(({ appStore }) => {
  return {
    content: appStore.modalContent,
    setModalContent: appStore.setModalContent
  }
})(observer(ModalMessage))
