import * as React from 'react'

import Option from 'atoms/Option'
import Section from 'atoms/Form/Section'
import Radio from 'atoms/Form/Radio'
import { IStepContent } from 'organisms/Main'
import { FormikProps, connect } from 'formik'
import BoxContainer from 'atoms/Form/BoxContainer'
import QuantityOption from 'molecules/Step/QuantityOption'
import CalendarPicker from 'atoms/CalendarPicker'
import NextDatePicker from 'atoms/NextDatePicker'
import { IBuyExperienceForm } from '../BuyExperience/index'
import { currencyToString } from 'tools/currency'
import TailorYourExperience from 'molecules/TailorYourExperience'
import { IAvailability } from 'stores/Availability'
import { IDateRange } from 'stores/Availability/DateRange'
import { inject, observer } from 'mobx-react'
import { IBasket } from 'stores/Basket'
import { ICatalogue } from 'stores/Catalogue'
import { debounce, find, slice } from 'lodash'
import { IAppStore } from 'stores/AppStore'
import { Disableable } from 'atoms/Disableable'
import { isSameDay, isEqual } from 'date-fns'
import { gteAddToCart, gteRemoveFromCart } from 'tools/analytics'

enum ParamChangeOption {
  add = 'add',
  remove = 'remove'
}

interface IState {
  selectedVehicle: boolean
}

class ExperienceDetails extends React.Component<
  IStepContent & { formik: FormikProps<IBuyExperienceForm> } & {
    availability?: IAvailability
    basket?: IBasket
    appStore?: IAppStore
    advocacyVariant?: boolean
  },
  IState
> {
  constructor(
    props: IStepContent & { formik: FormikProps<IBuyExperienceForm> }
  ) {
    super(props)
    this.resetAvailability = debounce(this.resetAvailability, 400)
    this.handleParameterChange = debounce(this.handleParameterChange, 400)
    this.state = {
      selectedVehicle: false
    }
  }

  // Load availability and merge with old results
  resetAvailability = async () => {
    const { availability, formik } = this.props
    await availability!.resetAvailability({
      parameters: formik.values.parameters
    })
  }

  handleMonthChange = (date: Date) => {
    // Navigation has occurred in calendar,
    //  load availability for months
    this.props.availability!.loadMonthAvailability(date)
  }
  handleParameterChange = async (type: ParamChangeOption) => {
    await this.resetAvailability()
    if (type === ParamChangeOption.add) {
      gteAddToCart()
    }
    if (type === ParamChangeOption.remove) {
      gteRemoveFromCart()
    }
  }

  checkInventoryAndResetParamsIfNotEnough = (newInventory: number) => {
    const { formik, basket } = this.props
    const { mainItem } = basket
    const entry = mainItem

    if (!entry.hasEnoughInventory(newInventory)) {
      let message: string
      if (!newInventory) {
        message = 'There are no open places for the selected time slot.'
      } else if (newInventory === 1) {
        message = 'There is only one place left for the selected time slot.'
      } else {
        message = `There are only ${newInventory} places open for the selected time slot.`
      }
      this.props.appStore.setModalContent({
        title: 'Not enough inventory',
        content: message
      })
      formik.setFieldValue('parameters', entry.sku.defaultParameters)
    }
  }

  parameterPriceForDate = (paramId: string, dateRange?: IDateRange): string => {
    if (dateRange && dateRange.parameters) {
      const parameter = find(
        dateRange.parameters,
        param => param.name === paramId
      )
      return parameter ? `(${currencyToString(parameter.price)})` : ''
    }

    return ''
  }

  handleSelectDate = (dateRange?: IDateRange) => {
    const { formik, basket } = this.props
    const { mainItem } = basket
    const entry = mainItem!
    // Set next available date and time from next available
    formik.setFieldValue('date', dateRange ? dateRange.date : undefined)
    formik.setFieldValue(
      'hasTimeSelection',
      dateRange
        ? !!dateRange.eventTimes && !!dateRange.eventTimes.length
        : undefined
    )

    this.props.basket!.mainItem!.setSelectedDateRange(dateRange)

    if (dateRange && dateRange.eventTimes) {
      const nextAvailableTime =
        dateRange.eventTimes.length && dateRange.eventTimes[0]
      // Select automatically next time available
      formik.setFieldValue(
        'startTime',
        nextAvailableTime ? nextAvailableTime.startTime : undefined
      )
      formik.setFieldValue(
        'endTime',
        nextAvailableTime ? nextAvailableTime.endTime : undefined
      )
      this.checkInventoryAndResetParamsIfNotEnough(nextAvailableTime.inventory)
      entry.setSelectedTimeRange(nextAvailableTime)
    }
  }

  calculateMaxForParameter({
    parameterMax,
    leftToAllocate,
    value,
    inventoryLinked
  }: {
    parameterMax: number
    leftToAllocate: number
    value: number
    inventoryLinked: boolean
  }): number {
    if (!inventoryLinked) {
      return parameterMax
    }
    return Math.min(parameterMax, leftToAllocate + value)
  }

  render() {
    const {
      t,
      appStore,
      basket,
      formik,
      availability,
      advocacyVariant
    } = this.props
    const { values, setFieldValue } = formik
    const { mainItem } = basket
    const entry = mainItem
    let addOns = entry && entry.addOns

    const availableTimetable = availability!.available

    const multidayTimetable = availableTimetable.filter(
      dateRange => dateRange.type === 'day'
    )

    let nextFiveDays: IDateRange[]
    if (multidayTimetable) {
      nextFiveDays = slice(multidayTimetable, 1, 6)
    }

    const selectedDateRange = availability!.dateRangeForDate(values.date)

    let totalParameterValues = 0
    if (entry.sku.parameters) {
      totalParameterValues = entry.sku.parameters.reduce((memo, item) => {
        if (item.inventoryLinked) {
          memo += formik.values.parameters[item.id]
        }
        return memo
      }, 0)
    }
    const baseInventory: number | null =
      entry && entry.selectedTimeRange && entry.selectedTimeRange.inventory
    let leftToAllocate: number | undefined

    if (baseInventory) {
      leftToAllocate = baseInventory - totalParameterValues
    }

    return (
      <Disableable disabled={this.props.appStore.hasPendingRequests}>
        {advocacyVariant && (
          <BoxContainer className="pb-0 mb-0">
            <div className="d-flex">
              <h3 className="text-uppercase mr-3 sh-1 ls-8 m-0">Centre: </h3>
              <h3 className="text-uppercase sh-1 ls-8 m-0">
                {appStore.configuration.centre}{' '}
              </h3>
            </div>
          </BoxContainer>
        )}
        <BoxContainer>
          <Section
            title={t('content.select.date', {
              defaultValue: 'Select a date'
            })}
          >
            <NextDatePicker
              dateRange={availableTimetable[0]}
              className="mb-5"
              selected={isSameDay(
                values.date,
                availableTimetable[0] && availableTimetable[0].date
              )}
              onClick={() => {
                this.handleSelectDate(availableTimetable[0])
              }}
            />
            {nextFiveDays &&
              nextFiveDays.map(dateRange => {
                const label = dateRange.toString({ showYear: true })

                return (
                  <Option key={label} optional label={label}>
                    <Radio
                      id={label}
                      checked={isSameDay(values.date, dateRange.date)}
                      name="dateRange"
                      aria-labelledby={label}
                      onChange={() => {
                        this.handleSelectDate(dateRange)
                      }}
                    />
                  </Option>
                )
              })}
            <CalendarPicker
              onMonthChange={this.handleMonthChange}
              availability={availability!}
              selectedDateRange={selectedDateRange}
              onDateClick={dateRange => {
                this.handleSelectDate(dateRange)
              }}
            />
          </Section>
          {/* Render time slot select if there are times to select */}
          {!!selectedDateRange && !!selectedDateRange.eventTimes.length && (
            <Section
              title={t('content.select.time', {
                defaultValue: 'select a time'
              })}
              role="radiogroup"
            >
              {selectedDateRange.eventTimes.map(time => {
                const label = time.asString

                return (
                  <Option key={label} optional label={label}>
                    <Radio
                      id={label}
                      checked={isEqual(values.startTime, time.startTime)}
                      name="time"
                      aria-labelledby={label}
                      onChange={() => {
                        setFieldValue('startTime', time.startTime)
                        setFieldValue('endTime', time.endTime)
                        this.checkInventoryAndResetParamsIfNotEnough(
                          time.inventory
                        )
                        entry.setSelectedTimeRange(time)
                      }}
                    />
                  </Option>
                )
              })}
            </Section>
          )}

          {!!entry.sku.parameters &&
            !!entry.sku.parameters.filter(
              (parameter: any) => parameter.isVisible
            ).length && (
              <Section
                title={t('content.select.quantity', {
                  defaultValue: 'select quantity'
                })}
              >
                {entry.sku.parameters.map((parameter: any) =>
                  parameter.isVisible ? (
                    <QuantityOption
                      key={parameter.id}
                      name={`parameters.${parameter.id}`}
                      min={parameter.minValue}
                      max={this.calculateMaxForParameter({
                        parameterMax: parameter.maxValue,
                        leftToAllocate: leftToAllocate || 0,
                        value: formik.values.parameters[parameter.id],
                        inventoryLinked: parameter.inventoryLinked
                      })}
                      optional={!parameter.required}
                      onAdd={() => {
                        this.handleParameterChange(ParamChangeOption.add)
                      }}
                      onRemove={() => {
                        this.handleParameterChange(ParamChangeOption.remove)
                      }}
                      parameterName={parameter.id}
                    >
                      {`${parameter.label} ${this.parameterPriceForDate(
                        parameter.id,
                        selectedDateRange
                      )}`}
                    </QuantityOption>
                  ) : null
                )}
              </Section>
            )}

          {!advocacyVariant && <TailorYourExperience addOns={addOns} />}
        </BoxContainer>
      </Disableable>
    )
  }
}

export default inject(({ appStore }) => {
  return {
    availability: appStore && (appStore.availability as IAvailability),
    basket: appStore && (appStore.basket as IBasket),
    appStore: appStore as IAppStore,
    catalogue: appStore && (appStore.catalogue as ICatalogue)
  }
})(connect<any, any>(observer(ExperienceDetails)))
