/* tslint:disable:max-line-length */
import * as React from 'react'
import { Fragment } from 'react'
import styled from 'tools/theme'
import classnames from 'classnames'
import WarningIcon from 'atoms/Icons/Warning'
import { translate, InjectedTranslateProps } from 'react-i18next'

export enum BeforeStartVariants {
  BuyExperience,
  VoucherFlow
}

const BeforeStartContainer = styled.div`
  background-color: ${props => props.theme.extraLightGrey};
  padding: 2rem;

  h3 span {
    display: inline-block;
    margin-left: 1.5rem;
  }

  p {
    font-weight: 900;
    margin-bottom: 0.4rem;

    &.description {
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const BeforeStartInfo: React.SFC<
  {
    className?: string
    variant: BeforeStartVariants
    text?: string
  } & InjectedTranslateProps
> = ({ className, t, variant, text }) => {
  const BuyExperienceContent = (
    <Fragment>
      <h3>
        <WarningIcon />
        <span className="text-uppercase ls-8">
          {t('beforeStartInfo.title', {
            defaultValue: 'Before you start'
          })}
        </span>
      </h3>

      {text &&
        text.split('\n').map((line, index) => (
          <p className="description" key={index}>
            {line}
          </p>
        ))}
      {!text && (
        <>
          <p className="description">
            {t('beforeStartInfo.description', {
              defaultValue:
                'To complete this booking you will need the following documents:'
            })}
          </p>
          <p>
            {t('beforeStartInfo.licence', {
              defaultValue:
                '- A valid driving licence for yourself and any additional drivers.'
            })}
          </p>
          <p>
            {t('beforeStartInfo.emergency', {
              defaultValue:
                '- Emergency contact and allergens information for yourself and any additional drivers.'
            })}
          </p>
        </>
      )}
    </Fragment>
  )

  const VoucherFlowContent = (
    <Fragment>
      <h3>
        <WarningIcon />
        <span className="text-uppercase ls-8">
          {t('beforeStartVoucher.title', { defaultValue: 'Important Notice' })}
        </span>
      </h3>
      {text &&
        text.split('\n').map((line, index) => (
          <p className="description" key={index}>
            {line}
          </p>
        ))}
      {!text && (
        <Fragment>
          <p className="description">
            {t('beforeStartVoucher.firstInfo', {
              defaultValue:
                'All drivers participating in a Drive Experience must be over 18 years old, hold a full and valid driving licence, with at least 12 months of uninterrupted driving experience on a full licence, and no more than 6 current penalty points.'
            })}
          </p>
          <p className="description">
            {t('beforeStartVoucher.secondInfo', {
              defaultValue:
                'Children attending a Tour Experience must be over the age of 10 and must be accompanied by a paying parent or guardian.'
            })}
          </p>
          <p className="description">
            {t('beforeStartVoucher.thirdInfo', {
              defaultValue:
                'For quantities of 10 or more, please contact the Experience Centre team.'
            })}
          </p>
        </Fragment>
      )}
    </Fragment>
  )

  return (
    <BeforeStartContainer
      className={classnames('ls-7', className)}
      data-test="BeforeStartContiner"
    >
      {variant === BeforeStartVariants.BuyExperience && BuyExperienceContent}
      {variant === BeforeStartVariants.VoucherFlow && VoucherFlowContent}
    </BeforeStartContainer>
  )
}

export default translate()(BeforeStartInfo)
