import axios, { AxiosRequestConfig } from 'axios'
import { camelCase, get as getValue } from 'lodash'
import { get } from 'tools/query'
import * as queryString from 'query-string'
import { getConfig } from 'config'
import { appStore } from 'stores'

// if we use mock, only allow get, find a way to detect that (if needed)
const _ONLY_GET_ALLOWED = !!get().mock

const headers = {}

// TODO: global io flag to control the buttons

export const setAPIKey = (apiKey: string): void => {
  headers['X-API-Key'] = apiKey
}

const config = getConfig()

const serviceUrl = (service: string, forceMock: boolean): string => {
  // TODO Support Production and QA build, maybe using another env for that for build time
  // Future to support different domains by configuration
  // if ends with /, then it is a root service, call with index.json
  return forceMock || !!get().mock || process.env.NODE_ENV === 'test'
    ? `/backend/${service.match(/\/$/) ? `${service}index` : service}.json`
    : `${config.baseUrl}/${service}`
}

interface IRequestOptions {
  method?: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
}

export const normaliseData = (
  normalise: boolean,
  data?: { [key: string]: any }
) => {
  if (!data || !normalise) {
    return data
  }

  return Object.keys(data || {}).reduce((acc, key) => {
    if (!!data[key]) {
      acc[key] = data[key]
    }
    return acc
  }, {})
}

let requestCount = 0

const requestInner = async (
  endpoint: string,
  options?: IRequestOptions & {
    query?: { [s: string]: string | number | boolean }
    cancelator?: any
    removeBlanks?: boolean
  },
  forceMock: boolean = false
) => {
  const opt = options || {}

  if (!opt.cancelator) {
    requestCount++
  }

  if (appStore) {
    appStore.setHasPendingRequests(!!requestCount)
  }

  const cancelToken = opt && opt.cancelator && opt.cancelator.cancelToken
  const promise = axios({
    method: (!forceMock && !_ONLY_GET_ALLOWED && opt.method) || 'get',
    url:
      serviceUrl(endpoint, forceMock) +
      (opt.query
        ? '?' +
          queryString.stringify(
            normaliseData(!!opt.removeBlanks, opt.query) || {}
          )
        : ''),
    data: opt.data ? normaliseData(!!opt.removeBlanks, opt.data) : null,
    cancelToken,
    withCredentials: true,
    auth: {
      username: 'keytree',
      password: 'treekey99'
    },
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...(opt.query
        ? { 'Content-Type': 'application/x-www-form-urlencoded' }
        : {}),
      ...headers
    },
    transformResponse
  })

  // Return data
  // https://jakearchibald.com/2017/await-vs-return-vs-return-await/
  return promise
}

const request = async (
  endpoint: string,
  options?: IRequestOptions & {
    query?: { [s: string]: string | number | boolean }
    cancelator?: any
    removeBlanks?: boolean
  },
  forceMock: boolean = false
) => {
  let response: any
  try {
    response = await requestInner(endpoint, options, forceMock)
  } catch (error) {
    const errorStatus = getValue(error, 'response.status')
    const errors = getValue(error, 'response.data')

    if (errorStatus === 500 || errorStatus === 403) {
      appStore.setRequestError()
      throw new Error(error)
    }
    if (errorStatus === 400) {
      appStore.setValidationError(errors)
      throw new Error(error)
    }
    if (errorStatus === 409) {
      // Conflict error. Happens on DB level.
      // See ticket https://tracking.keytree.cloud/browse/JLR-4264 for explanation
      appStore.setConflictError(errors)
      throw new Error(error)
    }
    return error
  }

  return response
}

export const transformResponse = [
  (data: any) => {
    requestCount--
    if (typeof data === 'string') {
      try {
        data = normaliseJSON(JSON.parse(data))
      } catch (e) {
        // Normalisation failed, content returned is not JSON, returning original
        appStore.setHasPendingRequests(!!requestCount)
        return data
      }
    }
    if (appStore) {
      appStore.setHasPendingRequests(!!requestCount)
    }
    return data
  }
]

const traverse = (
  target: any,
  transformValue: ({ key, value }: { key?: string; value: any }) => any,
  transformKey: ({ key, value }: { key: string; value: any }) => string
) =>
  Object.keys(target).reduce((acc, key) => {
    const value: any = target[key]

    if (Array.isArray(value)) {
      acc[transformKey({ key, value })] = value.map(entry => {
        if (value !== null && typeof entry === 'object') {
          return traverse(entry, transformValue, transformKey)
        } else {
          return transformValue({ value: entry })
        }
      })
    } else if (value !== null && typeof value === 'object') {
      acc[transformKey({ key, value })] = traverse(
        value,
        transformValue,
        transformKey
      )
    } else {
      acc[transformKey({ key, value })] = transformValue({ key, value })
    }
    return acc
  }, {})

export const normaliseJSON = (json: { [key: string]: any }) =>
  traverse(
    json,
    ({ key, value }) => {
      // Parse data to timestamp
      if (key && value && key.match(/_at$/)) {
        return Date.parse(value)
      }
      if (value === null) {
        return undefined
      }
      return value
    },
    ({ key }) => camelCase(key)
  )

export const denormaliseJSON = (json: { [key: string]: any }) => json

export default request
