import * as React from 'react'
import { ISelectInput } from 'atoms/Form/SelectInput'
import StepSelectInput from 'molecules/Step/SelectInput'
import request from 'tools/request'
import { find } from 'lodash'

type ICountry = {
  label: string
  value: string
}

type State = {
  countries: ICountry[]
  isLoading: boolean
}

type Omit<T, K extends keyof T> = T extends any
  ? Pick<T, Exclude<keyof T, K>>
  : never

export class CountrySelectInput extends React.Component<
  Omit<ISelectInput, 'options'> & {
    available?: string[]
    name: string
    label: string
  },
  State
> {
  state = {
    countries: [],
    isLoading: true
  }

  async componentDidMount() {
    // TODO Deal with error message
    // TODO Support caching options further up (request layer?)
    const { data } = await request('configuration/countries')
    const countries: ICountry[] = Object.keys(data).map(key => {
      const { name, code } = data[key]
      return { label: name, value: code }
    })

    const { available } = this.props

    this.setState(() => ({
      isLoading: false,
      countries: available
        ? countries.filter(country =>
            find(available, av => av === country.value)
          )
        : countries
    }))
  }

  render() {
    const { countries, isLoading } = this.state

    return (
      <StepSelectInput
        {...this.props}
        isLoading={isLoading}
        options={countries}
      />
    )
  }
}

export default CountrySelectInput
