import { types } from 'mobx-state-tree'

const ContactDetails = types.model('ContactDetails', {
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  addressLine1: types.maybe(types.string),
  addressLine2: types.maybe(types.string),
  locality: types.maybe(types.string),
  postalTown: types.maybe(types.string),
  adminDistrict: types.maybe(types.string),
  district: types.maybe(types.string),
  postalCode: types.maybe(types.string),
  country: types.string,
  email: types.maybe(types.string),
  contactNumber: types.maybe(types.string),
  deliveryType: types.enumeration('deliveryType', ['address', 'email'])
})

export const toSnakeCase = (details: IContactDetails) => {
  return {
    delivery_type: details.deliveryType,
    first_name: details.firstName,
    last_name: details.lastName,
    address_line_1: details.addressLine1,
    address_line_2: details.addressLine2,
    locality: details.locality,
    postal_town: details.postalTown,
    admin_district: details.adminDistrict,
    district: details.district,
    postal_code: details.postalCode,
    country: details.country,
    email: details.email,
    contact_number: details.contactNumber
  }
}

export type IContactDetails = typeof ContactDetails.Type
export type IContactDetailsSnapshot = typeof ContactDetails.SnapshotType
export default ContactDetails
