import { types, flow, applySnapshot, getRoot } from 'mobx-state-tree'
import request, { setAPIKey } from 'tools/request'
import i18nFactory from 'tools/i18n'
import { setPrivacy } from 'tools/analytics'
import { setTheme } from 'tools/theme'
import { reaction } from 'mobx'
import { get } from 'tools/query'
import { getState, getDefault } from 'tools/session'
import { isEmpty } from 'lodash'

const disposers: Array<() => void> = []

export type IImageUrl = (
  size: 'large' | 'medium' | 'small',
  width: number
) => string

const ProductLink = types.model('ProductLink', {
  label: types.string,
  value: types.string
})

const Configuration = types
  .model('Configuration', {
    appid: types.maybe(types.string),
    fbpx: types.maybe(types.string),
    sku: types.maybe(types.string),
    importantNotice: types.maybe(types.string),
    theme: types.optional(
      types.string,
      getDefault('configuration.theme', 'landrover')
    ),
    language: types.optional(
      types.string,
      getDefault('configuration.language', 'en')
    ),
    currency: types.optional(
      types.string,
      getDefault('configuration.currency', 'GBP')
    ),
    campaign: types.optional(
      types.string,
      getDefault('configuration.campaign', 'standard')
    ),
    centre: types.optional(
      types.string,
      getDefault('configuration.centreName', '')
    ),
    centreNumber: types.optional(
      types.string,
      getDefault('configuration.centreNumber', '')
    ),
    validCampaignCode: types.optional(types.boolean, false),
    campaignCode: types.maybe(types.string),
    availableProducts: types.optional(
      types.array(ProductLink),
      getDefault('configuration.availableProducts', [])
    ),
    analyticsId: types.maybe(types.string),
    hostnameOrigin: types.maybe(types.string),
    hostnameBuySdk: types.maybe(types.string),
    stripePublishableKey: types.maybe(types.string),
    cdnUrl: types.optional(types.string, '/cdn'),
    privacy: false,
    debug: false
  })
  .views(self => ({
    imageUrl({
      category,
      placement,
      identifier
    }: {
      category: 'centre' | 'product'
      placement: 'add-on' | 'header' | 'hero' | 'feature'
      identifier: string
    }): IImageUrl {
      return (size, width) =>
        `${self.cdnUrl}/${category}/${placement}/${identifier}_${size}_${width}.jpg`.toLowerCase()
    }
  }))
  .actions(self => {
    const actions: any = {
      load: flow(function* load() {
        const query = get()
        let queryWithSnap = {}

        const sessionSnap = getState() || {}

        queryWithSnap = Object.assign(sessionSnap, query)

        applySnapshot(self, queryWithSnap)

        // If appId is defined, load configuration from backend and merge into query (query has priority)
        if (query.appid) {
          // Set API header for request
          setAPIKey(query.appid)

          const { data } = yield request(`configuration/context`, {})
          applySnapshot(self, { ...data, ...query })
        } else {
          applySnapshot(self, query)
        }

        if (self.campaign === 'advocacy') {
          const products = yield request(
            'discovery/item-containers/' + self.sku
          )
          if (isEmpty(products.data)) {
            const rootStore = getRoot(self)
            rootStore.setModalContent({
              title: 'Not found',
              content: 'There are no items for this generic sku.'
            })
            return
          }
          self.availableProducts = products.data.items.map(
            (ap: { name: string; sku: string }) => ({
              label: ap.name,
              value: ap.sku
            })
          )
          self.importantNotice = products.data.importantNotice
          self.centre = products.data.items[0].unit
          self.centreNumber = products.data.items[0].phone
        }

        // After options were loaded, make sure any changes reflects into actions
        // Language change
        disposers.push(
          reaction(
            () => self.language,
            language => i18nFactory().changeLanguage(language)
          )
        )

        // Privacy
        disposers.push(
          reaction(() => self.privacy, privacy => setPrivacy(privacy))
        )

        // Theme
        disposers.push(reaction(() => self.theme, theme => setTheme(theme)))
      }),
      update(property: string, value: any) {
        self[property] = value
      },
      beforeDestroy() {
        disposers.map(fn => fn())
      },
      validateCampaignCodeAndSetAvailableProducts: flow(
        function* validateCampaignCode(code: string) {
          const res = yield request(`vouchers/search/voucher-code/${code}`)
          const status = res.status

          if (status === 200) {
            self.campaignCode = code
            self.validCampaignCode = true

          } else {
            self.campaignCode = null
            self.validCampaignCode = false
            const rootStore = getRoot(self)
            rootStore.setModalContent({
              title: 'Code not recognised',
              content:
                'CODE NOT RECOGNISED \n\nUnfortunately, the voucher code you entered cannot be used for this Experience. \n\nPlease contact our Experience team at ' + self.centreNumber
            })
          }
        }
      )
    }
    return actions
  })

export type IConfiguration = typeof Configuration.Type
export type IConfigurationSnapshot = typeof Configuration.SnapshotType
export default Configuration
