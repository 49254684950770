import * as React from 'react'
import classnames from 'classnames'
import ItemsTable from 'molecules/OrderSummary/ItemsTable'
import PriceLine from 'atoms/Basket/PriceLine'

import styled from 'tools/theme'

import { IBasket } from 'stores/Basket'
import { currencyToString } from 'tools/currency'
import { VoucherCodeInput } from 'molecules/OrderSummary/VoucherCodeInput'

import { translate, InjectedTranslateProps } from 'react-i18next'
import { ICatalogue } from 'stores/Catalogue'
import { observer } from 'mobx-react'

const Container = styled.div`
  .thick-top-white-border {
    border-top: ${props => `6px solid ${props.theme.white}`};
  }

  .thin-top-primary-border {
    border-top: ${props => `2px solid ${props.theme.primary}`};
  }
`

const OrderSummary: React.SFC<
  { basket: IBasket; catalogue: ICatalogue } & InjectedTranslateProps
> = ({ basket, catalogue, t }) => {
  const {
    discountTotal,
    itemTotalPromoted,
    netPrice,
    grossPrice,
    vatAmount,
    selectedDelivery,
    discountsEnabled
  } = basket

  return (
    <Container data-test="OrderSummaryContainer">
      <ItemsTable basket={basket} catalogue={catalogue} />
      {discountsEnabled && (
        <PriceLine
          title="PROMO / VOUCHER CODE"
          dataTest="PriceLinePromoVoucherCode"
          price={currencyToString(discountTotal)}
          className="thin-top-primary-border"
        >
          <VoucherCodeInput basket={basket} t={t} />
        </PriceLine>
      )}
      <PriceLine
        dataTest="PriceLineBasketTotal"
        className={classnames(
          discountsEnabled
            ? 'thick-top-white-border'
            : 'thin-top-primary-border'
        )}
        title="Basket Total"
        subtitle={
          selectedDelivery && !!selectedDelivery.price.amount
            ? 'Gift pack'
            : null
        }
        price={currencyToString(itemTotalPromoted)}
        subprice={
          selectedDelivery &&
          !!selectedDelivery.price.amount &&
          currencyToString(selectedDelivery.price, {
            showDash: false
          })
        }
      />
      <PriceLine
        dataTest="PriceLineTotalBeforeVat"
        className="thick-top-white-border"
        title="TOTAL BEFORE VAT"
        subtitle="(vat)"
        price={currencyToString(netPrice)}
        subprice={currencyToString(vatAmount)}
      />
      <PriceLine
        dataTest="PriceLineOrderTotal"
        total
        title="ORDER TOTAL"
        price={currencyToString(grossPrice)}
        className="thick-top-white-border"
      />
    </Container>
  )
}

export default translate()(observer(OrderSummary))
