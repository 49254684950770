import * as React from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import { IAppStore } from 'stores/AppStore'
import { scrollPage } from 'tools/scroll'
import Alert from 'atoms/Alert'

interface IProps {
  appStore?: IAppStore
}
const ConflictErrorPanel = ({ appStore }: IProps) => {
  const errors = appStore.conflictErrors

  if (!errors) return null

  scrollPage(0, 0)

  return (
    <Wrapper>
      <Inner>
        <Alert title={errors.title} type="error">
          <p>{errors.description}</p>
        </Alert>
      </Inner>
    </Wrapper>
  )
}

export default inject(({ appStore }) => ({
  appStore
}))(observer(ConflictErrorPanel))

const Wrapper = styled.div({
  width: '100vw',
  display: 'flex'
})

const Inner = styled.div({
  width: '90vw',
  margin: '20px auto'
})
