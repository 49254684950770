import * as React from 'react'
import Option from 'atoms/Option'
import styled from 'styled-components'
import ButtonGroup, {
  IQuantityButtonGroup
} from 'molecules/QuantityOption/ButtonGroup'
import { Disableable } from 'atoms/Disableable'
import { appStore } from 'stores'

export type IQuantityOption = {
  children: string
  messageAtMaxQuantity?: string
  optional?: boolean
} & IQuantityButtonGroup

const QuantityOption: React.SFC<IQuantityOption> = ({
  children,
  optional,
  className,
  ...others
}) => {
  const { max, quantity, messageAtMaxQuantity } = others
  return (
    <Disableable disabled={appStore.hasPendingRequests}>
      <Option className={className} label={children} optional={optional}>
        <ButtonGroup {...others} />
      </Option>
      {!!max && !!messageAtMaxQuantity && quantity === max && (
        <Message>{messageAtMaxQuantity}</Message>
      )}
    </Disableable>
  )
}

const Message = styled.p`
  margin-top: 5px;
  color: red;
  font-size: 12px;
`

export default QuantityOption
