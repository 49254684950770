/* eslint-disable */
import * as React from 'react'
import Section from 'atoms/Form/Section'
import { FormikProps, connect } from 'formik'
import { IGiftVoucherForm } from 'organisms/Scenarios/GiftVoucher'
import BoxContainer from 'atoms/Form/BoxContainer'
import { IStepContent } from 'organisms/Main'
import PrePopulatedInfo from 'atoms/PrePopulatedInfo'
import { observer } from 'mobx-react'
import Text from 'atoms/Form/Text'
import StepCheckbox from 'molecules/Step/Checkbox'
import Link from 'atoms/Link'
import ContactInput from 'molecules/Step/ContactInput'
import LockIcon from 'atoms/Icons/Lock'
import { scrollPage } from 'tools/scroll'
import StripePaymentForm from 'molecules/StripePaymentForm/StripePaymentForm'

class PaymentDetails extends React.Component<
  { formik: FormikProps<IGiftVoucherForm> } & IStepContent & {
      billingAddressRequired?: boolean
    },
  {}
> {
  render() {
    const {
      billingAddressRequired,
      t,
      formik,
      navigation: { previousStep },
      catalogue: { paymentProvider, paymentMethods },
      basket: {
        billingAddress,
        paymentURL,
        isAwaitingPayment,
        isDigitalDelivery,
        tosUrl,
        privacyPolicyUrl
      }
    } = this.props

    const cleanupTerms = () => {
      const policyEl = document.getElementById('checkfront-policy')
      if (policyEl) {
        policyEl.remove()
      }
      const mobileInvoiceEl = document.getElementById('mobile-invoice')
      if (mobileInvoiceEl) {
        mobileInvoiceEl.remove()
      }
    }

    return (
      <div>
        <BoxContainer>
          {billingAddressRequired && (
            <div>
              {(formik.values.sameBillingDetails && !isDigitalDelivery) ||
              isAwaitingPayment ? (
                <Section
                  dataTest="BillingInformation"
                  title={t('content.billing.title', {
                    defaultValue: 'Billing Information'
                  })}
                >
                  <PrePopulatedInfo
                    title={t('prePolutedInfo.payee', {
                      defaultValue: 'Payee'
                    })}
                    info={billingAddress!}
                    onClick={
                      !isAwaitingPayment
                        ? event => {
                            event.preventDefault()
                            previousStep()
                          }
                        : undefined
                    }
                  />
                </Section>
              ) : (
                <Section
                  containsForm
                  dataTest="BillingInformation"
                  title={t('content.billing.title', {
                    defaultValue: 'Billing Information'
                  })}
                >
                  <ContactInput fieldName="billingAddress" t={t} />
                </Section>
              )}
            </div>
          )}
          {isAwaitingPayment && (
            <Section
              borderTop
              title={t('content.payment.title', {
                defaultValue: 'Payment Information'
              })}
            >
              {scrollPage(0, 0)}
              {paymentProvider === 'stripe' && (
                <StripePaymentForm
                  stepContent={this.props}
                  paymentMethods={paymentMethods.toJS()}
                />
              )}
              {(!paymentProvider || paymentProvider === 'datacash') &&
                paymentURL && (
                  <iframe
                    allow="payment"
                    data-test="PaymentIframe"
                    id="paymentframe"
                    onLoad={cleanupTerms}
                    style={{
                      width: '1px',
                      minWidth: '100%',
                      minHeight: '520px'
                    }}
                    src={paymentURL}
                    allowFullScreen={false}
                    frameBorder="0"
                    scrolling="yes"
                  />
                )}
            </Section>
          )}
        </BoxContainer>
        {!isAwaitingPayment && (
          <BoxContainer>
            <Section>
              <StepCheckbox
                name="agreed"
                label={t('content.payment.agreed.label', {
                  defaultValue: 'Must agree on Terms of Service'
                })}
              >
                <span>
                  {t('content.payment.agreed.clause', {
                    defaultValue: 'I have read and agreed to the '
                  })}
                  <Link href={tosUrl || '#'} target="_blank">
                    {t('content.payment.agreed.linktext', {
                      defaultValue: 'Terms of service'
                    })}
                  </Link>
                </span>
              </StepCheckbox>
              <StepCheckbox name="informed" optional>
                {t('content.payment.informed.checkbox', {
                  defaultValue: 'Keep me informed'
                })}
                <br />
                <br />
                <p>
                  {t('content.payment.informed.explanation', {
                    defaultValue:
                      ' Tick this box if you wish to be kept up-to-date with the latest Land Rover Experience news and offers. We respect the privacy of every individual and will not share your data with anyone outside of Jaguar Land Rover, its Retailers, and companies with which we have arranged services for your benefit. For full details, please see our '
                  })}
                  <Link href={privacyPolicyUrl || '#'} target="_blank">
                    {t('content.payment.informed.linktext', {
                      defaultValue: 'Privacy Policy'
                    })}
                  </Link>
                  .
                </p>
              </StepCheckbox>
            </Section>
          </BoxContainer>
        )}
        <Text className="mt-5">
          <LockIcon className="align-text-bottom mr-3" />
          {t('content.payment.safePage', {
            defaultValue:
              'This page is safe. All of your personal details are safe.'
          })}
        </Text>
      </div>
    )
  }
}

export default observer(connect<any, any>(PaymentDetails))
