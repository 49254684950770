import * as React from 'react'
import { observer } from 'mobx-react'
import { appStore } from 'stores'
import ProductUnaviable from 'organisms/ProductUnaviable'

type Props = {
  children: React.ReactNode
  appStore?: any
}

type State = {
  hasError: boolean
}

@observer
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {}

  render() {
    if (this.state.hasError || appStore.requestError) {
      return <ProductUnaviable></ProductUnaviable>
    }

    return this.props.children
  }
}

export default ErrorBoundary
