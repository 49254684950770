import { types, flow, getSnapshot } from 'mobx-state-tree'
import DateRange, { IDateRange } from './DateRange'
import request from 'tools/request'
import { find, concat, uniqBy, includes } from 'lodash'
const parse = require('date-fns/parse') // tslint:disable-line
const format = require('date-fns/format') // tslint:disable-line
const endOfMonth = require('date-fns/end_of_month') // tslint:disable-line
const addDays = require('date-fns/add_days') // tslint:disable-line
const addMonths = require('date-fns/add_months') // tslint:disable-line
const isWithinRange = require('date-fns/is_within_range') // tslint:disable-line

const MONTHS_TO_TRY = 18

export class AvailabilityError extends Error {
  constructor(m: string) {
    super(m)
    this.name = 'AvailabilityError'
    // Set the prototype explicitly for instanceof to work
    Object.setPrototypeOf(this, AvailabilityError.prototype)
  }
}

interface IAvailabilityResponse {
  timetable: IDateRange[]
  baseInventory: number
}

const loadedAvailabilityDates = []

const loadAvailability = async ({
  sku,
  parameters = {},
  dateFrom,
  dateTo
}: {
  sku: string
  parameters: { [s: string]: string | number | boolean }
  dateFrom: Date
  dateTo: Date
}): Promise<IAvailabilityResponse> => {
  const { data } = await request('availability/events', {
    query: {
      sku,
      ...Object.keys(parameters).reduce((acc, paramKey) => {
        acc[`parameter[${paramKey}]`] = parameters[paramKey]
        return acc
      }, {}),
      date_from: format(dateFrom, 'YYYY-MM-DD'),
      date_to: format(dateTo, 'YYYY-MM-DD')
    }
  })

  const timetable = data.eventTimetable
    .filter((tt: any) => {
      if (!includes(loadedAvailabilityDates, tt.date)) {
        loadedAvailabilityDates.push(tt.date)
        return tt
      } else {
        return null
      }
    })
    .map((tt: any) => ({
      available: tt.available,
      date: parse(`${tt.date}`, 'YYYY-MM-DD'),
      duration: tt.duration,
      eventTimes: (tt.eventTimes || [])
        .map((time: any) => ({
          startTime: new Date(time.startTime),
          endTime: new Date(time.endTime),
          available: time.available,
          inventory: time.inventory
        }))
        .filter((time: any) => time.available),
      type: tt.type,
      parameters: tt.parameters
    }))

  return {
    timetable,
    baseInventory: data.baseInventory
  }
}

const DateRangeArray = types.array(DateRange)

const Availability = types
  .model('Availability', {
    timetable: types.optional(DateRangeArray, []),
    baseInventory: types.maybe(types.number),
    isLoading: false
  })
  .views(self => ({
    get loadedDateRange() {
      const from = self.timetable[0] && self.timetable[0].date
      const to =
        self.timetable[self.timetable.length - 1] &&
        self.timetable[self.timetable.length - 1].date
      return {
        from,
        to,
        next: to && addMonths(to, 3)
      }
    },
    get available(): IDateRange[] {
      return self.timetable.filter(dateRange => dateRange.available)
    },
    get unavailable(): IDateRange[] {
      return self.timetable.filter(dateRange => !dateRange.available)
    }
  }))
  .views(self => ({
    get nextAvailable(): IDateRange | undefined {
      return self.available[0]
    },
    dateRangeForDate(
      date?: Date,
      timetable?: IDateRange[]
    ): IDateRange | undefined {
      const ranges = timetable || self.timetable
      return find(ranges, tt => tt.isWithinDateRange(date))
    }
  }))
  .actions(self => {
    let loadedParameters: {
      [s: string]: string | number | boolean
    }
    let loadedSKU: string

    // If the first availability call fails, date will be
    // pushed forward this amount of months and will retry.
    const INCREASE_MONTHS_STEP = 3

    // Date to start from loading (tomorrow)
    let dateFrom: Date = addDays(new Date(), 1)
    // Load INCREASE_MONTHS_STEP months forward by default
    let dateTo: Date = endOfMonth(addMonths(dateFrom, INCREASE_MONTHS_STEP))

    let lastTriedMonthsStep = INCREASE_MONTHS_STEP

    let aggregatedTimeTable: object[] = []

    const tryLoadingAvailability: any = async (opts: any) => {
      const { sku, parameters, dateFrom: df, dateTo: dt } = opts

      const availabilityIntent: IAvailabilityResponse = await loadAvailability({
        sku,
        parameters,
        dateFrom: df,
        dateTo: dt
      })

      aggregatedTimeTable = uniqBy(
        concat(aggregatedTimeTable, availabilityIntent.timetable),
        'date'
      )

      const isThereAvailableTime = find(aggregatedTimeTable, {
        available: true
      })

      if (lastTriedMonthsStep >= MONTHS_TO_TRY && !isThereAvailableTime) {
        // trow error
        return []
      }

      lastTriedMonthsStep += INCREASE_MONTHS_STEP

      if (!aggregatedTimeTable.length || !isThereAvailableTime) {
        const nextDateFrom = dt
        const nextDateTo = endOfMonth(
          addMonths(nextDateFrom, INCREASE_MONTHS_STEP)
        )
        return tryLoadingAvailability({
          sku: loadedSKU,
          parameters,
          dateFrom: nextDateFrom,
          dateTo: nextDateTo
        })
      } else {
        lastTriedMonthsStep = 0
        return {
          timetable: aggregatedTimeTable,
          baseInventory: availabilityIntent.baseInventory
        }
      }
    }

    return {
      resetAvailability: flow(function* resetAvailability({
        parameters,
        sku
      }: {
        parameters: {
          [s: string]: string | number | boolean
        }
        sku?: string
      }) {
        loadedParameters = parameters
        if (sku) {
          loadedSKU = sku
        }
        self.isLoading = true

        const availability = yield tryLoadingAvailability({
          sku: loadedSKU,
          parameters,
          dateFrom: self.loadedDateRange.from || dateFrom,
          dateTo: self.loadedDateRange.to || dateTo
        })

        const timetable = availability.timetable

        if (!timetable || !timetable.length) {
          self.isLoading = false
          throw new Error('no avail')
        } else {
          self.baseInventory = availability.baseInventory

          const currentSnapshot = getSnapshot(self.timetable)
          self.timetable = DateRangeArray.create([
            ...currentSnapshot,
            ...timetable
          ])
          self.isLoading = false
        }
      }),

      loadMonthAvailability: flow(function* loadMonthAvailability(month: Date) {
        // If month is not within loaded months, load availability
        const startMonth = month
        if (!isWithinRange(startMonth, dateFrom, dateTo) && !self.isLoading) {
          self.isLoading = true

          const monthDateTo = endOfMonth(addMonths(startMonth, 3))

          const availability: IAvailabilityResponse = yield loadAvailability({
            sku: loadedSKU,
            parameters: loadedParameters,
            dateFrom: startMonth,
            dateTo: monthDateTo
          })

          const currentSnapshot = getSnapshot(self.timetable)
          const monthTimetable = availability.timetable
          const dateArray = DateRangeArray.create([
            ...currentSnapshot,
            ...monthTimetable
          ])

          self.timetable = dateArray
          self.isLoading = false

          dateFrom = endOfMonth(addMonths(dateFrom, INCREASE_MONTHS_STEP))
          dateTo = endOfMonth(addMonths(dateFrom, INCREASE_MONTHS_STEP))
        }
      })
    }
  })
  .actions(self => {
    return {
      init: flow(function* init(initArgs: {
        sku: string
        parameters: { [s: string]: string | number | boolean }
      }) {
        yield self.resetAvailability(initArgs)
      })
    }
  })

export type IAvailability = typeof Availability.Type
export type IAvailabilitySnapshot = typeof Availability.SnapshotType
export default Availability
