import * as React from 'react'
import classnames from 'classnames'

const LanguageSelect: React.SFC<{
  language: string
  onChange: (language: string) => void
}> = ({ language, onChange }) => (
  <div className="btn-group m-2" role="group" aria-label="Language Selector">
    {['en', 'de'].map(languageName => (
      <button
        key={languageName}
        type="button"
        className={classnames(
          'btn btn-sm',
          language === languageName ? 'btn-primary' : 'btn-outline-primary'
        )}
        onClick={() => onChange(languageName)}
      >
        {languageName}
      </button>
    ))}
  </div>
)

export default LanguageSelect
