import * as React from 'react'

import Section from 'atoms/Form/Section'
import Option from 'atoms/Option'
import Radio from 'atoms/Form/Radio'
import { IStepContent } from 'organisms/Main'
import { FormikProps, connect } from 'formik'
import FormText from 'atoms/Form/Text'

import BoxContainer from 'atoms/Form/BoxContainer'

import { IBuyExperienceForm } from '../BuyExperience/index'
import { IFormField } from 'stores/Basket'
import StepTextInput from 'molecules/Step/TextInput'
import StepCheckbox from 'molecules/Step/Checkbox'
import StepSelectInput from 'molecules/Step/SelectInput'
import { log } from 'tools/log'
import styled from 'tools/theme'
import { find } from 'lodash'

const getTextFieldType = (field: any) => {
  let type = null
  if (field.elementType === 'TEXT') {
    if (field.id.match(/email$/)) {
      type = 'email'
    } else if (field.id.match(/phone$/)) {
      type = 'phone'
    }
  }
  return type
}

const Tip = styled(FormText)`
  && {
    margin-bottom: 0;
  }
`

const commonProps = (
  field: IFormField
): {
  name: string
  label: string
  optional: boolean
  key: string
} => ({
  key: field.id,
  name: `basketForm_${field.id}`,
  label: field.label!,
  optional: !field.required
})

let fieldsWithFilter: any[] = []

class Step3 extends React.Component<
  IStepContent & {
    formik: FormikProps<IBuyExperienceForm>
  },
  { fields: IFormField[]; isLoading: boolean }
> {
  state = {
    fields: [],
    isLoading: true
  }

  async componentDidMount() {
    const fields = await this.props.basket.loadForm()

    fieldsWithFilter = fields.filter((field: any) => field.filter)
    const fieldsWithValue = fields.filter((field: any) => field.value)

    fieldsWithValue.forEach(field => {
      this.props.formik.setFieldValue('basketForm_' + field.id, field.value)
    })

    this.setState(() => ({
      fields,
      isLoading: false
    }))
  }

  render() {
    const {
      t,
      formik: { setFieldValue, values }
    } = this.props
    const { fields, isLoading } = this.state

    return (
      <BoxContainer>
        {isLoading && (
          <FormText>
            {t('booking.formGenerator.isLoading', {
              defaultValue: 'Please wait, loading form fields...'
            })}
          </FormText>
        )}
        {fields.map((field: IFormField) => {
          const hasFilterApplied = find(fieldsWithFilter, {
            id: field.id as any
          })
          if (hasFilterApplied) {
            // hide if filter set
            const filter = hasFilterApplied.filter
            const { showFilterValue, showFilterBy, showFilterOperator } = filter
            if (
              !(
                (showFilterOperator === 'EQ' &&
                  showFilterValue === values['basketForm_' + showFilterBy]) ||
                (showFilterOperator === 'NOT' &&
                  showFilterValue !== values['basketForm_' + showFilterBy])
              )
            ) {
              return null
            }
          }
          if (
            field.elementType === 'TEXT' ||
            field.elementType === 'TEXTAREA'
          ) {
            return (
              <Section key={field.id}>
                <StepTextInput
                  {...commonProps(field)}
                  type={getTextFieldType(field)}
                  makeTextarea={field.elementType === 'TEXTAREA'}
                  helper={field.tip && <Tip>{field.tip}</Tip>}
                />
              </Section>
            )
          }
          if (field.elementType === 'CHECKBOX') {
            return (
              <Section title={field.label!} key={field.id}>
                <StepCheckbox
                  {...commonProps(field)}
                  label={t('booking.formGenerator.agreement', {
                    defaultValue: 'Must agree on requirements'
                  })}
                >
                  {field.tip}
                </StepCheckbox>
              </Section>
            )
          }
          if (field.elementType === 'SELECT') {
            return (
              <Section key={field.id}>
                <StepSelectInput
                  {...commonProps(field)}
                  options={(field.options || []).map(option => ({
                    value: option.value,
                    label: option.text
                  }))}
                  helper={field.tip && <Tip>{field.tip}</Tip>}
                />
              </Section>
            )
          }
          if (field.elementType === 'CONTENT') {
            return (
              <Section key={field.id}>
                <div dangerouslySetInnerHTML={{ __html: field.content! }} />
              </Section>
            )
          }
          if (field.elementType === 'RADIO') {
            return (
              <Section
                title={field.label}
                role="radiogroup"
                key={field.id}
                subtitle={field.tip}
              >
                {(field.options || []).map(({ value, text }) => {
                  return (
                    <Option key={value} optional={!field.required} label={text}>
                      <Radio
                        id={field.id + value}
                        checked={values[`basketForm_${field.id}`] === value}
                        name={field.id}
                        aria-labelledby={field.label}
                        onChange={() => {
                          setFieldValue(`basketForm_${field.id}`, value)
                        }}
                      />
                    </Option>
                  )
                })}
                {/* TODO Implement required handling: showing error message */}
              </Section>
            )
          }
          log('[WARNING] Cannot render form field for', field)
          return null
        })}
      </BoxContainer>
    )
  }
}

export default connect<any, any>(Step3)
