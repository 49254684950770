import * as React from 'react'
import { Field, FieldProps } from 'formik'
import SelectInput, { ISelectInput } from 'atoms/Form/SelectInput'
import * as Yup from 'yup'
import { find } from 'lodash'

// TODO Align style (border box color, menu drop down above other fields)

const StepSelectInput: React.SFC<ISelectInput> = ({ name, ...props }) => {
  return (
    <Field
      name={name}
      validate={async (value: string) => {
        let schema = Yup.string().label(props.label)

        // If optional is not defined, then it should be required
        if (!props.optional) {
          schema = schema.required()
        }

        try {
          await schema.validate(value)
        } catch (error) {
          return error.errors[0]
        }
      }}
      render={({ field, form }: FieldProps<{ [s: string]: string }>) => {
        return (
          <SelectInput
            {...props}
            {...field}
            onChange={(option, action) => {
              // form.setFieldTouched(name)
              form.setFieldValue(
                name,
                option
                  ? Array.isArray(option)
                    ? option.map(({ value }) => value)
                    : option.value
                  : null
              )

              if (props.onChange) {
                props.onChange(option, action)
              }
            }}
            value={
              props.options
                ? find(props.options, option => option.value === field.value)
                : undefined
            }
            isDisabled={
              !!props.options &&
              !!find(props.options, option => option.value === field.value) &&
              props.options.length === 1
            } // Disabled if only one option is available and selected as value
            error={
              !!form.status
                ? name.split('.').reduce((acc: any, partialName: string) => {
                    return acc && partialName ? acc[partialName] : undefined
                  }, form.errors)
                : ''
            }
          />
        )
      }}
    />
  )
}

export default StepSelectInput
