import { Component } from 'react'
import { debounce, isEqual } from 'lodash'
import { connect, FormikValues, FormikProps } from 'formik'

// As defined at https://github.com/jaredpalmer/formik-effect/issues/4#issuecomment-446833481

interface IProps {
  onChange: (values: FormikValues) => void
}

interface IPropsEnhanced extends IProps {
  formik: FormikProps<FormikValues>
}

const SAVE_DELAY = 0

class FormikEffects extends Component<IPropsEnhanced> {
  onChange = debounce(this.props.onChange, SAVE_DELAY)

  componentDidUpdate(prevProps: IPropsEnhanced) {
    const { formik } = this.props

    const hasChanged = !isEqual(prevProps.formik.values, formik.values)

    if (hasChanged) {
      this.onChange(formik.values)
    }
  }

  render(): null {
    return null
  }
}

export default connect<IProps>(FormikEffects)
