import * as React from 'react'
import styled from 'tools/theme'
import tick from './tick.svg'
import classnames from 'classnames'
import ErrorDescription from 'atoms/Form/ErrorDescription'

type AdditionalProps = {
  name: string
  optional?: boolean
  className?: string
  bold?: boolean
  error?: string
}

const CheckboxContainer = styled<AdditionalProps, any>('div')`
  && {
    min-height: auto;
    padding-left: 4rem;
    padding-right: 0;
    cursor: pointer;
    margin-bottom: ${props => (props.bold ? '4.3rem' : '3rem')};

    & + & {
      margin-bottom: 0;
    }

    input {
      outline: 0 !important;
    }
    &:focus-within {
      label {
        &:before {
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.64);
        }
      }
    }

    label {
      color: ${props => props.theme.darkCoolGrey};
      font-weight: ${props => (props.bold ? '900' : 'normal')};
      font-size: 1.4rem;
      letter-spacing: ${props => (props.bold ? '0.7px' : '1.1px')};
      user-select: none;
      cursor: inherit;

      &:before {
        width: 2.2rem;
        height: 2.2rem;
        top: 0rem;
        left: -4rem;
        right: auto;
        border-radius: 4px;
        border: 0;
      }

      &:after {
        top: 0rem;
        right: auto;
        left: -4rem;
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 75%;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url(${tick});
    }

    .custom-control-input ~ .custom-control-label::before {
      background-color: ${props =>
        props.error ? props.theme.danger : props.theme.mediumGrey};
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: ${props => props.theme.mediumGrey};
    }
  }
`

export type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  AdditionalProps

const Checkbox: React.SFC<CheckboxProps> = ({
  children,
  optional,
  className,
  bold,
  error,
  checked,
  ...props
}) => {
  if (!checked) {
    checked = false
  }
  const input = (
    <input
      type="checkbox"
      checked={checked}
      id={props.name}
      {...props}
      className="custom-control-input"
    />
  )
  return (
    <CheckboxContainer
      data-test="CheckboxContainer"
      bold={bold}
      error={error}
      className={classnames(
        'custom-control custom-checkbox custom-control-inline',
        className
      )}
    >
      {React.cloneElement(input, {
        error
      })}
      <label className="custom-control-label" htmlFor={props.name}>
        {children}
        {!optional && '*'}
        <ErrorDescription
          data-test="CheckboxContainerErrorDescription"
          error={error}
          className="d-block"
        />
      </label>
    </CheckboxContainer>
  )
}

export default Checkbox
