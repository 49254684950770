import * as React from 'react'
import { Fragment } from 'react'
import { IContactDetails } from 'stores/Basket/ContactDetails'
import { translate, InjectedTranslateProps } from 'react-i18next'
import Link from 'atoms/Link'
import styled from 'tools/theme'

const StyledLink = styled(Link)`
  font-size: 1.2rem;
`

const Name = styled.span`
  color: ${props => props.theme.darkCoolGrey};
  font-weight: normal;
`

const InfoLine = styled.p`
  letter-spacing: 0.7px;
  line-height: 1.5;
  margin-bottom: 0;
`

const PrePopulatedInfo: React.SFC<
  {
    title: string
    info: IContactDetails
    nameOnVoucher?: string[]
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
  } & InjectedTranslateProps
> = ({
  title,
  nameOnVoucher,
  info: {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    locality,
    postalCode,
    country,
    postalTown,
    email
  },
  onClick,
  t
}) => (
  <div data-test="PrepopulatedInfoContainer">
    {onClick && (
      <StyledLink onClick={onClick} className="float-right">
        {t('prePolutedInfo.modify', {
          defaultValue: 'Modify' // tslint:disable-line
        })}
      </StyledLink>
    )}
    <h4 className="text-uppercase ls-8 mb-4">
      {title} -{' '}
      <Name>
        {nameOnVoucher
          ? nameOnVoucher.join().replace(/,/g, ', ')
          : `${firstName} ${lastName}`}
      </Name>
    </h4>
    {!nameOnVoucher && (
      <Fragment>
        <InfoLine>{addressLine1}</InfoLine>
        {addressLine2 && <InfoLine>{addressLine2}</InfoLine>}
        <InfoLine>{locality}</InfoLine>
        <InfoLine>{postalCode}</InfoLine>
        <InfoLine>{postalTown}</InfoLine>
        <InfoLine>{country}</InfoLine>
        <InfoLine>{email}</InfoLine>
      </Fragment>
    )}
  </div>
)

export default translate()(PrePopulatedInfo)
