import React from 'react'
import {IdealBankElement} from '@stripe/react-stripe-js'

// Stripe Style object properties: https://stripe.com/docs/js/appendix/style
const style = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
}

const IdealSection = () => {
  // The IdealBankElement includes the iDEAL bank select list.
  // If we want to customize how the fields appear, or if we want to add or remove fields, we can do that with
  // different Elements. See: https://github.com/stripe/react-stripe-elements
  return (
    <IdealBankElement className="StripeIdealBankElement" options={style} />
  )
}

export default IdealSection
