import * as React from 'react'
import styled, { IThemeInterface } from 'tools/theme'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { translate, InjectedTranslateProps } from 'react-i18next'
import classnames from 'classnames'

const RadioContainer = styled.div`
  && {
    min-height: auto;
    padding-right: 2.1rem;
    padding-left: 0;
    &:focus-within {
      label {
        &:before {
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.64);
        }
      }
    }

    label {
      color: ${props => props.theme.primary};
      letter-spacing: 1.3px;
      font-weight: 300;
      font-size: 1rem;
      user-select: none;
      cursor: pointer;

      &:before {
        width: 1.6rem;
        height: 1.6rem;
        border: ${props => `1px solid ${props.theme.primary}`};
        top: 0;
        right: -2.1rem;
        left: auto;
      }

      &:after {
        cursor: pointer;
        top: 0;
        left: auto;
        right: -2.1rem;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url(${`data:image/svg+xml;base64,${window.btoa(
        icon(faCheck, {
          styles: { color: 'white' },
          transform: {
            size: 20
          }
        }).html[0]
      )}`});
    }
  }
`

const Radio: React.SFC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    theme?: IThemeInterface
    tReady?: boolean
  } & InjectedTranslateProps
> = ({ id, t, tReady, children, className, ...props }) => (
  <RadioContainer
    data-test="RadioContainer"
    className={classnames(
      'custom-control custom-radio custom-control-inline m-0',
      className
    )}
  >
    <input type="radio" id={id} {...props} className="custom-control-input" />
    <label
      className="custom-control-label text-uppercase font-landrover"
      htmlFor={id}
    >
      {children || t('form.radio.text', { defaultValue: 'select' })}
    </label>
  </RadioContainer>
)

export default translate()(Radio)
