/* eslint-disable */
import * as React from 'react'
import { Fragment } from 'react'
import styled, { IThemeInterface, media } from 'tools/theme'
import { observer, inject } from 'mobx-react'
import { transparentize } from 'polished'
import { currencyToString } from 'tools/currency'
import AddonFlag from 'atoms/AddonFlag'
import classnames from 'classnames'

import { IBasket } from 'stores/Basket'
import { INavigation } from 'stores/Navigation'

import { translate, InjectedTranslateProps } from 'react-i18next'
import QuantityButtonGroup from 'molecules/QuantityOption/ButtonGroup'
import { ICatalogue } from 'stores/Catalogue'
import { IConfiguration } from 'stores/Configuration'
import { debounce, find } from 'lodash'
import { appStore } from 'stores'
import { Disableable } from 'atoms/Disableable'

const debouncedAddons: any[] = []
const createDebouncedSave = (addon: any) => {
  const alreadyDebounced = find(debouncedAddons, { sku: addon.sku })

  const debouncedSave = debounce(addon.saveParametersAndApplySnapshot, 600)
  if (alreadyDebounced) {
    return alreadyDebounced.debouncedSave
  }
  debouncedAddons.push({ sku: addon.sku, debouncedSave })
  return debouncedSave
}

const Container = styled.div`
  ${media.xs`
    padding: 1.6rem 0 0;
  `};

  padding: 3.5rem 3.2rem 0;
  background-color: ${props => props.theme.extraLightGrey};

  .addon {
    border-top: ${props => `1px solid ${props.theme.mediumGrey}`};

    &.first {
      border-top: ${props =>
        `2px solid ${transparentize(0.7, props.theme.darkCoolGrey)}`};
    }
  }
`

const Link = styled.a`
  color: ${props => props.theme.dark};
  font-size: 1.2rem;
  margin: 0.5rem 0;
  padding-right: 0.5rem;
  letter-spacing: 0.7px;
  text-decoration: underline;
  font-weight: normal;

  & + & {
    padding-left: 0.5rem;
    border-left: ${props =>
      `1px solid ${transparentize(0.85, props.theme.darkCoolGrey)}`};
  }
`

const Value = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.darkCoolGrey};
`

const Table = styled.table`
  && {
    thead {
      border-bottom: ${props =>
        `1px solid ${transparentize(0.85, props.theme.darkCoolGrey)}`};

      th {
        padding: 0 0 2rem;
      }
    }
    tbody {
      img {
        margin-right: 1.6rem;
      }

      .product-title {
        font-size: 1.9rem;
      }

      td,
      th {
        padding: 3.5rem 0 0;
      }

      tr.addon,
      tr.schedule {
        th,
        td {
          padding: 2rem 0;
        }
      }
    }
  }
`

const Details = styled.div`
  border-top: ${props => `1px solid ${props.theme.mediumGrey}`};

  h5 {
    font-size: 1.2rem;
  }

  ${Value} {
    font-size: 1.3rem;
  }
`

const ItemsTable: React.SFC<
  {
    theme?: IThemeInterface
    navigation?: INavigation
    configuration?: IConfiguration
    basket: IBasket
    catalogue: ICatalogue
  } & InjectedTranslateProps
> = ({ basket, navigation, configuration, catalogue, t }) => {
  const { mainItem, selectedDelivery } = basket
  const {
    item,
    lineTotalPrice,
    quantity,
    imageUrl,
    selectedAddOns,
    dateSelection
  } = mainItem!
  const { name, isEvent, parameterSelection } = item

  const addOnsWithDetails: any[] = []

  selectedAddOns.forEach((addon: any) => {
    const catalogueItemForAddon = catalogue.getItemBySku(addon.sku)
    const details = catalogueItemForAddon.parameters[0]

    const addOnWithDetails = {
      addon,
      details
    }

    addOnsWithDetails.push(addOnWithDetails)
  })

  return (
    <Container className="row">
      <div className="d-block d-sm-none col">
        <div className="media">
          <img
            srcSet={`${imageUrl('small', 168)} 1x, ${imageUrl(
              'small',
              336
            )} 2x`}
            className="align-self-start mr-3"
          />
          <div className="media-body">
            <h6 className="product-title text-uppercase ls-8">{name}</h6>
          </div>
        </div>
        <div className="mt-3 mb-4">
          {dateSelection && (
            <Fragment>
              <h6 className="text-uppercase m-0 ls-8">
                {t('orderSummary.when', {
                  defaultValue: 'When'
                })}
              </h6>
              <Value className="m-0 ls-6">{dateSelection}</Value>
            </Fragment>
          )}
          {configuration.campaign !== 'advocacy' && (
            <Link
              href="#"
              onClick={event => {
                event.preventDefault()
                if (navigation) {
                  navigation.previousStep()
                }
              }}
            >
              Modify
            </Link>
          )}
        </div>
        <Details className="py-4">
          <h5 className="text-uppercase m-0 ls-8">delivery:</h5>
          <Value className="m-0 ls-8">
            {selectedDelivery && selectedDelivery.name}
          </Value>
        </Details>
        <Details className="py-4">
          <h5 className="text-uppercase m-0 ls-8">quantity:</h5>
          <Value className="m-0 ls-8">
            {isEvent ? parameterSelection : quantity}
          </Value>
        </Details>
        <Details className="py-4 text-right ls-7">
          <h6 className="m-0">{currencyToString(lineTotalPrice)}</h6>
        </Details>
        {addOnsWithDetails.map((addonWithDetails: any, idx: any) => {
          const { addon, details } = addonWithDetails

          const debouncedSave = createDebouncedSave(addon)
          return (
            <div key={addon.sku}>
              <div className={classnames('addon py-4', { first: idx === 0 })}>
                <AddonFlag />
                <h6 className="text-uppercase mt-3 mb-0">
                  {addon.name}
                  <span className="float-right">
                    {currencyToString(addon.lineTotalPrice)}
                  </span>
                </h6>
              </div>
              <Details className="py-4">
                <h5 className="text-uppercase m-0 ls-8">delivery:</h5>
                <Value className="m-0 ls-8">
                  {t('orderSummary.addon.collectionOnDay', {
                    defaultValue: 'Collection on day'
                  })}
                </Value>
              </Details>
              <Details className="py-4">
                <h5 className="text-uppercase m-0 ls-8">quantity:</h5>
                <Value className="m-0 ls-8">
                  <Disableable disabled={appStore.hasPendingRequests}>
                    <QuantityButtonGroup
                      className="d-inline-block mr-4"
                      min={details ? details.minValue : null}
                      max={details ? details.maxValue : null}
                      quantity={addon.parameters[0].value}
                      onAdd={() => {
                        addon.addToQuantity()
                        debouncedSave()
                      }}
                      onRemove={() => {
                        addon.removeFromQuantity()
                        debouncedSave()
                      }}
                    />
                  </Disableable>
                  <Link
                    href="#"
                    onClick={event => {
                      event.preventDefault()
                      addon.setSelected(false)
                      addon.saveSelected()
                    }}
                  >
                    {t('orderSummary.addon.delete', {
                      defaultValue: 'Delete'
                    })}
                  </Link>
                </Value>
              </Details>
            </div>
          )
        })}
      </div>
      <Table className="d-none d-sm-table table table-borderless m-0">
        <thead>
          <tr>
            {['Items', '', 'Quantity', 'Price'].map((title, idx) => (
              <th key={title} scope="col">
                <h6
                  className={classnames('text-uppercase m-0 ls-5', {
                    'text-right': idx === 3,
                    'text-center': idx === 2
                  })}
                >
                  {title}
                </h6>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <img
                srcSet={`${imageUrl('large', 288)} 1x, ${imageUrl(
                  'large',
                  576
                )} 2x`}
                className="float-left"
              />
              <h4 className="product-title text-uppercase ls-8">{name}</h4>
              <div>
                {configuration.campaign !== 'advocacy' && (
                  <Link
                    href="#"
                    onClick={event => {
                      event.preventDefault()
                      if (navigation) {
                        navigation.previousStep()
                      }
                    }}
                  >
                    Modify
                  </Link>
                )}
              </div>
            </th>
            <td></td>
            <td className="text-center">
              {isEvent ? parameterSelection : quantity}
            </td>
            <td className="text-right font-brand">
              {currencyToString(lineTotalPrice)}
            </td>
          </tr>
          <tr className="schedule">
            <td colSpan={4}>
              {dateSelection && (
                <Fragment>
                  <h6 className="text-uppercase m-0 ls-5 d-inline-block">
                    {t('orderSummary.when', {
                      defaultValue: 'When'
                    })}
                  </h6>
                  <Value className="d-inline-block m-0 ls-5 pl-1">
                    {dateSelection}
                  </Value>
                </Fragment>
              )}
            </td>
          </tr>
          {addOnsWithDetails.map((addOnWithDetails: any, idx: any) => {
            const { addon, details } = addOnWithDetails

            const debouncedSave = createDebouncedSave(addon)

            return (
              <tr
                key={addon.sku}
                className={classnames('addon', { first: idx === 0 })}
              >
                <th scope="row">
                  <AddonFlag />
                  <h6 className="text-uppercase ml-4 my-0 ls-7 d-inline-block">
                    {addon.name}
                  </h6>
                </th>
                <td>
                  {t('orderSummary.addon.collectionOnDay', {
                    defaultValue: 'Collection on day'
                  })}
                </td>
                <td>
                  <Disableable disabled={appStore.hasPendingRequests}>
                    <QuantityButtonGroup
                      className="d-inline-block mr-4"
                      min={details ? details.minValue : null}
                      max={details ? details.maxValue : null}
                      quantity={addon.parameters[0].value}
                      onAdd={() => {
                        addon.addToQuantity()
                        debouncedSave()
                      }}
                      onRemove={() => {
                        addon.removeFromQuantity()
                        debouncedSave()
                      }}
                    />
                  </Disableable>
                  <Link
                    href="#"
                    onClick={event => {
                      event.preventDefault()
                      addon.setSelected(false)
                      addon.saveSelected()
                    }}
                  >
                    {t('orderSummary.addon.delete', {
                      defaultValue: 'Delete'
                    })}
                  </Link>
                </td>
                <td className="text-right">
                  {currencyToString(addon.lineTotalPrice)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default translate()(
  inject(({ appStore }) => {
    return {
      configuration: appStore.configuration,
      navigation: appStore && (appStore.navigation as INavigation)
    }
  })(observer(ItemsTable))
)
