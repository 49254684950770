import * as React from 'react'
import classnames from 'classnames'
import styled from 'tools/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  icon?: React.ReactNode
  isLoading?: boolean
}

const ButtonContainer = styled<ButtonProps, any>('button')`
  .icon {
    top: 12px;
    left: 21px;
  }

  .icon + .content {
    position: relative;
    margin-left: 32px;
  }

  .not-loading svg {
    path {
      fill: ${props => props.theme.white};
    }
  }

  &:hover .not-loading {
    svg {
      path {
        fill: ${props => props.theme.slateBlue};
      }
    }
  }
`

const Button: React.SFC<ButtonProps> = ({
  children,
  icon,
  className,
  isLoading,
  ref,
  ...props
}) => {
  const btnIcon = isLoading ? (
    <FontAwesomeIcon
      data-test="ButtonSpinner"
      size="2x"
      spin
      icon={faSpinner}
    />
  ) : (
    icon
  )

  return (
    <ButtonContainer
      data-test="ButtonContainer"
      type="button"
      className={classnames(
        'btn btn-slate text-uppercase position-relative',
        className
      )}
      disabled={isLoading || props.disabled}
      {...props}
    >
      {btnIcon && (
        <span
          className={classnames('icon align-baseline position-absolute', {
            'not-loading': !isLoading
          })}
        >
          {btnIcon}
        </span>
      )}
      <span className="content">{children}</span>
    </ButtonContainer>
  )
}

export default Button
