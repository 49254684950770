import * as React from 'react'
import {
  IMeasure,
  addInfoCallback,
  removeInfoCallback
} from 'tools/withStickyBehaviour'

// TODO Retrieve that directly from bootstrap properties?
const Breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200
}

export type BreakpointLabels = keyof typeof Breakpoints

export const hasMobileSize = (pageWidth: number): boolean =>
  pageWidth < Breakpoints.sm

export interface WithBreakpointsProps {
  isMobile: boolean
}

export const withBreakpoints = <P extends object>(
  Component: React.ComponentType<P & WithBreakpointsProps>
) => {
  return class WithBreakpoints extends React.Component<
    P,
    WithBreakpointsProps
  > {
    state = {
      isMobile: true // By default we assume running on mobile
    }

    componentDidMount() {
      addInfoCallback(this.handlePageSizeChange)
    }

    componentWillUnmount() {
      removeInfoCallback(this.handlePageSizeChange)
    }

    handlePageSizeChange = (measure: IMeasure): void => {
      this.setState(() => ({
        isMobile: hasMobileSize(measure.clientWidth)
      }))
    }

    render() {
      const { isMobile } = this.state

      return (
        <div>
          <Component {...this.props} isMobile={isMobile} />
        </div>
      )
    }
  }
}

export default Breakpoints
