import * as React from 'react'
import classnames from 'classnames'

export default class PrivacySelect extends React.Component<{
  privacy: boolean
  onChange: (privacy: boolean) => void
}> {
  render() {
    const { privacy, onChange } = this.props

    return (
      <div className="btn-group m-2" role="group" aria-label="Theme selection">
        {[{ name: 'ON', value: 'true' }, { name: 'OFF', value: '' }].map(
          ({ name, value }) => (
            <button
              key={name}
              type="button"
              className={classnames(
                'btn btn-sm',
                !!value === privacy ? 'btn-primary' : 'btn-outline-primary'
              )}
              onClick={() => onChange(!!value)}
            >
              {name.toUpperCase()}
            </button>
          )
        )}
      </div>
    )
  }
}
