import * as React from 'react'
import classnames from 'classnames'

export default class ThemeSelect extends React.Component<{
  theme: string
  onChange: (theme: string) => void
}> {
  render() {
    const { theme, onChange } = this.props

    return (
      <div className="btn-group m-2" role="group" aria-label="Theme selection">
        {['jaguar', 'landrover'].map(themeName => (
          <button
            key={themeName}
            type="button"
            className={classnames(
              'btn btn-sm',
              theme === themeName ? 'btn-primary' : 'btn-outline-primary'
            )}
            onClick={() => onChange(themeName)}
          >
            {themeName.toUpperCase()}
          </button>
        ))}
      </div>
    )
  }
}
