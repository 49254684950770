import React, { Fragment, SyntheticEvent, useState } from 'react'
import { IConfiguration } from 'stores/Configuration'
import TextInput from 'atoms/Form/TextInput'
import Button from 'atoms/Button'

export const AdvocacyCodeCheck = ({ config }: { config: IConfiguration }) => {
  const [code, setCode] = useState('')
  const validateCode = async (e: SyntheticEvent) => {
    e.preventDefault()
    config.validateCampaignCodeAndSetAvailableProducts(code)
  }
  return (
    <Fragment>
      <p className="mb-5">Please re-confirm your voucher code</p>
      <TextInput
        name="advocacyCode"
        label="Voucher Code"
        value={code}
        onChange={e => {
          setCode(e.currentTarget.value)
        }}
      ></TextInput>
      <Button disabled={!code} onClick={validateCode}>
        Confirm code
      </Button>
    </Fragment>
  )
}
