import {
  types,
  flow,
  getParent,
  applySnapshot,
  getRoot,
  getSnapshot
} from 'mobx-state-tree'
import Item from 'stores/Catalogue/Item'
import Money from 'stores/Basket/Money'
import { Customisations } from 'stores/Basket/Customisation'
import request from 'tools/request'
import AddOn, { IAddOn } from 'stores/Basket/AddOn'
import { IImageUrl } from 'stores/Configuration'
import DateRange, { IDateRange } from 'stores/Availability/DateRange'
import TimeRange, { ITimeRange } from 'stores/Availability/TimeRange'
import { find, sumBy, filter } from 'lodash'
import { format } from 'date-fns'
import { gteAddToCart, gteRemoveFromCart } from 'tools/analytics'

let firstDatesUpdate = true
let firstTimesUpdate = true

const Organisation = types.model('Organisation', {
  stripePublishableKey: types.maybe(types.string)
})
const Entry = types
  .model('BasketEntry', {
    id: types.maybe(types.identifier(types.string)),
    name: types.maybe(types.string),
    isUpdating: types.optional(types.boolean, false),
    sku: types.maybe(types.reference(Item)),
    addOns: types.optional(types.array(AddOn), []),
    quantity: types.optional(types.number, 1),
    customisations: types.optional(Customisations, {
      customisationsArray: [{ customText: '' }]
    }),
    organisation: types.maybe(Organisation),
    listPrice: types.maybe(Money),
    lineTotalPrice: types.maybe(Money),
    discountTotal: types.maybe(Money),
    discountedPrice: types.maybe(Money),
    startDate: types.maybe(types.string),
    startTime: types.maybe(types.string),
    selectedDateRange: types.maybe(DateRange),
    selectedTimeRange: types.maybe(TimeRange),
    lastInventory: types.maybe(types.number)
  })
  .views(self => ({
    get dateSelection(): string {
      let selection = ''

      if (
        self.sku.isTimeslot &&
        self.selectedDateRange &&
        self.selectedTimeRange
      ) {
        selection = `${self.selectedDateRange.toString({ showYear: true })} / ${
          self.selectedTimeRange.asString
        }`
      } else if (self.selectedDateRange) {
        selection = self.selectedDateRange.toString({
          showYear: true,
          showDuration: true
        })
      }

      return selection
    },
    get recipientName() {
      return self.customisations[0].customText
    },
    get item() {
      return self.sku
    },
    get selectedAddOns(): IAddOn[] {
      return self.addOns.filter(addon => !!addon.selected)
    },
    get imageUrl(): IImageUrl {
      return getRoot(self).configuration.imageUrl({
        category: 'product',
        placement: 'feature',
        identifier: self.sku.sku
      })
    }
  }))
  .actions(self => {
    const actions = {
      setSelectedDateRange: (
        dateRange?: IDateRange,
        setIsUpdating?: boolean
      ) => {
        if (firstDatesUpdate === false && setIsUpdating) {
          self.isUpdating = true
        } else {
          self.isUpdating = false
        }
        firstDatesUpdate = false
        self.selectedDateRange = dateRange
          ? DateRange.create(getSnapshot(dateRange))
          : null
      },

      hasEnoughInventory: (newInventory: number): boolean => {
        const inventoryLinkedParameters = filter(
          self.sku.parameters,
          p => p.inventoryLinked
        )
        const totalParametersValue = sumBy(
          inventoryLinkedParameters,
          p => p.value
        )
        return newInventory >= totalParametersValue
      },

      resetParametersToDefaults: () => {
        self.sku.setParametersValue(self.sku.defaultParameters)
      },

      setSelectedTimeRange: (
        timeRange?: ITimeRange,
        setIsUpdating?: boolean
      ) => {
        // TODO: Keep track of timerange.inventory, if the new one is less than the old one,
        // ask for confirmation and reset the parameters
        if (firstTimesUpdate === false && setIsUpdating) {
          self.isUpdating = true
        }
        firstTimesUpdate = false
        if (timeRange) {
          self.selectedTimeRange = TimeRange.create(getSnapshot(timeRange))
        } else {
          self.selectedTimeRange = null
        }
      },

      setCustomisations: flow(function* setCustomisations(customisations: any) {
        self.customisations = customisations
        yield self.customisations.save()
      }),

      incrementQuantity() {
        self.quantity = self.quantity + 1
        self.customisations.customisationsArray.push({
          customText: ''
        })
        gteAddToCart()
      },

      setQuantity(quantity: number) {
        self.quantity = quantity
      },

      decrementQuantity() {
        self.quantity = self.quantity - 1
        self.customisations.customisationsArray.pop()
        gteRemoveFromCart()
      },

      updatePrice: flow(function* updatePrice() {
        try {
          const { data } = yield request(
            `commerce/baskets/${getParent(self, 2).id}/items/${
              self.id
            }/parameters`,
            {
              method: 'put',
              data: [
                {
                  id: 'quantity',
                  value: self.quantity
                }
              ]
            }
          )

          const currentItem: any = find(data.items, { id: self.id })
          let quantity: number
          if (currentItem && currentItem.parameters) {
            const quantityParameter: any = find(currentItem.parameters, {
              id: 'quantity'
            })
            if (quantityParameter && quantityParameter.value) {
              quantity = parseInt(quantityParameter.value, 10)
            }
          }
          delete data.deliveryDetail
          delete data.billingAddress
          applySnapshot(getParent(self, 2), data)
          if (quantity) {
            self.quantity = quantity
          }
        } catch (err) {
          throw new Error(`Basket.Entry.updatePrice : ${JSON.stringify(err)}`)
        }
      }),

      updateBooking: flow(function* updateBooking({
        date,
        startTime,
        endTime,
        parameters
      }: {
        date: Date
        startTime?: Date
        endTime?: Date
        parameters: { [s: string]: string | number }
      }) {
        try {
          // Saving to basket details
          const reqData = {
            start_date: format(date, 'YYYY-MM-DD'),
            start_time: startTime ? format(startTime, 'HH:mm') : undefined,
            end_time: endTime ? format(endTime, 'HH:mm') : undefined,
            parameters
          }

          const { data } = yield request(
            `commerce/baskets/${getParent(self, 2).id}/items/${self.id}`,
            {
              method: 'put',
              data: reqData
            }
          )

          // Copy selected dates and reassign after apply
          // That is because basket is not returning those values
          const selectedDateRange = self.selectedDateRange
            ? getSnapshot(self.selectedDateRange)
            : null
          const selectedTimeRange = self.selectedTimeRange
            ? getSnapshot(self.selectedTimeRange)
            : null

          applySnapshot(getParent(self, 2), data)
          self.selectedDateRange = selectedDateRange
          self.selectedTimeRange = selectedTimeRange
          self.isUpdating = false
        } catch (err) {
          throw new Error(`Basket.Entry.updateBooking : ${JSON.stringify(err)}`)
        }
      })
    }
    return actions
  })

export type IEntry = typeof Entry.Type
export type IItemSnapshot = typeof Entry.SnapshotType
export default Entry
