import * as React from 'react'

import TextInput from 'atoms/Form/TextInput'
import Button from 'atoms/Button'
import { IBasket } from 'stores/Basket'
import { InjectedTranslateProps } from 'react-i18next'

enum Status {
  None,
  Applying,
  Applied
}

type State = {
  status: Status
  code: string
  error: string | undefined
}

export class VoucherCodeInput extends React.Component<
  { basket: IBasket } & InjectedTranslateProps,
  State
> {
  state = {
    status: this.props.basket.discountCodeUsed ? Status.Applied : Status.None,
    code: this.props.basket.discountCodeUsed || '',
    error: undefined
  }

  handleApplyVoucher = async () => {
    const { t, basket } = this.props
    const { code } = this.state

    this.setState(() => ({ status: Status.Applying, error: undefined }))

    // Call basket to apply code
    try {
      const isCodeValid = code.match(/^[a-zA-Z0-9_\\-]*$/g)
      if (!isCodeValid || code.length > 25) {
        this.setState(() => ({
          error: t('voucherCodeInput.error.notValid', {
            defaultValue: 'Code not valid'
          }), // TODO Use message/code returned
          status: Status.None
        }))
        return
      }
      await basket.applyDiscountCode(code)

      this.setState(() => ({ status: Status.Applied }))
    } catch (error) {
      this.setState(() => ({
        error: t('voucherCodeInput.error.notValid', {
          defaultValue: 'Code not valid'
        }), // TODO Use message/code returned
        status: Status.None
      }))
    }
  }

  handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(() => ({
      code: value,
      status: Status.None,
      error: undefined
    }))
  }

  render() {
    const { t } = this.props
    const { status, code, error } = this.state

    return (
      <TextInput
        labelAsPlaceholder
        noMargin
        disabled={status !== Status.None}
        name="code"
        value={code}
        onChange={this.handleCodeChange}
        optional
        label={t('voucherCodeInput.enterCodePlaceholder', {
          defaultValue: 'Enter code'
        })}
        className="text-left m-0"
        error={error}
        addon={
          <Button
            data-test="VoucherInputApplyButton"
            isLoading={status === Status.Applying}
            onClick={this.handleApplyVoucher}
            disabled={status !== Status.None || !code}
          >
            {status === Status.Applying
              ? t('voucherCodeInput.status.applying', {
                  defaultValue: 'Applying'
                })
              : status === Status.Applied
              ? t('voucherCodeInput.status.applied', {
                  defaultValue: 'Applied'
                })
              : t('voucherCodeInput.status.apply', {
                  defaultValue: 'Apply'
                })}
          </Button>
        }
      />
    )
  }
}

export default VoucherCodeInput
