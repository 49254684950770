import { types, flow, getRoot } from 'mobx-state-tree'
import request from 'tools/request'
import axios from 'axios'
import { gtePurchase, fbpxPurchase } from 'tools/analytics'
import { IBasket } from 'stores/Basket'

export class PaymentError extends Error {
  constructor(m: string) {
    super(m)
    this.name = 'PaymentError'
    // Set the prototype explicitly for instanceof to work
    Object.setPrototypeOf(this, PaymentError.prototype)
  }
}

const Order = types
  .model('Order', {
    orderReference: types.string,
    paymentUrl: types.maybe(types.string),
    documentUrl: types.maybe(types.string),
    guestFormUrl: types.maybe(types.string),
    clientSecret: types.maybe(types.string),
    status: types.optional(
      types.enumeration('orderStatus', ['created', 'paid', 'error']),
      'created'
    )
  })
  .actions(self => {
    const CancelToken = axios.CancelToken
    const cancelTokenSource = CancelToken.source()
    return {
      checkPaymentDone: flow(function* checkPaymentDone() {
        try {
          const response = yield request(
            `commerce/orders/${self.orderReference}/notifications`,
            {
              query: {
                status: 'PREAUTH_SUCCESS'
              },
              cancelator: {
                cancelToken: cancelTokenSource.token
              }
            }
          )

          if (response.status === 200) {
            self.status = 'paid'
            const { basket }: { basket: IBasket } = getRoot(self)

            if (!basket.grossPrice) {
              console.error('Could not get basket store')
              return
            }

            gtePurchase()
            fbpxPurchase()
          } else if (response.status === 204) {
            self.status = 'error'
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            return
          } else {
            self.status = 'error'
            throw new PaymentError(error.message)
          }
        }
      }),
      cancelPaymentDonePoll: function() {
        // TODO: This is causing issues at the moment. Investigate and either fix or remove this code
        // cancelTokenSource.cancel('Cancelling long poll for payment done check')
      },
      setPaid: function() {
        self.status = 'paid'
        gtePurchase()
      }
    }
  })

export type IOrder = typeof Order.Type
export type IOrderSnapshot = typeof Order.SnapshotType
export default Order
