import React from 'react'
import { IdealBankElement, ElementsConsumer } from '@stripe/react-stripe-js'
import IdealSection from './IdealSection'
import stripeJs, { PaymentIntent, StripeError } from '@stripe/stripe-js'
import Button from '../Button'
import LockIcon from '../Icons/Lock'
import { hasMobileSize } from '../../tools/breakpoints'
import { IStepContent } from '../../organisms/Main'
import * as Sentry from '@sentry/browser'

type IdealPaymentProps = IStepContent & {
  stripe: stripeJs.Stripe | null
  elements: stripeJs.StripeElements | null
  clientSecret: string
}

class IdealPaymentForm extends React.Component<
  IdealPaymentProps,
  { isLoading: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { isLoading: false }
  }

  handlePayButton = () => {
    const {
      basket: { billingAddress },
      basket,
      navigation,
      stripe,
      elements,
      clientSecret,
      t
    } = this.props

    if (clientSecret === '' || typeof clientSecret === 'undefined') {
      const stripeMsg =
        'Error: Missing the required client secret for iDEAL payment submission.'
      Sentry.captureMessage(stripeMsg)
      console.error(stripeMsg)
      return
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return
    }
    this.setState({ isLoading: true })

    stripe
      .confirmIdealPayment(clientSecret, {
        payment_method: {
          ideal: elements.getElement(IdealBankElement),
          billing_details: {
            name: billingAddress.firstName + ' ' + billingAddress.lastName,
            email: billingAddress.email,
            address: {
              country: billingAddress.country,
              postal_code: billingAddress.postalCode,
              line1: billingAddress.addressLine1,
              line2: billingAddress.addressLine2,
              city: billingAddress.postalTown
            }
          }
        }
      })
      .then((value: { error?: StripeError; paymentIntent?: PaymentIntent }) => {
        this.setState({ isLoading: false })

        if (value.error) {
          const stripeMsg = t('Stripe.errors.notProcessed', {
            defaultValue:
              'We were unable to process your payment.' +
              ' Please check your payment information and try again.'
          })
          Sentry.captureMessage(stripeMsg)
          alert(stripeMsg)
        } else {
          basket.order.setPaid()
          navigation.nextStep()
        }
      })
  }

  render() {
    const isMobileWidth = hasMobileSize(window.innerWidth)
    const buttonClass = isMobileWidth ? 'btn-block' : 'float-right'

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <IdealSection />
          </div>
        </div>
        <div className="row pos-container">
          <div className="col-md-12">
            <Button
              onClick={this.handlePayButton}
              className={buttonClass}
              icon={<LockIcon />}
              isLoading={this.state.isLoading}
            >
              Submit Payment
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default function InjectedIdealPaymentForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <IdealPaymentForm
          stripe={stripe}
          elements={elements}
          basket={props.basket}
          navigation={props.navigation}
          t={props.t}
          clientSecret={props.clientSecret}
        />
      )}
    </ElementsConsumer>
  )
}
