import * as React from 'react'

const ArrowIcon: React.SFC<{ className?: string }> = ({ className }) => (
  <svg
    width="10px"
    height="14px"
    viewBox="0 0 10 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <defs>
      <polygon
        id="path-1-abcde"
        points="2.91666667 6.9860914 9.85650098 0 11.0799505 1.23247232 5.35398113 6.99744536 11.0833333 12.76696 9.85988379 14 2.92004948 7.01220551 2.93188931 7.00085155" // tslint:disable-line
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-2.000000, 0.000000)">
        <mask id="mask-2-xyz" fill="white">
          <use xlinkHref="#path-1-abcde" />
        </mask>
        <g mask="url(#mask-2-xyz)" fill="#4A4F54" fillRule="evenodd">
          <g transform="translate(-14.000000, -14.000000)">
            <rect x="0" y="0" width="42" height="42" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ArrowIcon
