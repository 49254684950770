/* eslint-disable */
import i18n from 'i18next'
import * as Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'
import { setLocale } from 'yup' // tslint:disable-line

let _instance: any

const i18nFactory = (
  {
    language,
    debug
  }: {
    language: string
    debug?: boolean
  } = { language: 'en' }
): i18n.i18n => {
  if (_instance) {
    return _instance
  }

  // Don't load backend XHR in test environment to avoid AJAX errors
  // translations are mocked in test already - see __mocks__/react-i18next.js file
  _instance = process.env.NODE_ENV === 'test' ? i18n : i18n.use(Backend)

  _instance
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init(
      {
        lng: language,
        fallbackLng: 'en',

        // have a common namespace used around the full app
        ns: ['translation'],
        defaultNS: 'translation',

        debug: !!debug,

        interpolation: {
          escapeValue: false // not needed for react!!
        },

        react: {
          wait: true
        },

        backend: {
          // Avoid caching issues with the backend by loading a new version every
          //  time version is changed
          queryStringParams: { v: process.env.REACT_APP_GIT_VERSION }
        }
      },
      (err: any, t: i18n.TranslationFunction) => {
        if (err) {
          // TODO Show general error message
          throw new Error('something went wrong loading')
        }

        // Setting translations for validation messages (yup)
        setLocale({
          mixed: {
            required: t('yup.mixed.required', {
              defaultValue: '${path} is a required field'
            })
          },
          string: {
            email: t('yup.string.email', {
              defaultValue: '${path} must be a valid email'
            })
          }
        })
      }
    )

  return _instance
}

export default i18nFactory
