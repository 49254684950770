import * as React from 'react'

const TickIcon: React.SFC<{}> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="a"
        d="M8.845 21c-.366 0-.718-.15-.977-.415l-6.465-6.651a1.444 1.444 0 0 1 .002-2.003 1.353 1.353 0 0 1 1.951.002l5.49 5.645L21.642 4.416a1.355 1.355 0 0 1 1.952-.002c.54.552.54 1.45.002 2.004L9.822 20.585c-.26.266-.61.415-.977.415" // tslint:disable-line
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#0C121C" xlinkHref="#a" />
      <g fill="#0C121C" mask="url(#b)">
        <path d="M-24-24h72v72h-72z" />
        <path d="M-24-24h72v72h-72z" />
      </g>
    </g>
  </svg>
)

export default TickIcon
