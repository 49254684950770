/* eslint-disable */
import { types, flow, applySnapshot, getParent, getRoot } from 'mobx-state-tree'
import Money from 'stores/Basket/Money'
import AddOnParameter from 'stores/Basket/AddOnParameter'
import request from 'tools/request'
import { IImageUrl } from 'stores/Configuration'
import { IItem } from 'stores/Catalogue/Item'
import { IAppStore } from 'stores/AppStore'
import { remove } from 'lodash'
import { IBasket } from 'stores/Basket'
import { gteAddToCart, gteRemoveFromCart } from 'tools/analytics'

const AddOn = types
  .model('BasketAddOn', {
    sku: types.identifier(types.string),
    id: types.string,
    name: types.string,
    parameters: types.optional(types.array(AddOnParameter), []),
    description: types.maybe(types.string),
    listPrice: Money,
    lineTotalPrice: Money,
    discountTotal: Money,
    discountedPrice: Money,
    selected: types.boolean,
    bundled: types.boolean
  })
  .views(self => ({
    get quantity(): number {
      const parameter = self.parameters[0]
      return parameter ? parameter.value : 0
    },
    get imageUrl(): IImageUrl {
      return getRoot(self).configuration.imageUrl({
        category: 'product',
        placement: 'add-on',
        identifier: self.sku
      })
    },
    get parametersPayload() {
      const clean = self.parameters.map(p => {
        const copy: any = Object.assign({}, p)
        delete copy.name
        return copy
      })

      // TODO: Once the service response has eliminated legacy quantity parameter, this should be deleted
      remove(clean, c => c.id === 'quantity')

      return clean
    }
  }))
  .actions(self => ({
    saveParametersAndApplySnapshot: flow(
      function* saveParametersAndApplySnapshot() {
        const rootStore: IAppStore = getRoot(self)
        // Initialise basket
        const { data } = yield request(
          `commerce/baskets/${getParent(self, 4).id}/items/${
            getParent(self, 2).id
          }/add-on/${self.id}/parameters`,
          {
            method: 'put',
            data: self.parametersPayload
          }
        )
        // Apply snapshot of updated basket info
        applySnapshot(getParent(self, 4), data)
      }
    ),
    saveSelected: flow(function* setSelected() {
      const rootStore: IAppStore = getRoot(self)
      const basketStore: IBasket = rootStore.basket
      const { data, status } = yield request(
        `commerce/baskets/${getParent(self, 4).id}/items/${
          getParent(self, 2).id
        }/add-on/${self.id}`,
        {
          method: 'put',
          data: { selected: self.selected }
        }
      )

      if (status < 400) {
        const dataMainItem = data.items[0]
        const storeMainItem = basketStore.items[0]
        dataMainItem.selectedDateRange = storeMainItem.selectedDateRange
        dataMainItem.selectedTimeRange = storeMainItem.selectedTimeRange
        applySnapshot(basketStore, data)
      } else {
        console.error('There was a problem adding the addon')
      }
    }),

    setSelected(selected: boolean) {
      const rootStore: IAppStore = getRoot(self)
      self.selected = selected
      gteAddToCart()
    },

    appendCatalogueParameters(parameters: IItem) {
      self.parameters = Object.assign({}, self.parameters, parameters)
    },

    setParameterValue: flow(function* setParameterValue(value: number) {
      const rootStore: IAppStore = getRoot(self)
      const parameter = self.parameters[0]
      if (parameter) {
        parameter.value = value
      }
    })
  }))

  .actions(self => ({
    addToQuantity: () => {
      self.setParameterValue(self.quantity + 1)
      gteAddToCart()
    },
    removeFromQuantity: () => {
      self.setParameterValue(self.quantity - 1)
      gteRemoveFromCart()
    }
  }))

export type IAddOn = typeof AddOn.Type
export type IAddOnSnapshot = typeof AddOn.SnapshotType
export default AddOn
