import * as React from 'react'
import styled, { media } from 'tools/theme'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import { IImageUrl } from 'stores/Configuration'

interface Props {
  imageFn: IImageUrl
  open?: boolean
  price?: string
  onToggle?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Container = styled.div`
  background-color: ${props => props.theme.extraLightGrey};

  h6 {
    position: absolute;
    bottom: -5px;

    ${media.sm`
      position: relative;
      bottom: 0;
    `};
  }
`

const Image = styled.div`
  height: 100px;
  background-image: url(${(props: Props) => props.imageFn('large', 570)});
  background-image: image-set(
    url(${props => props.imageFn('large', 570)}) 1x,
    url(${props => props.imageFn('large', 1140)}) 2x
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 50%;
  transition: height 0.3s ease-out;

  ${media.md`
      background-image: url(${(props: Props) => props.imageFn('medium', 360)});
      background-image: image-set(
        url(${(props: Props) => props.imageFn('medium', 360)}) 1x,
        url(${(props: Props) => props.imageFn('medium', 720)}) 2x
      );
    `};

  ${media.sm`
      height: ${(props: Props) => (props.open ? '50px' : '40px')};
      background-image: url(${(props: Props) => props.imageFn('medium', 768)});
      background-image: image-set(
        url(${(props: Props) => props.imageFn('medium', 768)}) 1x,
        url(${(props: Props) => props.imageFn('medium', 1536)}) 2x
      );
      box-shadow: ${(props: Props) =>
        props.open ? 'none' : '0 -3px 7px 0 rgba(0, 0, 0, 0.3)'};
    `};

  ${media.xs`
    background-image: url(${(props: Props) => props.imageFn('small', 576)});
    background-image: image-set(
      url(${(props: Props) => props.imageFn('small', 576)}) 1x,
      url(${(props: Props) => props.imageFn('small', 1152)}) 2x
    );
  `};
`

const Price = styled.h3`
  display: none;

  ${media.sm`display: block;`};
`

const ToggleButton = styled<Props, any>('button')`
  position: ${(props: Props) => (props.open ? 'absolute' : 'inherit')};
  transition: position 0.3s ease-out;
  right: 1.4rem;
  bottom: 0.2rem;
`

const skipImages = process.env.SKIP_IMAGES

const Header: React.SFC<Props & InjectedTranslateProps> = ({
  imageFn,
  price,
  open,
  onToggle,
  t
}) => {
  return (
    <div data-test="BasketHeader">
      <div className="row">
        {!skipImages && <Image open={open} className="col" imageFn={imageFn} />}
      </div>
      <div className="row">
        <Container className="col position-relative">
          {/* <ImageMask className="position-absolute" /> */}
          <h6
            className={classnames('text-uppercase ls-8 m-md-0 mb-0', {
              'mt-4': open
            })}
          >
            {t('basket.title.mybasket', {
              defaultValue: 'My Basket'
            })}
          </h6>
          {price && (
            <div className="pt-1 pb-2 d-block d-md-none">
              {!open && (
                <Price
                  data-test="BasketHeaderPrice"
                  className="ls-8 m-0 d-inline-block"
                >
                  {price}
                </Price>
              )}
              <ToggleButton
                data-test="BasketHeaderToggleButton"
                className="btn btn-link btn-sm float-right"
                onClick={onToggle}
                open={open}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={open ? faChevronUp : faChevronDown}
                />
              </ToggleButton>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default translate()(Header)
