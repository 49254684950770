import * as React from 'react'
import styled from 'tools/theme'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { observer } from 'mobx-react'

interface Props {
  steps: string[]
  currentStep: number
}

const Container = styled.h4`
  color: #4a4a4a;
`

const StepCounter: React.SFC<Props & InjectedTranslateProps> = ({
  steps,
  currentStep,
  t
}) => {
  return (
    <Container className="text-uppercase ls-7 mt-5 mb-3">
      {t('stepsinfo.steps', {
        defaultValue: 'Step {{ index }} of {{ length }}: {{ current }}',
        index: currentStep + 1,
        length: steps.length,
        current: steps[currentStep]
      })}
    </Container>
  )
}

export default translate()(observer(StepCounter))
