import * as React from 'react'
import styled, { media } from 'tools/theme'
import classnames from 'classnames'

interface Props {
  title: string
  subtitle?: string
  price: string
  subprice?: string
  className?: string
  total?: boolean // If it is the total line (get a special design, normally the last line)
  header?: boolean // If it is a header line, shows the title bigger and in one line only
  noPadding?: boolean
  dataTest?: string
}

const Container = styled<Props, any>('div')`
  min-height: 80px;
  border-top: 2px solid white;
  padding-top: 30px !important;
  background-color: ${props =>
    props.total ? props.theme.mediumGrey : props.theme.extraLightGrey};
`

const Title = styled<Props, any>('h3')`
  ${media.xs`
    font-size: ${(props: Props) => (props.header ? '2.2rem' : '1.8rem')};
    margin: ${(props: Props) => (props.header ? '1.5rem 0' : '0')};
  `};
`

const Sub = styled.small`
  font-size: 1.4rem;
  font-weight: bold;
  color: inherit;

  ${media.xs`
    font-size: 1.2rem;
  `};
`

const Price = styled<Props, any>('h3')`
  font-size: ${props => (props.total ? '2.4rem' : '1.8rem')};

  ${media.xs`
    margin: ${(props: Props) => (props.header ? '0 0 1.5rem' : '0')};
    font-size: 1.8rem;
  `};
`

const PriceLine: React.SFC<Props> = ({
  title,
  subtitle,
  price,
  subprice,
  children,
  className,
  total,
  noPadding,
  dataTest,
  header
}) => {
  return (
    <Container
      data-test={dataTest}
      total={total}
      className={classnames(
        'row py-4 align-items-center price-line',
        className
      )}
    >
      <div
        className={classnames(
          !!children
            ? 'd-none d-sm-block col-sm'
            : `${header ? '' : 'col-8'} col-sm` // Make title hidden if children is present
        )}
      >
        <Title
          data-test="BasketPriceLineTitle"
          header={header}
          className={`${noPadding ? '' : 'pl-4'} my-0 ls-6 text-uppercase`}
        >
          {title}
          <Sub>{subtitle}</Sub>
        </Title>
      </div>
      {children && (
        <div className={`${header ? '' : 'col-8'} col-md text-center`}>
          {children}
        </div>
      )}
      <div className={`${header ? '' : 'col-4'} col-sm text-right`}>
        <Price
          header={header}
          total={total}
          className={`${noPadding ? '' : 'pr-4'} ls-5 text-uppercase`}
        >
          <span data-test="BasketPriceLinePrice" className="test-price">
            {price}
          </span>
          <Sub data-test="BasketPriceLineSubprice">{subprice}</Sub>
        </Price>
      </div>
    </Container>
  )
}

export default PriceLine
