import { types, flow, getParent } from 'mobx-state-tree'
import request from 'tools/request'
import { IEntry } from 'stores/Basket/Entry'
import { find } from 'lodash'

export const Customisation = types.model('Customisation', {
  customText: types.string
})

export const Customisations = types
  .model('Customisations', {
    customisationsArray: types.array(Customisation)
  })
  .actions(self => {
    const actions = {
      setCustomisation(customisations: any) {
        self.customisationsArray = customisations
      },
      save: flow(function* save() {
        const safeArray = self.customisationsArray.map((item: any) => ({
          custom_text: item.customText
        }))

        const { data } = yield request(
          `commerce/baskets/${getParent(self, 3).id}/items/${
            getParent(self, 1).id
          }/customisation`,
          {
            method: 'put',
            data: safeArray
          }
        )

        // Cannot apply basket again otherwise will get a mobx error
        //  at this time only set the customisation data directly on the current entry
        // instead of updating the whole structure (may need to change in the future if
        // customisation changes other data)
        const currentItem = find(
          data.items,
          (item: IEntry) => item.id === getParent(self, 1).id
        )

        if (currentItem && currentItem.customisation) {
          // TODO Format has changed to array, redo this
          // applySnapshot(self, currentItem.customisation)
        }
      })
    }

    return actions
  })

export type ICustomisations = typeof Customisations.Type
export type ICustomisation = typeof Customisation.Type
export type ICustomisationSnapshot = typeof Customisations.SnapshotType
