import React from 'react'
import { Container } from 'atoms/Container'
import styled from '@emotion/styled'

const model = [
  {
    label: 'Name',
    key: 'firstName'
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Phone',
    key: 'contactNumber'
  },
  {
    label: 'Address',
    key: 'addressLine1'
  },
  {
    label: 'Postcode',
    key: 'postalCode'
  },
  {
    label: 'Country',
    key: 'country'
  }
]

export const CustomerDetails = store => {
  const { billingAddress } = store.basket
  if (!billingAddress) return null
  return (
    <div style={{ margin: '14px -15px' }}>
      <Container>
        <Title>Customer details</Title>
        {model.map((item: { label: string; key: string }, index: number) => (
          <Label key={index}>
            {item.label}: {billingAddress[item.key]}
          </Label>
        ))}
      </Container>
    </div>
  )
}

const Title = styled('h3')({
  textTransform: 'uppercase',
  fontSize: 18
})

const Label = styled('label')({
  fontSize: 14,
  display: 'block'
})
