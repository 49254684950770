import * as React from 'react'
import { Field, FieldProps } from 'formik'
import QuantityOption, { IQuantityOption } from 'molecules/QuantityOption'

type Omit<T, K extends keyof T> = T extends any
  ? Pick<T, Exclude<keyof T, K>>
  : never

const StepQuantityOption: React.SFC<
  Omit<IQuantityOption, 'quantity'> & { name: string }
> = ({ name, ...props }) => {
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps<{ [s: string]: string }>) => (
        <QuantityOption
          {...props}
          quantity={field.value}
          onAdd={event => {
            form.setFieldValue(field.name, field.value + 1)
            if (props.onAdd) {
              props.onAdd(event)
            }
          }}
          onRemove={event => {
            form.setFieldValue(field.name, field.value - 1)
            if (props.onRemove) {
              props.onRemove(event)
            }
          }}
        />
      )}
    />
  )
}

export default StepQuantityOption
