import * as React from 'react'
import styled from 'tools/theme'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { IAddOn } from 'stores/Basket/AddOn'
import AddOnCard from 'atoms/AddOnCard'

const Container = styled.div`
  margin-top: 1.5rem;

  .addon-card {
    margin-top: 2.7rem;
  }

  > h2 {
    font-size: 2.6rem;
  }
`

const TailorYourExperience: React.SFC<
  { addOns: IAddOn[] } & InjectedTranslateProps
> = ({ t, addOns }) =>
  addOns.length ? (
    <Container>
      <h2 className="title text-uppercase ls-13">
        {t('content.tailorExperience', {
          defaultValue: 'Tailor Your Experience'
        })}
      </h2>
      <div className="row">
        {addOns.map(addOn => (
          <div key={addOn.sku} className="col-xl-6">
            <AddOnCard className="addon-card" key={addOn.sku} addOn={addOn} />
          </div>
        ))}
      </div>
    </Container>
  ) : null

export default translate()(TailorYourExperience)
