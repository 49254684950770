import * as styledComponents from 'styled-components'
import { set } from 'tools/query'
import Breakpoints, { BreakpointLabels } from 'tools/breakpoints'

const {
  default: styled,
  css,
  injectGlobal,
  keyframes,
  ThemeProvider,
  withTheme
} = styledComponents

const removeStylesheetFromDOM = (stylesheetId: string): void => {
  const sheet = document.getElementById(stylesheetId)
  if (sheet && sheet.parentNode) {
    sheet.parentNode.removeChild(sheet)
  }
}

export interface IThemeInterface {
  primary: string
  secondary: string
  darkCoolGrey: string
  extraLightGrey: string
  mediumGrey: string
  slateBlue: string
  grey1: string
  grey2: string
  grey3: string
  grey4: string
  grey5: string
  dark: string
  white: string
  danger: string
}

const initTheme = (
  { theme }: { theme: string } = { theme: 'landrover' }
): void => {
  // import(`jlr-bootstrap-theme/build/${theme}.css`)
  // Loading page theme depending on query string
  // import(`../../public/theme/${theme}.css`)
  // addStylesheetToDOM(`/theme/${theme}.css`)
  const themes = ['landrover', 'jaguar']
  themes
    .filter(availableTheme => availableTheme !== theme)
    .forEach(themeToRemove => removeStylesheetFromDOM(`${themeToRemove}-theme`))
}

const setTheme = (theme: string) => {
  set({ theme })
}

// Iterate through the sizes and create a media template
const media: { [s in BreakpointLabels]?: any } = Object.keys(
  Breakpoints
).reduce(
  (mediaQueries, label: BreakpointLabels) => ({
    ...mediaQueries,
    [label]: (...args: any[]) =>
      css`
        @media (max-width: ${Breakpoints[label]}px) {
          ${css.call(media, ...args)};
        }
      `
  }),
  {}
)

export default styled
export {
  css,
  injectGlobal,
  keyframes,
  ThemeProvider,
  withTheme,
  initTheme,
  setTheme,
  media
}
