import * as React from 'react'
import classnames from 'classnames'
import { inject, observer } from 'mobx-react'
import { IBasket } from 'stores/Basket'
import { INavigation } from 'stores/Navigation'
import { ICatalogue } from 'stores/Catalogue'
import HeroImage from 'molecules/HeroImage'
import ProgressBar from 'molecules/ProgressBar'
import StepCounter from 'molecules/StepCounter'
import OrderConfirmation from 'molecules/OrderConfirmation'
import MyBasket from 'organisms/MyBasket'
import BeforeStartInfo, { BeforeStartVariants } from 'atoms/BeforeStartInfo'
import NotImplemented from 'organisms/Scenarios/NotImplemented'
import { IAppStore } from 'stores/AppStore'
import ValidationErrorPanel from 'molecules/ValidationErrorPanel'
import ConflictErrorPanel from 'molecules/ConflictErrorPanel'
import { pageView } from 'tools/analytics'
import {
  translate,
  TranslationFunction,
  InjectedTranslateProps
} from 'react-i18next'
import { IImageUrl } from 'stores/Configuration'

export interface IStepContent {
  basket: IBasket
  catalogue?: ICatalogue
  navigation: INavigation
  t: TranslationFunction
}

const stepComponent = (navigation: INavigation): any => {
  try {
    return require(`./Scenarios/${navigation.id}`).default
  } catch (e) {
    return NotImplemented
  }
}

class Main extends React.Component<
  {
    appStore?: IAppStore
    basket?: IBasket
    catalogue?: ICatalogue
    navigation?: INavigation
  } & InjectedTranslateProps,
  { isBasketOpen: boolean }
> {
  state = {
    isBasketOpen: false
  }

  handleOpenBasket = (isBasketOpen: boolean) => {
    this.setState(() => ({ isBasketOpen }))
  }

  componentDidMount() {
    pageView('/index.html')
  }

  render() {
    const { basket, catalogue, navigation, t } = this.props
    const { isBasketOpen } = this.state
    const { currentStep, steps, isShowingBasket } = navigation!

    let name: string, imageUrl: IImageUrl, importantNotice: string

    if (basket.mainItem && basket.mainItem.item) {
      const i = basket.mainItem.item
      name = i.name
      imageUrl = i.imageUrl
      importantNotice = i.importantNotice
    }

    const stepsName = steps.map(step => step.name)

    const StepContent: React.SFC<IStepContent> = stepComponent(navigation!)

    const beforeStartBlockConfig = {
      show: currentStep === 0,
      variant: BeforeStartVariants.VoucherFlow
    }

    return (
      <div>
        <div className="d-none d-md-block">
          <HeroImage imageFn={imageUrl}>{name}</HeroImage>
        </div>
        <div className="container-fluid">
          <ProgressBar steps={stepsName} currentStep={currentStep} />
        </div>
        <ValidationErrorPanel />
        <ConflictErrorPanel />
        <div className="d-md-none">
          <HeroImage imageFn={imageUrl}>{name}</HeroImage>
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <StepCounter steps={stepsName} currentStep={currentStep} />
            </div>
          </div>
          <div className="row pb-5">
            <div className={`col-sm-12 col-md-${isShowingBasket ? '6' : '12'}`}>
              {beforeStartBlockConfig.show && (
                <BeforeStartInfo
                  variant={beforeStartBlockConfig.variant}
                  text={importantNotice}
                />
              )}
              <h2 className="mt-2 mb-6 ls-10">
                <span className="text-uppercase">
                  {steps[currentStep].title}
                </span>
                <small className="mt-2 ls-7">
                  {steps[currentStep].subtitle}
                </small>
              </h2>
              <div className={classnames({ 'd-none': isBasketOpen })}>
                {!!StepContent ? (
                  <StepContent
                    basket={basket!}
                    catalogue={catalogue!}
                    navigation={navigation!}
                    t={t}
                  />
                ) : (
                  <NotImplemented />
                )}
              </div>
            </div>
            {isShowingBasket && (
              <div className="col-sm-12 col-md-6">
                {basket.mainItem && (
                  <MyBasket
                    basket={basket}
                    catalogue={catalogue}
                    onBasketToggle={this.handleOpenBasket}
                  />
                )}
                {basket!.isPaymentDone && basket!.billingAddress && (
                  <div className="mt-5">
                    <OrderConfirmation email={basket!.billingAddress!.email!} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default translate()(
  inject(({ appStore }) => {
    return {
      appStore: appStore as IAppStore,
      basket: appStore.basket as IBasket,
      catalogue: appStore.catalogue as ICatalogue,
      navigation: appStore.navigation as INavigation
    }
  })(observer(Main))
)
