import * as React from 'react'
import { Field, FieldProps } from 'formik'
import Checkbox, { CheckboxProps } from 'atoms/Form/Checkbox'
import * as Yup from 'yup'

interface Props {
  name: string
  label?: string
}

const StepCheckbox: React.SFC<Props & CheckboxProps> = ({ name, ...props }) => (
  <Field
    name={name}
    validate={async (value: boolean) => {
      let schema = Yup.boolean().label(props.label || name)

      // If optional is not defined, then it should be required
      if (!props.optional) {
        schema = schema.oneOf([true], props.label)
      }

      try {
        await schema.validate(!!value)
      } catch (error) {
        return error.errors[0]
      }
    }}
    render={({ field, form }: FieldProps<{ [s: string]: string }>) => {
      return (
        <Checkbox
          {...props}
          {...field}
          checked={field.value}
          error={!!form.status ? form.errors[name] : ''}
        />
      )
    }}
  />
)

export default StepCheckbox
