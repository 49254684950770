import * as React from 'react'
import classnames from 'classnames'
import styled from 'tools/theme'

type IText = {
  className?: string
  error?: boolean
  bold?: boolean
}

const TextContainer = styled<IText, any>('div')`
  margin-top: 0;
  margin-bottom: 4.3rem;
  color: ${props =>
    props.error ? props.theme.danger : props.theme.darkCoolGrey};
`

const Text: React.SFC<IText> = ({ children, className, error, bold }) => {
  return (
    <TextContainer
      data-test="TextContainer"
      error={error}
      className={classnames(
        'form-group ls-7',
        { 'font-weight-bold': bold },
        className
      )}
    >
      {children}
    </TextContainer>
  )
}

export default Text
