import * as React from 'react'
import styled from 'tools/theme'
import classnames from 'classnames'
import { transparentize } from 'polished'

import AddonFlag from 'atoms/AddonFlag'
import { IMoney } from 'stores/Basket/Money'
import { currencyToString } from 'tools/currency'

interface Props {
  className?: string
  name: string
  price: IMoney
}

const Container = styled.div`
  background-color: ${props => props.theme.extraLightGrey};

  border-top: ${props =>
    `1px solid ${transparentize(0.15, props.theme.mediumGrey)}`};
`

const AddonLine: React.SFC<Props> = ({ className, name, price }) => {
  return (
    <Container
      data-test="AddonLineContainer"
      className={classnames('row py-4 align-items-center', className)}
    >
      <div className="col-sm-9 mb-4 mb-sm-0 col-12">
        <div className="row align-items-start">
          <AddonFlag />
          <h6 data-test="AddonLineName" className="text-uppercase ls-7 m-0">
            {name}
          </h6>
        </div>
      </div>
      <div className="col-sm-3 col-4">
        <h6
          data-test="AddonLinePrice"
          className="text-uppercase ls-7 m-0 text-right"
        >
          {currencyToString(price, { literal: true, isNegative: false })}
        </h6>
      </div>
    </Container>
  )
}

export default AddonLine
