import { get } from 'lodash'

const sessionLabel = 'mobxsnap'

// const isDev = process.env.NODE_ENV === 'development'
const isDev = false


export const getState = () => {
  if (!isDev) return null
  const state = JSON.parse(
    sessionStorage && sessionStorage.getItem(sessionLabel)
  )
  return state
}

export const setState = (state: object) => {
  if (!isDev) return null
  sessionStorage && sessionStorage.setItem(sessionLabel, JSON.stringify(state))
}

export const getDefault: any = (path: string, alternative: any) => {
  if (!isDev) return alternative
  const state = getState()
  const defaultValue = get(state, path)
  return defaultValue || alternative
}
