import { types } from 'mobx-state-tree'

const Step = types.model('Step', {
  name: types.string,
  gaFunnelName: types.string,
  gaVirtualPage: types.string,
  title: types.maybe(types.string),
  subtitle: types.maybe(types.string),
  showBasket: true,
  isPayment: types.maybe(types.boolean),
  isConfirm: types.maybe(types.boolean)
})

export type IStep = typeof Step.Type
export type IStepSnapshot = typeof Step.SnapshotType
export default Step
