import * as React from 'react'
import styled from 'tools/theme'
import classnames from 'classnames'
import { transparentize } from 'polished'

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

const LinkContainer = styled.a`
  text-decoration: underline;
  color: ${props => transparentize(0, props.theme.darkCoolGrey)}};

  &:hover,
  &:focus,
  &:active {
    color: ${props => transparentize(0.3, props.theme.darkCoolGrey)}};
  }
`

const Link: React.SFC<LinkProps> = ({
  children,
  href,
  className,
  ...props
}) => (
  <LinkContainer
    data-test="LinkContainer"
    {...props}
    href={href || '#'}
    className={classnames('text-underline ls-11', className)}
  >
    {children}
  </LinkContainer>
)

export default Link
