// tslint:disable
import * as React from 'react'
import styled from 'tools/theme'
import { inject, observer } from 'mobx-react'
import { IConfiguration } from 'stores/Configuration'

const StyledSVG = styled.svg`
  width: 16px;
  height: 16px;

  path {
    fill: ${props => props.theme.danger};
  }
`

const ErrorIcon: React.SFC<{ config?: IConfiguration; className?: string }> = ({
  config,
  className
}) =>
  config && config.theme === 'jaguar' ? (
    <StyledSVG
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
    >
      <path
        d="M8-0.01c-4.43,0-8,3.57-8,8s3.57,8,8,8s8-3.57,8-8S12.43-0.01,8-0.01z M11.43,12.47L7.99,9.02l-3.44,3.44l-1.06-1.06
	l3.44-3.44L3.51,4.54l1.06-1.06L7.99,6.9l3.44-3.44l1.06,1.06L9.05,7.96l3.44,3.44L11.43,12.47z"
      />
    </StyledSVG>
  ) : (
    <StyledSVG
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
    >
      <path
        d="M7.98-0.02c-4.43,0-8,3.57-8,8s3.57,8,8,8s8-3.57,8-8S12.41-0.02,7.98-0.02z M12.03,12.01c-0.15,0.15-0.34,0.22-0.53,0.22
   s-0.38-0.07-0.53-0.22l-3-3l-2.98,2.98c-0.15,0.15-0.34,0.22-0.53,0.22s-0.38-0.07-0.53-0.22c-0.29-0.29-0.29-0.77,0-1.06l2.98-2.98
   L3.96,5c-0.29-0.29-0.29-0.77,0-1.06s0.77-0.29,1.06,0l2.95,2.95l2.94-2.94c0.29-0.29,0.77-0.29,1.06,0s0.29,0.77,0,1.06L9.03,7.95
   l3,3C12.32,11.25,12.32,11.72,12.03,12.01z"
      />
    </StyledSVG>
  )

export default inject(({ appStore }) => {
  return {
    config: appStore && (appStore.configuration as IConfiguration)
  }
})(observer(ErrorIcon))
