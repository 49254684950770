import { types } from 'mobx-state-tree'
import Money from 'stores/Basket/Money'

const DeliveryOption = types.model('DeliveryOption', {
  id: types.identifier(types.string),
  name: types.string,
  price: Money,
  fulfilmentType: types.enumeration('fulfilmentType', [
    'DIGITAL_PDF',
    'PHYSICAL'
  ]),
  countries: types.optional(types.array(types.string), []),
  selected: false
})

export type IDeliveryOption = typeof DeliveryOption.Type
export type IDeliveryOptionSnapshot = typeof DeliveryOption.SnapshotType
export default DeliveryOption
