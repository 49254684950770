import * as React from 'react'

const PlusIcon: React.SFC<{}> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="11"
    height="11"
    viewBox="0 0 11 11"
  >
    <g fill="#0C121C">
      <path
        d="M5.958 5.042V.458a.458.458 0 1 0-.916 0v4.584H.458a.458.458 0 1 0 0 .916h4.584v4.584a.458.458 0 1 0 .916 0V5.958h4.584a.458.458 0 1 0 0-.916H5.958z" // tslint:disable-line
      />
    </g>
  </svg>
)

export default PlusIcon
