import * as React from 'react'
import { Fragment } from 'react'
import styled from 'tools/theme'
import WarningIcon from 'atoms/Icons/Warning'
import { translate, InjectedTranslateProps } from 'react-i18next'

export interface IRequiredActionsConfig {
  label: string
  url: string
}

interface Props {
  config: IRequiredActionsConfig[]
  status?: string
  showDocLinks?: boolean
}

/* Made into a function in case more check should be performed on config */
const shouldRender = (
  config: IRequiredActionsConfig[],
  status: string | undefined,
  showDocLinks: boolean
) => {
  let isValid = true

  if (showDocLinks) return !!config.length

  if (!status || status !== 'paid') {
    isValid = false
  }

  if (config.length === 0) {
    isValid = false
  }

  return isValid
}

const RequiredActions: React.SFC<Props & InjectedTranslateProps> = ({
  config,
  status,
  showDocLinks,
  t
}) => {
  if (!shouldRender(config, status, showDocLinks)) {
    return null
  }
  return (
    <Container data-test="BasketRequiredActionsContainer">
      <IconWithTitle>
        <WarningIcon />
        <h3 className="text-uppercase ls-8 ml-3">
          {t('basket.requiredActions.title', {
            defaultValue: 'Actions Required'
          })}
        </h3>
      </IconWithTitle>
      <p>
        {t('basket.requiredActions.ask', {
          defaultValue: 'Please complete the following documents:'
        })}
      </p>
      {config.map((action: IRequiredActionsConfig) => (
        <Fragment key={action.url}>
          <ActionNameAndLink>
            <ActionName data-test="BasketRequiredActionsActionName">
              {action.label}
            </ActionName>
            <a
              target="blank"
              href={action.url}
              data-test="BasketRequiredActionsActionUrl"
            >
              > Complete
            </a>
          </ActionNameAndLink>
          <RequiredNote>
            {t('basket.requiredActions.note', {
              defaultValue: 'Required for your experience'
            })}
          </RequiredNote>
        </Fragment>
      ))}
    </Container>
  )
}

const Container = styled<Props, any>('div')`
  border-top: 3px solid #ff7f00;
  padding: 10px;
  background: #f6f7f7;
  margin-right: -15px;
  margin-left: -15px;
`

const IconWithTitle = styled.div`
  display: flex;
`

const ActionName = styled.h4`
  font-size: 13pt;
`

const ActionNameAndLink = styled.div`
  display: flex;
  justify-content: space-between;
`

const RequiredNote = styled.p`
  color: grey;
  font-style: italic;
`
export default translate()(RequiredActions)
