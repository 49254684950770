import * as React from 'react'
import styled, { media, IThemeInterface } from 'tools/theme'
import classnames from 'classnames'
import { transparentize } from 'polished'
import { translate, InjectedTranslateProps } from 'react-i18next'
interface Props {
  theme?: IThemeInterface
  label?: string
  value?: string
  className?: string
  quantity?: string
  date?: string
  topBorder?: boolean
}

const Container: any = styled.div`
  background-color: ${props => props.theme.extraLightGrey};
  padding: 5px 0;
  border-top: ${(props: any) => (props.topBorder ? '1px solid white' : 'none')};

  .col-sm-6 {
    &:last-child {
      border-left: ${props =>
        `1px solid ${transparentize(0.15, props.theme.mediumGrey)}`};

      ${media.xs`
        border-left: 0;
        border-top: ${(props: Props) =>
          `1px solid ${transparentize(0.15, props.theme!.mediumGrey)}`};
      `};
    }
  }
`

const Detail = styled.h5`
  font-size: 1.4rem;
`
const Value = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.darkCoolGrey};
`

const DetailsLine: React.SFC<Props & InjectedTranslateProps> = ({
  topBorder = true,
  className,
  label,
  value,
  quantity,
  date,
  t
}) => {
  return (
    <Container
      data-test="DetailsLineContainer"
      topBorder={topBorder}
      className={classnames('row align-items-start', className)}
    >
      {quantity && (
        <div className="col-sm-6 py-3">
          <Detail className="text-uppercase m-0 ls-8">
            {t('basket.detailsLine.quantity', {
              defaultValue: 'Quantity:'
            })}
          </Detail>
          <Value className="ls-5 mt-3 mb-0" data-test="DetailsLineQuantity">
            {quantity}
          </Value>
        </div>
      )}
      {date && (
        <div className={`col-sm-${quantity ? '6' : '12'} py-3`}>
          <Detail className="text-uppercase m-0 ls-8">
            {t('basket.detailsLine.when', {
              defaultValue: 'When:'
            })}
          </Detail>
          <Value data-test="DetailsLineDate" className="ls-5 mt-3 mb-0">
            {date}
          </Value>
        </div>
      )}
      {(value || label) && (
        <div className="d-flex col-sm align-items-center justify-content-between w-100">
          <Detail data-test="DetailsLineDetail" className="text-uppercase">
            {label}
          </Detail>
          <Value data-test="DetailsLineValue">{value}</Value>
        </div>
      )}
    </Container>
  )
}

export default translate()(DetailsLine)
