import * as React from 'react'
import styled from 'tools/theme'
import classnames from 'classnames'
import Tick from 'atoms/Icons/Tick'
import Close from 'atoms/Icons/Close'

type AlertProps = {
  title: string
  type: 'success' | 'error'
  className?: string
}

const AlertContainer = styled.div`
  && {
    background-color: ${props => props.theme.extraLightGrey};
    padding: 3.6rem 2.5rem;
    margin: 0;
  }
`

const Content = styled.div`
  font-size: 1.6rem;
`

const Icon = styled<AlertProps, any>('div')`
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  background-color: ${props =>
    props.type === 'success' ? props.theme.primary : props.theme.danger};
  margin-right: 2rem;

  svg {
    path {
      fill: ${props => props.theme.white};
    }
  }
`

const Alert: React.SFC<AlertProps> = ({ children, type, title, className }) => (
  <AlertContainer
    className={classnames('media ls-8', className)}
    data-test="AlertContainer"
  >
    <Icon
      type={type}
      className="align-items-center align-self-center d-flex justify-content-center"
    >
      {type === 'success' ? <Tick /> : <Close />}
    </Icon>
    <div className="media-body">
      <h3 className="alert-heading" data-test="AlertContainerHeading">
        {title}
      </h3>
      <Content data-test="AlertContent">{children}</Content>
    </div>
  </AlertContainer>
)

export default Alert
