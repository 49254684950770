import * as React from 'react'
import styled, { media } from 'tools/theme'
interface Props {
  className?: string
  bottom?: React.ReactNode
  top?: React.ReactNode
  isShowingBasket?: boolean
}

const Container = styled<Props, any>('div')`
  margin-top: 3rem;

  .pos-container {
    padding-top: 3rem;
    ${media.md`
      padding-bottom: 3rem;
    `};
  }

  .pre-container {
    padding-bottom: 3rem;
  }
`

const FormPage: React.SFC<Props> = ({
  children,
  bottom,
  top,
  isShowingBasket
}) => (
  <Container data-test="PageContainer" isShowingBasket={isShowingBasket}>
    {top && (
      <div className="row pre-container">
        <div className={`col-sm-12 clearfix`}>{top}</div>
      </div>
    )}
    {children}
    {bottom && (
      <div className="row pos-container">
        <div className={`col-sm-12 clearfix`}>{bottom}</div>
      </div>
    )}
  </Container>
)

export default FormPage
