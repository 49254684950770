import { types, getRoot } from 'mobx-state-tree'
import Steps from 'stores/Navigation/Step'
import { getAsString } from 'tools/query'
import { scrollPage } from 'tools/scroll'
import { getState } from 'tools/session'
import { findIndex } from 'lodash'
import {
  pageView,
  gteSelectContent,
  gteBeginCheckout,
  gteCheckoutProgress,
  gteAddToCart,
  fbpxPageView,
  fbpxAddToCart,
  fbpxInitiateCheckout,
} from 'tools/analytics'
import { IAppStore } from 'stores/AppStore'

import {
  UnregisterCallback,
  createBrowserHistory,
  createMemoryHistory
} from 'history'

export const history =
  process.env.NODE_ENV === 'test'
    ? createMemoryHistory()
    : createBrowserHistory()

const sessionState = getState()
let sessionCurrent

if (sessionState) {
  sessionCurrent = sessionState.navigation.current
}

const Navigation = types
  .model('Navigation', {
    id: types.maybe(types.identifier(types.string)),
    steps: types.maybe(types.array(Steps)),
    current: types.optional(types.number, sessionCurrent || 0),
    lockedStep: types.maybe(types.number)
  })
  .views(self => ({
    get currentStep() {
      return self.lockedStep !== null ? self.lockedStep : self.current
    }
  }))
  .views(self => ({
    get isLastStep() {
      return self.steps.length - 1 === self.currentStep
    },
    get isPaymentStep() {
      const paymentStepIndex = findIndex(self.steps, o => o.isPayment)
      return paymentStepIndex === self.currentStep
    },
    get isConfirmStep() {
      return findIndex(self.steps, o => o.isConfirm) === self.currentStep
    },
    get isShowingBasket() {
      return self.steps[self.current].showBasket
    }
  }))
  .actions(self => ({
    setCurrentStep(currentStep: number) {
      self.current = currentStep
    }
  }))
  .actions(self => ({
    lockFlow(stepNumber: number) {
      self.lockedStep = stepNumber
    }
  }))
  .actions(self => {
    // Using memory history if test environment
    // This code will be deleted by minifier
    let unlisten: UnregisterCallback

    const confirmBeforeLeaving = (shouldConfirm: boolean) => {
      if (process.env.NODE_ENV !== 'development') {
        window.onbeforeunload = shouldConfirm ? () => shouldConfirm : null
      }
    }

    // referenced so actions can call other actions

    const actions = {
      afterCreate() {
        // Initialiaze the history with the first step
        history.replace(
          `#${history.location.pathname}/${self.id}/${self.current}${history.location.search}${history.location.hash}`,
          {
            currentStep: self.current
          }
        )

        fbpxPageView()

        // Listen for changes to the current history location.
        // That will effectivelly change the store step and redirect the user to the first step view

        unlisten = history.listen(location => {
          const currentStep: number = location.state.currentStep
          const currentStepObject = self.steps[currentStep]

          if (!currentStepObject) return

          self.setCurrentStep(currentStep)
          pageView(currentStepObject.gaVirtualPage)

          if (currentStepObject.gaVirtualPage === '/order-summary.html') {
            gteSelectContent()
          } else if (
            ['/booking-details.html', '/delivery-details.html'].includes(
              currentStepObject.gaVirtualPage
            )
          ) {
            gteAddToCart()
            fbpxAddToCart()
            gteBeginCheckout()
            fbpxInitiateCheckout()
          } else {
            gteCheckoutProgress()
          }

          scrollPage(0, 0)
          // if last step, don't confirm leaving page
          if (self.steps.length - 1 === self.current) {
            confirmBeforeLeaving(false)
            // Lock flow on last step (payment confirmation)
            self.lockFlow(self.current)
          }
        })

        confirmBeforeLeaving(true)
      },
      beforeDestroy() {
        // Destroy listener for step navigation
        unlisten()
        confirmBeforeLeaving(false)
      },
      nextStep() {
        const appStore: IAppStore = getRoot(self)
        if (appStore.validationErrors || appStore.conflictErrors) {
          return
        }
        const nextStep = self.current + 1
        history.push(`/${self.id}/${nextStep}${getAsString()}`, {
          currentStep: nextStep
        })
      },
      previousStep() {
        const previousStep = self.current - 1
        history.push(`/${self.id}/${previousStep}${getAsString()}`, {
          currentStep: previousStep
        })
      }
    }

    return actions
  })

export type ItemType = 'EVENT' | 'EVENT_GROUP' | 'PRODUCT' | 'ADVOCACY'

export type ICampaign = 'STANDARD' | 'ADVOCACY'

export const navigationByItemTypeOrCampaign = (
  campaign: ICampaign,
  itemType: ItemType
): INavigation => {
  if (!itemType) {
    throw new Error('Type for main item is not defined, aborting')
  }

  if (campaign.toUpperCase() === 'ADVOCACY') {
    return Navigation.create({
      id: 'Advocacy',
      steps: [
        {
          name: 'Experience Details',
          gaFunnelName: '#experience-details',
          gaVirtualPage: '/experience-details.html',
          title: 'Experience details',
          subtitle: 'Fields marked with * are mandatory'
        },
        {
          name: 'Customer details',
          gaFunnelName: '#customer-details',
          gaVirtualPage: 'order-summary.html',
          title: 'Customer details',
          subtitle: 'Fields marked with * are mandatory',
          isConfirm: true
        },
        {
          name: 'Order placed',
          gaFunnelName: '#order-placed',
          gaVirtualPage: '/order-placed.html',
          title: 'Thank you'
        }
      ]
    })
  }

  if (itemType === 'PRODUCT') {
    return Navigation.create({
      id: 'GiftVoucher',
      steps: [
        {
          name: 'Experience Details',
          gaFunnelName: '#experience-details',
          gaVirtualPage: '/experience-details.html',
          title: 'BUY As a gift voucher',
          subtitle: 'Fields marked with * are mandatory'
        },
        {
          name: 'ORDER SUMMARY',
          gaFunnelName: '#order-summary',
          gaVirtualPage: '/order-summary.html',
          title: 'PLEASE REVIEW YOUR SUMMARY',
          showBasket: false
        },
        {
          name: 'DELIVERY DETAILS',
          gaFunnelName: '#delivery-details',
          gaVirtualPage: '/delivery-details.html',
          title: 'voucher recipient details',
          subtitle: 'Fields marked with * are mandatory'
        },
        {
          name: 'PAYMENT DETAILS',
          gaFunnelName: '#payment-details',
          gaVirtualPage: '/payment-details.html',
          title: 'billing & payment',
          subtitle: 'Fields marked with * are mandatory',
          isPayment: true
        },
        {
          name: 'ORDER PLACED',
          gaFunnelName: '#order-placed',
          gaVirtualPage: '/order-placed.html',
          title: 'order review'
        }
      ]
    })
  }

  return Navigation.create({
    id: 'BuyExperience',
    steps: [
      {
        name: 'Experience Details',
        gaFunnelName: '#experience-details',
        gaVirtualPage: '/experience-details.html',
        title: 'BUY AN EXPERIENCE',
        subtitle: 'Fields marked with * are mandatory'
      },
      {
        name: 'ORDER SUMMARY',
        gaFunnelName: '#order-summary',
        gaVirtualPage: '/order-summary.html',
        title: 'PLEASE REVIEW YOUR SUMMARY',
        showBasket: false
      },
      {
        name: 'Booking DETAILS',
        gaFunnelName: '#booking-details',
        gaVirtualPage: '/booking-details.html',
        title: 'Your experience',
        subtitle: 'Fields marked with * are mandatory'
      },
      {
        name: 'PAYMENT DETAILS',
        gaFunnelName: '#payment-details',
        gaVirtualPage: '/payment-details.html',
        title: 'billing & payment',
        subtitle: 'Fields marked with * are mandatory',
        isPayment: true
      },
      {
        name: 'ORDER PLACED',
        gaFunnelName: '#order-placed',
        gaVirtualPage: '/order-placed.html',
        title: 'order review'
      }
    ]
  })
}

type INavigationType = typeof Navigation.Type
export interface INavigation extends INavigationType {}
export type INavigationSnapshot = typeof Navigation.SnapshotType
export default Navigation
