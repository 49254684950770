import { types, onSnapshot, flow, getSnapshot } from 'mobx-state-tree'
import Catalogue from 'stores/Catalogue'
import Basket from 'stores/Basket'
import Navigation, { navigationByItemTypeOrCampaign } from 'stores/Navigation'
import Configuration from 'stores/Configuration'
import { log } from 'tools/log'
import Availability from 'stores/Availability'
import { setState, getDefault } from 'tools/session'

const ValidationError = types.model('ValidationError', {
  id: types.string,
  errorType: types.string
})

const ServerError = types.model('ServerError', {
  errorType: types.string,
  description: types.string,
  errors: types.array(ValidationError),
  title: types.string
})

const ModalContent = types.model('ModalContent', {
  title: types.string,
  content: types.string
})

const AppStore = types
  .model('AppStore', {
    catalogue: types.optional(Catalogue, getDefault('catalogue', {})),
    basket: types.optional(Basket, getDefault('basket', {})),
    configuration: types.optional(
      Configuration,
      getDefault('configuration', {})
    ),
    navigation: types.maybe(Navigation),
    availability: types.optional(Availability, getDefault('availability', {})),
    isInitialising: true,
    initialisationError: types.maybe(types.string),
    requestError: types.maybe(types.boolean),
    serverError: types.maybe(ServerError),
    validationErrors: types.maybe(ServerError),
    conflictErrors: types.maybe(ServerError),
    timeoutError: types.maybe(types.boolean),
    hasPendingRequests: types.optional(types.boolean, false),
    modalContent: types.maybe(ModalContent)
  })
  .actions(self => ({
    logSnaphotInDevelopement: function logSnaphotInDevelopement() {
      if (process.env.NODE_ENV === 'development') {
        onSnapshot(self, snapshot => {
          // @ts-ignore
          if (snapshot.basket.id) {
            setState(snapshot)
          }
          log('[AppStore Snapshot]', snapshot)
        })
      }
    },
    loadConfiguration: flow(function* loadConfiguration() {
      try {
        // Load configuration
        yield self.configuration.load()
      } catch (error) {
        self.initialisationError =
          'Unable to load application configuration for parameters: ' +
          JSON.stringify(getSnapshot(self.configuration), null, 2)
        self.isInitialising = false
        log('[Init Error]', error)
        return
      }
    }),
    setNavigationType: (function setNavigationType() {
      try {
        // Set correct navigation depending on item type
        const campaign = self.configuration.campaign
        let itemType: any
        if (campaign === 'advocacy') {
          itemType = 'ADVOCACY'
        } else if (self.catalogue.item) {
          itemType = self.catalogue.item.itemType
        }
        self.navigation = navigationByItemTypeOrCampaign(campaign, itemType)
      } catch (error) {
        self.initialisationError = `Unable to retrieve navigation.`
        self.isInitialising = false
        log('[Init Error]', error)
      }
    }),
    loadDefaultAvailability: flow(function* loadDefaultAvailability() {
      try {
        if (self.catalogue.item.isEvent) {
          const { sku, defaultParameters } = self.catalogue.item
          yield self.availability.init({
            sku,
            parameters: defaultParameters
          })
        }
      } catch (error) {
        if (error.message === 'no avail') {
          self.initialisationError =
            'We could not find any available events for the next 18 months.'
        } else {
          self.initialisationError =
            `Unable to load availability for event: ` +
            JSON.stringify(getSnapshot(self.catalogue.item), null, 2)
        }
        self.isInitialising = false
        log('[Init Error]', error)
        return
      }
    }),
    createBasket: flow(function* createBasket() {
      try {
        // Add main item directly basket if loaded successfully
        yield self.basket.create(
          self.catalogue.item,
          self.configuration.language,
          self.configuration.currency
        )
      } catch (error) {
        self.initialisationError =
          `Unable to create basket for parameters: ` +
          JSON.stringify(getSnapshot(self.configuration), null, 2)
        self.isInitialising = false
        return
      }
    })
  }))
  .actions(self => ({
    loadCatalogueItem: flow(function* loadCatalogueItem() {
      try {
        // Load item defined at initialisation
        yield self.catalogue.loadItem(self.configuration.sku)
        yield self.loadDefaultAvailability()
      } catch (error) {
        self.initialisationError = `Unable load catalogue item for SKU: ${self.configuration.sku}`
        self.isInitialising = false
        log('[Init Error]', error)
      }
    })
  }))
  .actions(self => ({
    afterCreate: flow(function* load() {
      self.logSnaphotInDevelopement()

      self.loadConfiguration()
      // If main item is not loaded, load main item
      if (!self.catalogue.item) {
        if (self.configuration.campaign === 'standard') {
          yield self.loadCatalogueItem()
        }
      }

      self.setNavigationType()

      if (self.configuration.campaign === 'advocacy') {
        self.isInitialising = false
        return
      }

      self.createBasket()

      self.isInitialising = false
    }),
    setHasPendingRequests(flag: boolean) {
      self.hasPendingRequests = flag
    },
    setRequestError() {
      self.requestError = true
    },
    setValidationError(errors: any) {
      self.validationErrors = errors
    },
    setConflictError(errors: any) {
      self.conflictErrors = errors
    },
    setModalContent(content: { title: string; content: string }) {
      self.modalContent = content
    }
  }))

export type IAppStore = typeof AppStore.Type
export type IValidationError = typeof ValidationError.Type
export type IAppStoreSnapshot = typeof AppStore.SnapshotType
export default AppStore
