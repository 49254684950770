import AppStore, { IAppStoreSnapshot } from 'stores/AppStore'
import { getState } from 'tools/session'

export const createRootStore = (snapshot?: IAppStoreSnapshot) => {
  let store: any = {}
  let sessionSnap: any
  if (AppStore) {
    // const sessionSnap = null
    sessionSnap = getState()
    store = AppStore.create(sessionSnap || snapshot, {})
  }

  return store
}

export const appStore = createRootStore()
