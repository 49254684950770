import * as React from 'react'
import Section from 'atoms/Form/Section'
import StepCheckbox from 'molecules/Step/Checkbox'
import ContactInput from 'molecules/Step/ContactInput'
import { IStepContent } from 'organisms/Main'
import { observer } from 'mobx-react'

class PhysicalDelivery extends React.Component<IStepContent, {}> {
  render() {
    const { t, basket } = this.props

    return (
      <Section
        containsForm
        title={t('content.delivery.physical.title', {
          defaultValue: 'Gift pack delivery details'
        })}
      >
        <ContactInput
          fieldName="deliveryDetail"
          t={t}
          availableCountries={basket.deliveryCountries}
        />
        <StepCheckbox name="sameBillingDetails" optional bold>
          {t('content.delivery.sameBillingDetails', {
            defaultValue: 'Use as billing details'
          })}
        </StepCheckbox>
      </Section>
    )
  }
}

export default observer(PhysicalDelivery)
