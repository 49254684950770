const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )

export const transform = formikData => {
  const mobxData = formikData.reduce((memo, item) => {
    memo[snakeToCamel(item.id)] = item.value
    return memo
  }, {})
  return mobxData
}
