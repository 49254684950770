import { types, flow, applySnapshot, destroy, getRoot } from 'mobx-state-tree'
import Entry, { IEntry } from 'stores/Basket/Entry'
import { IItem } from 'stores/Catalogue/Item'
import { ICatalogue } from 'stores/Catalogue'
import DeliveryOption from 'stores/Basket/DeliveryOption'
import Media from 'stores/Basket/Media'
import request from 'tools/request'
import { reaction, IReactionDisposer } from 'mobx'
import Money from 'stores/Basket/Money'
import ContactDetails, {
  IContactDetails,
  toSnakeCase
} from 'stores/Basket/ContactDetails'
import { IAvailability } from 'stores/Availability'
import Order from 'stores/Basket/Order'
import { IImageUrl } from 'stores/Configuration'
import { find, get } from 'lodash'
import { IAppStore } from 'stores/AppStore'
import { ITimeRange } from 'stores/Availability/TimeRange'
import { IDateRange } from 'stores/Availability/DateRange'
import { format } from 'date-fns'
import { gtePromoClick, gteSetCheckoutOption } from 'tools/analytics'
import { transform } from 'tools/formikToBobx'

export type IFormField = {
  id: string
  elementType: 'CONTENT' | 'TEXT' | 'CHECKBOX' | 'SELECT' | 'RADIO' | 'TEXTAREA'
  required?: boolean
  content?: string
  label?: string
  tip?: string
  value?: any
  options?: Array<{ value: string; text: string }>
}

export type IFormFieldValue = {
  id: string
  value: string
}

const addQuantityToItemsInData = (data: any) => {
  const itemsWithQuantity = data.items.map((item: any) => {
    const quantityParam = item.parameters[0]
    const quantity = get(quantityParam, 'value')

    item.quantity = parseInt(quantity, 10)
    return item
  })
  return itemsWithQuantity
}

const Deposit = types.model('Deposit', {
  amount: Money,
  percent: types.number
})

const Basket = types
  .model('Basket', {
    id: types.maybe(types.string),
    heroMedia: types.optional(Media, {
      type: 'image',
      uri: 'FullDayDriveExp.jpg' // TODO Add default image
    }),
    items: types.optional(types.array(Entry), []),
    createdAt: types.maybe(types.Date),
    deliveryOptions: types.optional(types.array(DeliveryOption), []),
    itemTotal: types.maybe(Money),
    itemTotalPromoted: types.maybe(Money),
    netPrice: types.maybe(Money),
    vatAmount: types.maybe(Money),
    grossPrice: types.maybe(Money),
    deliveryDetail: types.maybe(ContactDetails),
    billingAddress: types.maybe(ContactDetails),
    contactNumber: types.maybe(types.string),
    discountsEnabled: false,
    discountTotal: types.maybe(Money),
    discountCodeUsed: types.maybe(types.string),
    tosUrl: types.maybe(types.string),
    privacyPolicyUrl: types.maybe(types.string),
    order: types.maybe(Order),
    deposit: types.maybe(Deposit)
  })
  .views(self => ({
    get selectedDelivery() {
      const selectedOption = find(
        self.deliveryOptions,
        option => option.selected
      )
      return selectedOption
    },
    get paymentURL() {
      return self.order && self.order.paymentUrl
    },
    get mainItem(): IEntry | undefined {
      return self.items.length ? self.items[0] : undefined
    }
  }))
  .views(self => ({
    get imageFn(): IImageUrl {
      return getRoot(self).configuration.imageUrl({
        category: 'product',
        placement: 'header',
        identifier: self.mainItem && self.mainItem.sku.sku
      })
    },
    get isDigitalDelivery() {
      return (
        self.selectedDelivery &&
        self.selectedDelivery.fulfilmentType === 'DIGITAL_PDF'
      )
    },
    get deliveryCountries() {
      // Make a list of supported delivery countries
      return Object.keys(
        self.deliveryOptions.reduce((acc, option) => {
          // TODO API issue Countries are being returned with 3 chars in basket, we support 2 only, so truncating
          option.countries.forEach(
            country => (acc[country[0] + country[1]] = true)
          )
          return acc
        }, {})
      )
    },
    get isAwaitingPayment() {
      const appStore: IAppStore = getRoot(self)
      const isPaymentStep = appStore.navigation.isPaymentStep
      return self.order && self.order.status === 'created' && isPaymentStep
    },
    get isPaymentDone() {
      return self.order && self.order.status === 'paid'
    },
    get hasSelectedAddons() {
      const check =
        self.mainItem && self.mainItem.addOns.find(addon => addon.selected)
      return !!check
    }
  }))
  .actions(self => {
    const actions = {
      create: flow(function* create(
        item: IItem,
        language: string,
        currency: string
      ) {
        let startDate: string
        let startTime: string
        let endTime: string

        const nextAvailable = (getRoot(self).availability as IAvailability)
          .nextAvailable

        // Adding dates if event
        // For that loadDefaultAvailable (that was done in store)
        if (item.isEvent && nextAvailable) {
          startDate = format(nextAvailable.date, 'YYYY-MM-DD')
          if (nextAvailable.eventTimes.length) {
            startTime = format(nextAvailable.eventTimes[0].startTime, 'HH:mm')
            endTime = format(nextAvailable.eventTimes[0].endTime, 'HH:mm')
          }
        }

        const query = {
          sku: item.sku.toUpperCase(),
          currency,
          language,
          ...Object.keys(item.defaultParameters).reduce((acc, paramKey) => {
            acc[`parameter[${paramKey}]`] = item.defaultParameters[paramKey]
            return acc
          }, {}),
          start_date: startDate,
          start_time: startTime,
          end_time: endTime
        }

        const code = getRoot(self).configuration.campaignCode

        if (code) {
          // @ts-ignore
          query.advocacyCode = code
        }

        // Initialise basket
        const { data } = yield request(
          'commerce/baskets/',
          {
            method: 'post',
            query
          },
          false
        )

        // Apply snapshot of updated basket info
        try {
          // item_details belong in the catalogue, move there and remove from here
          const itemDetails: IItem[] = data.itemDetails
          const catalogue: ICatalogue = getRoot(self).catalogue

          if (data.itemDetails) {
            itemDetails.forEach((itemDetail: IItem) => {
              catalogue.insertItem(itemDetail)
            })

            delete data.itemDetails
          }

          // sort delivery options
          if (data.deliveryOptions) {
            data.deliveryOptions.sort(
              (prev: any, next: any) => prev.price.amount - next.price.amount
            )
          }

          data.items = addQuantityToItemsInData(data)
          applySnapshot(self, data)
        } catch (e) {
          console.error(e)
          throw new Error('Could not apply snapshot to initialise basket ')
        }

        actions.setDateAndTimeOnMainItem(true, null, null)
      }),

      setDateAndTimeOnMainItem(
        setIsUpdating: boolean,
        dateSelection: IDateRange,
        timeSelection: ITimeRange
      ) {
        const mainItem = self.mainItem
        if (dateSelection && timeSelection) {
          mainItem.setSelectedDateRange(dateSelection)
          mainItem.setSelectedTimeRange(timeSelection)
        } else {
          const nextAvailable = (getRoot(self).availability as IAvailability)
            .nextAvailable
          if (mainItem && nextAvailable) {
            mainItem.setSelectedDateRange(nextAvailable, setIsUpdating)
            if (nextAvailable.eventTimes.length) {
              mainItem.setSelectedTimeRange(
                nextAvailable.eventTimes[0],
                setIsUpdating
              )
            }
          }
        }
      },

      setDeliveryDetail: flow(function* setDeliveryDetail(
        details: IContactDetails
      ) {
        // Set details locally
        self.deliveryDetail = details

        // Saving to basket details
        yield request(`commerce/baskets/${self.id}/delivery-detail`, {
          method: 'put',
          data: toSnakeCase(self.deliveryDetail),
          removeBlanks: true
        })
      }),

      setBillingAddress: flow(function* setBillingAddress(
        details: IContactDetails
      ) {
        // Set billing address locally
        self.billingAddress = {
          ...details,
          deliveryType: details.deliveryType || 'address'
        }

        // Saving to basket details
        yield request(`commerce/baskets/${self.id}/billing-address`, {
          method: 'put',
          data: toSnakeCase(self.billingAddress),
          removeBlanks: true
        })
      }),

      setDeliveryOption: flow(function* setDeliveryOption(
        selectedOptionId: string
      ) {
        // Set selected locally
        self.deliveryOptions.forEach(option => {
          option.selected = option.id === selectedOptionId
        })

        // Saving to basket details
        const { data } = yield request(
          `commerce/baskets/${self.id}/delivery-option`,
          {
            method: 'put',
            data: {
              delivery_option_id: selectedOptionId
            }
          }
        )

        // Updating details along with prices

        data.items = addQuantityToItemsInData(data)

        try {
          applySnapshot(self, data)
        } catch (e) {
          console.error('could not apply snapshot')
        }

        gteSetCheckoutOption()
      }),

      createOrder: flow(function* createOrder({
        contactConsentGiven,
        tosAccepted,
        checkPaymentManually
      }: {
        contactConsentGiven: boolean
        tosAccepted: boolean
        checkPaymentManually?: boolean
      }) {
        // Saving to basket details
        try {
          const { data } = yield request(`commerce/orders/`, {
            method: 'post',
            query: {
              basket_id: self.id!,
              kmi_consent_given: contactConsentGiven,
              tos_accepted: tosAccepted
            }
          })

          // Creating order from details
          self.order = data

          // Wait here until payment is confirmed
          if (!checkPaymentManually) {
            yield self.order!.checkPaymentDone()
          }
        } catch (error) {
          console.error(error)
        }
      }),

      resetOrder: () => {
        if (self.order) {
          destroy(self.order)
        }
      },

      applyDiscountCode: flow(function* applyDiscountCode(
        discountCode: string
      ) {
        // Saving to basket details
        try {
          const { data } = yield request(
            `commerce/baskets/${self.id}/discount-codes`,
            {
              method: 'put',
              data: {
                discount_code: discountCode
              }
            }
          )
          // Updating details along with prices
          const dateSelection = self.mainItem.selectedDateRange
          const timeSelection = self.mainItem.selectedTimeRange
          addQuantityToItemsInData(data)
          applySnapshot(self, data)
          actions.setDateAndTimeOnMainItem(false, dateSelection, timeSelection)
          self.discountCodeUsed = discountCode
          gtePromoClick(discountCode)
        } catch (e) {
          yield Promise.reject('Discount code request error')
        }
      }),

      loadForm: flow<IFormField[]>(function* loadForm() {
        const {
          data: { elements }
        } = yield request(`commerce/baskets/${self.id}/form`)

        return Object.keys(elements).map(fieldKey => {
          return elements[fieldKey]
        })
      }),

      saveForm: flow(function* saveForm(formData: IFormFieldValue[]) {
        yield request(`commerce/baskets/${self.id}/form`, {
          method: 'put',
          data: formData
        })

        const td = transform(formData)
        const contactDetails = {
          firstName: td.customerName,
          lastName: '',
          addressLine1: td.customerAddress,
          addressLine2: '',
          locality: '',
          postalTown: td.customerCity,
          adminDistrict: '',
          district: '',
          postalCode: td.customerPostalZip,
          country: td.customerCountry || '',
          email: td.customerEmail,
          contactNumber: td.customerPhone,
          deliveryType: 'email'
        }

        applySnapshot(self, {
          ...self,
          billingAddress: contactDetails
        })
      })
    }

    return actions
  })
  .actions(self => {
    let disposer: IReactionDisposer

    return {
      afterCreate() {
        // Reaction: Auto selection in diferent cases
        disposer = reaction(
          () => self.deliveryOptions.length,
          length => {
            // if there is only one delivery option, that must be selected
            // if no option is selected by default, select first
            if (length === 1 || (length > 1 && !self.selectedDelivery)) {
              self.setDeliveryOption(self.deliveryOptions[0].id)
            }
          },
          { fireImmediately: true }
        )
      },
      beforeDestroy() {
        disposer()
      }
    }
  })

type IBasketType = typeof Basket.Type
export interface IBasket extends IBasketType {}
export type IBasketSnapshot = typeof Basket.SnapshotType
export default Basket
