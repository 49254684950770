import React from 'react'
import InjectedCardPaymentForm from '../../atoms/StripePaymentMethods/CardPaymentForm'
import InjectedIdealPaymentForm from '../../atoms/StripePaymentMethods/IdealPaymentForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { IStepContent } from '../../organisms/Main'
import styled from 'styled-components'
import { appStore } from 'stores'
import { IItem } from 'stores/Catalogue/Item'

const PaymentNav = styled.ul`
  margin-bottom: 15px;

  .nav-item:focus {
    outline: none;
  }
`

class StripePaymentForm extends React.Component<
  { stepContent: IStepContent; paymentMethods: string[] },
  { stripe?: Stripe; isStripeLoaded: boolean; paymentMethod: string }
> {
  constructor(props) {
    super(props)
    this.state = {
      stripe: null,
      isStripeLoaded: false,
      paymentMethod: 'card'
    }
  }

  componentDidMount() {
    const sku = appStore.basket.mainItem.sku.sku
    const publishableKey = appStore.catalogue.items.find(
      (i: IItem) => i.sku === sku
    ).organisation.stripePublishableKey
    loadStripe(publishableKey).then(Stripe => {
      this.setState({ isStripeLoaded: true, stripe: Stripe })
    })
  }

  setPaymentMethod(method: string) {
    this.setState({ paymentMethod: method })
  }

  getNavClassName(method: string) {
    return (
      'nav-item nav-link' +
      (this.state.paymentMethod === method ? ' active' : '')
    )
  }

  render() {
    const clientSecret = this.props.stepContent.basket.order.clientSecret
    return (
      <div className="PaymentContainer">
        {this.state.isStripeLoaded && clientSecret !== '' && (
          <div>
            <PaymentNav className="nav nav-tabs">
              {this.props.paymentMethods.includes('card') && (
                <li>
                  <button
                    className={this.getNavClassName('card')}
                    type="button"
                    onClick={() => this.setPaymentMethod('card')}
                  >
                    Card
                  </button>
                </li>
              )}
              {this.props.paymentMethods.includes('ideal') && (
                <li>
                  <button
                    className={this.getNavClassName('ideal')}
                    type="button"
                    onClick={() => this.setPaymentMethod('ideal')}
                  >
                    iDEAL
                  </button>
                </li>
              )}
            </PaymentNav>
            <Elements stripe={this.state.stripe}>
              {this.props.paymentMethods.includes('card') &&
                this.state.paymentMethod === 'card' && (
                  <InjectedCardPaymentForm
                    basket={this.props.stepContent.basket}
                    navigation={this.props.stepContent.navigation}
                    t={this.props.stepContent.t}
                    clientSecret={clientSecret}
                  />
                )}
              {this.props.paymentMethods.includes('ideal') &&
                this.state.paymentMethod === 'ideal' && (
                  <InjectedIdealPaymentForm
                    basket={this.props.stepContent.basket}
                    navigation={this.props.stepContent.navigation}
                    t={this.props.stepContent.t}
                    clientSecret={clientSecret}
                  />
                )}
            </Elements>
          </div>
        )}
      </div>
    )
  }
}

export default StripePaymentForm
