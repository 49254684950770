import * as React from 'react'
import { observer } from 'mobx-react'
import { IBasket } from 'stores/Basket'
import { ICatalogue } from 'stores/Catalogue'
import { scrollPage } from 'tools/scroll'
import { withBreakpoints, WithBreakpointsProps } from 'tools/breakpoints'
import Basket from 'organisms/MyBasket/Basket'

type State = {
  open: boolean
}

class MyBasket extends React.Component<
  {
    basket?: IBasket
    catalogue?: ICatalogue
    onBasketToggle?: (isOpen: boolean) => void
  } & WithBreakpointsProps,
  State
> {
  state = {
    open: false
  }

  componentDidMount() {
    if (this.props.onBasketToggle) {
      this.props.onBasketToggle(this.state.open)
    }
  }

  handleHeaderToggle = () => {
    this.setState(({ open }) => {
      // Callback called to report opening
      if (this.props.onBasketToggle) {
        this.props.onBasketToggle(!open)
      }
      // If openning (was not open before), scroll to the top
      if (!open) {
        scrollPage(0, 0)
      }
      // Change state
      return { open: !open }
    })
  }

  render() {
    const { basket, catalogue, isMobile } = this.props
    const { open } = this.state

    // Basket is always open in Desktop
    const isBasketOpen = !isMobile || open

    return (
      <Basket
        basket={basket}
        catalogue={catalogue}
        open={isBasketOpen}
        onOpenToggle={this.handleHeaderToggle}
      />
    )
  }
}

export default observer(withBreakpoints(MyBasket))
