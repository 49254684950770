import * as React from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import { IAppStore } from 'stores/AppStore'
import { scrollPage } from 'tools/scroll'
import Alert from 'atoms/Alert'

interface IProps {
  appStore?: IAppStore
}
const ValidationErrorPanel = ({ appStore }: IProps) => {
  const errors = appStore.validationErrors

  if (!errors) return null

  scrollPage(0, 0)

  if (errors.title === 'Items expired') {
    return (
      <Wrapper>
        <Inner>
          <Alert
            title="We apologise but there has been an error with processing this booking."
            type="error"
          >
            <p>
              Please try again in 20 minutes or alternatively contact the centre
              directly by phone or email.
            </p>
          </Alert>
        </Inner>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Inner>
        <Alert title="Something is not quite right." type="error">
          <p>{errors.description}</p>
        </Alert>
      </Inner>
    </Wrapper>
  )
}

export default inject(({ appStore }) => ({
  appStore
}))(observer(ValidationErrorPanel))

const Wrapper = styled.div({
  width: '100vw',
  display: 'flex'
})

const Inner = styled.div({
  width: '90vw',
  margin: '20px auto'
})
