import * as React from 'react'

const CalendarIcon: React.SFC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={className}
  >
    <defs>
      <path
        id="a1234"
        d="M22 8H2V6a2 2 0 0 1 2-2h1v1a1 1 0 0 0 2 0V4h4v1a1 1 0 0 0 2 0V4h4v1a1 1 0 0 0 2 0V4h1a2 2 0 0 1 2 2v2zm0 12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10h20v10zM20 2a4 4 0 0 1 4 4v14a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h1V1a1 1 0 0 1 2 0v1h4V1a1 1 0 0 1 2 0v1h4V1a1 1 0 0 1 2 0v1h1zm-9 10a1.001 1.001 0 0 0 0 2v3a1 1 0 0 1-1 1 1 1 0 0 0 0 2h3.999a1 1 0 0 0 0-2A1 1 0 0 1 13 17v-4c0-.551-.448-1-1-1h-1z" // tslint:disable-line
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b1234" fill="#fff">
        <use xlinkHref="#a1234" />
      </mask>
      <use fill="#700046" xlinkHref="#a" />
      <g fill="#0C121C" mask="url(#b1234)">
        <path d="M-24-24h72v72h-72z" />
      </g>
    </g>
  </svg>
)

export default CalendarIcon
