import * as React from 'react'
import { FormikErrors, FormikActions, FormikProps } from 'formik'
import { isEmpty } from 'lodash'

export interface Props<Values> {
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>
  onMount?: (formikProps: FormikProps<Values>) => void
  onUnmount?: (formikProps: FormikProps<Values>) => void
  onSubmit?: (values: Values, formikActions: FormikActions<Values>) => void
  top?: React.ReactNode
}

class Page<FormFields> extends React.Component<
  Props<FormFields> & { formikProps?: FormikProps<FormFields> },
  {}
> {
  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount(this.props.formikProps!)
    }
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount(this.props.formikProps!)
    }
  }

  componentDidUpdate() {
    const formikProps = this.props.formikProps
    if (formikProps.isSubmitting && !isEmpty(formikProps.errors)) {
      const firstFieldWithError = document.querySelectorAll(
        '[error]'
      )[0] as HTMLElement
      if (firstFieldWithError) {
        firstFieldWithError.focus()
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default Page
