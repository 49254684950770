import * as React from 'react'
import Header from 'atoms/Basket/Header'
import PriceLine from 'atoms/Basket/PriceLine'
import Information from 'atoms/Basket/Information'
import DetailsLine from 'atoms/Basket/DetailsLine'
import AddonLine from 'atoms/Basket/AddonLine'
import RequiredActions from 'atoms/Basket/RequiredActions'
import styled from 'tools/theme'
import { IBasket } from 'stores/Basket'
import { ICatalogue } from 'stores/Catalogue'
import { currencyToString } from 'tools/currency'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { observer, inject } from 'mobx-react'

const Basket: React.SFC<
  {
    basket?: IBasket
    catalogue?: ICatalogue
    appStore?: any
    open?: boolean
    onOpenToggle?: (event: React.MouseEvent<HTMLButtonElement>) => void
  } & InjectedTranslateProps
> = ({ basket, catalogue, t, open, onOpenToggle, appStore }) => {
  const {
    imageFn,
    grossPrice,
    contactNumber,
    mainItem,

    discountTotal,
    deposit,
    order
  } = basket!

  const { configuration } = appStore

  const { item, lineTotalPrice, selectedAddOns, dateSelection } = mainItem!
  const { name, isEvent, isTimeslot, parameterSelection } = item
  const parameters = catalogue.item.parameters[0]
  const quantityParam: any = parameters.id === 'quantity' ? parameters : null
  const selectedDeliveryOption = basket!.selectedDelivery

  const date =
    dateSelection ||
    (isTimeslot
      ? t('basket.detailsLine.selectDateAndTime', {
          defaultValue: 'Please select a date and a time'
        })
      : t('basket.detailsLine.selectDate', {
          defaultValue: 'Please select a date'
        }))

  let documentUrl: string
  let guestFormUrl: string
  let status: string

  const isAdvocacy = configuration.campaign === 'advocacy'

  if (order) {
    documentUrl = order.documentUrl
    guestFormUrl = order.guestFormUrl
    status = order.status
  }

  const requiredActionsConfig = []

  if (documentUrl) {
    requiredActionsConfig.push({
      label: 'Experience Waiver',
      url: documentUrl
    })
  }

  if (guestFormUrl) {
    requiredActionsConfig.push({
      label: 'Guest Details',
      url: guestFormUrl
    })
  }

  return (
    <Container>
      <Header
        imageFn={imageFn}
        price={currencyToString(grossPrice)}
        open={open}
        onToggle={onOpenToggle}
      />
      {/* <Collapse isOpened={!!open}> */}
      {open && (
        <div>
          <PriceLine
            noPadding
            header
            title={isAdvocacy ? 'Complimentary owner experience' : name}
            price={currencyToString(lineTotalPrice)}
          />
          {isAdvocacy && (
            <DetailsLine label="Vehicle" value={name} topBorder={false} />
          )}
          {isEvent && (
            <DetailsLine
              topBorder={false}
              quantity={parameterSelection}
              date={date}
            />
          )}
          {selectedAddOns.map((addon: any) => (
            <AddonLine
              key={addon.sku}
              name={addon.name}
              price={addon.lineTotalPrice}
            />
          ))}

          {quantityParam && (
            <DetailsLine
              topBorder={false}
              quantity={mainItem.quantity + ' ' + quantityParam.label}
              value={null}
            />
          )}
          {!!selectedDeliveryOption && !!selectedDeliveryOption.price.amount && (
            <DetailsLine
              label={t('basket.deliveryLine', {
                defaultValue: 'Presentation pack'
              })}
              value={currencyToString(selectedDeliveryOption.price)}
            />
          )}
          {discountTotal && discountTotal.amount > 0 && (
            <PriceLine
              noPadding
              price={currencyToString(discountTotal, {
                isNegative: true,
                literal: false
              })}
              title={t('basket.discount.title', { defaultValue: 'Discount' })}
            />
          )}
          <PriceLine
            className="thin-top-primary-border"
            noPadding
            total
            price={currencyToString(grossPrice)}
            title={t('basket.total.price', {
              defaultValue: 'Order Total'
            })}
            subtitle={t('basket.total.delivery', {
              defaultValue: '(Including delivery)'
            })}
          />
          {deposit && (
            <PriceLine
              noPadding
              total
              price={currencyToString(deposit.amount)}
              title={t('basket.deposit.title', {
                defaultValue: 'Payment due today'
              })}
              subtitle={`(${deposit.percent}% Deposit)`}
            />
          )}
          <RequiredActions
            config={requiredActionsConfig}
            status={status}
            showDocLinks={!!isAdvocacy}
          />
          <Information phone={contactNumber} className="mt-3" />
        </div>
      )}
      {/* </Collapse> */}
    </Container>
  )
}

export default translate()(
  inject(({ appStore }) => ({ appStore }))(observer(Basket))
)

const Container = styled.div`
  background-color: ${props => props.theme.white};

  .thin-top-primary-border {
    border-top: ${props => `2px solid ${props.theme.primary}`};
  }
`
