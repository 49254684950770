import * as React from 'react'
import styled from 'tools/theme'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { observer } from 'mobx-react'
import Section from 'atoms/Form/Section'
// import Link from 'atoms/Link'

interface Props {
  email: string
  // printURL?: string
}

const Container = styled(Section)`
  p {
    font-size: 12px;
  }
`

const OrderConfirmation: React.SFC<Props & InjectedTranslateProps> = ({
  email,
  // printURL,
  t
}) => (
  <Container
    data-test="OrderConfirmationContainer"
    title={t('voucher.orderConfirmation.title', {
      defaultValue: 'Order Confirmation'
    })}
  >
    <p className="ls-6">
      {t('voucher.orderConfirmation.text', {
        defaultValue: 'Your order confirmation will be emailed to {{email}}.',
        email
      })}
    </p>
    {/* <Link className="text-uppercase" href={printURL}>
      Print Order Confirmation
    </Link> */}
  </Container>
)

export default translate()(observer(OrderConfirmation))
