import * as React from 'react'
import { translate } from 'react-i18next'
import HeroImage from 'molecules/HeroImage'
import { inject } from 'mobx-react'
import * as Sentry from '@sentry/browser'
import styled from 'tools/theme'

const ContactsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  list-style: none;
  padding: 0;
  color: #666;
  font-size: 14px;
  font-family: "LandRoverWeb", Arial, Helvetica, Sans-Serif !important;
`

const BoldContact = styled.span`
  font-weight: bold;
  color: black;
`

const ProductUnaviable = ({
  t,
  initialisationError,
  appStore
}: {
  t: any
  initialisationError?: any
  appStore?: any
}) => {
  console.error(initialisationError)
  const isAdvocacy = appStore.configuration.campaign === 'advocacy'
  Sentry.captureMessage('Product unaviable')
  Sentry.captureException(initialisationError)
  return (
    <div>
      <HeroImage imageFn={() => '/cdn/centre/hero/lre_sol_large_1280.jpg'}>
        {t('errors.unavailable.hero')}
      </HeroImage>
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <h4 className="text-uppercase ls-7 mt-5 mb-3">
              {t('errors.unavailable.title')}
            </h4>
            <p>{t('errors.unavailable.text-one')}</p>
            <p>{t('errors.unavailable.text-two')}</p>
            <br/>
            {isAdvocacy && (
              <><h3>{t('errors.unavailable.advocacy')} </h3><br /><ContactsList>
                <li>
                  <BoldContact>Scotland  </BoldContact>
                  <span>Dunkeld  </span>
                  <BoldContact>01350 727720 </BoldContact>
                </li>
                <li>
                  <BoldContact>North Yorkshire </BoldContact>
                  <span>Skipton </span>
                  <BoldContact>01756 611060 </BoldContact>
                </li>
                <li>
                  <BoldContact>Liverpool </BoldContact>
                  <span>Halewood </span>
                  <BoldContact>01514 484023 </BoldContact>
                </li>
                <li>
                  <BoldContact>Cheshire </BoldContact>
                  <span>Tarporley </span>
                  <BoldContact>01829 262864 </BoldContact>
                </li>
                <li>
                  <BoldContact>Midlands </BoldContact>
                  <span>Solihull </span>
                  <BoldContact>0121 7004619 </BoldContact>
                </li>
                <li>
                  <BoldContact>Herefordshire </BoldContact>
                  <span>Eastnor </span>
                  <BoldContact>01531 638777 </BoldContact>
                </li>
                <li>
                  <BoldContact>East of England </BoldContact>
                  <span>Rockingham </span>
                  <BoldContact>01536 772238 </BoldContact>
                </li>
                <li>
                  <BoldContact>London </BoldContact>
                  <span>Luton </span>
                  <BoldContact>01989 770932 </BoldContact>
                </li>
                <li>
                  <BoldContact>West Country </BoldContact>
                  <span>Honiton </span>
                  <BoldContact>01404 549333 </BoldContact>
                </li>
              </ContactsList></>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default translate()(
  inject(({ appStore }) => ({
    appStore
  }))(ProductUnaviable)
)
