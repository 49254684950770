import React from 'react'
import Alert from 'atoms/Alert'
import styled from '@emotion/styled'

const PaymentNotConfirmedMessage = ({
  phone,
  orderStatus
}: {
  phone: string
  orderStatus: string
}) => {
  if (orderStatus !== 'error') return null

  return (
    <Alert type={'error'} title="Payment Problem">
      <Subtitle>We apologise for any inconvenience</Subtitle>
      <Warning>
        We were not able to confirm your payment was successfully completed.
      </Warning>
      <p>Please check your bank or card provider payments</p>
      <NextSteps>
        <Title>Next Steps</Title>
        <p>Please check your email for your order confirmation.</p>
        <p>
          If you have not recieved an order confirmation email, please contact
          the Centre to check your order status.
        </p>
        {phone && <p>Call {phone}</p>}
      </NextSteps>
    </Alert>
  )
}

const Subtitle = styled.p({
  fontSize: 20,
  fontWeight: 800,
  marginTop: 20,
  marginBottom: 20
})

const Warning = styled.p({
  fontSize: 18,
  fontWeight: 800,
  color: 'red'
})

const NextSteps = styled.div({
  padding: 20,
  background: '#dfe1e1'
})

const Title = styled.p({
  fontSize: 18
})

export default PaymentNotConfirmedMessage
