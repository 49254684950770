import * as React from 'react'
import styled, { media } from 'tools/theme'
import { IImageUrl } from 'stores/Configuration'
interface Props {
  imageFn: IImageUrl
  children: string
}

// as described at https://github.com/styled-components/styled-components/issues/1428
const Jumbotron = styled<Props, any>('header')`
  && {
    background-image: url(${props => props.imageFn('large', 1280)});
    background-image: image-set(
      url(${props => props.imageFn('large', 1280)}) 1x,
      url(${props => props.imageFn('large', 2560)}) 2x
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 60% 50%;
    padding: 0;

    .container {
      text-align: center;
      line-height: 0;
    }

    .title-container {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 1.4rem 2.1rem;
      ${media.xs`padding: 0.5rem 1.4rem;`};
    }

    ${media.sm`
      background-image: url(${(props: Props) => props.imageFn('medium', 768)});
      background-image: image-set(
        url(${(props: Props) => props.imageFn('medium', 768)}) 1x,
        url(${(props: Props) => props.imageFn('medium', 1536)}) 2x
      );
    `};

    ${media.xs`
      height: 120px;
      background-image: url(${(props: Props) => props.imageFn('small', 576)});
      background-image: image-set(
        url(${(props: Props) => props.imageFn('small', 576)}) 1x,
        url(${(props: Props) => props.imageFn('small', 1152)}) 2x
      );
    `};

    height: 210px;
  }
`

const Title = styled.h1`
  overflow: hidden;
  max-height: 106px;
`

const HeroImage: React.SFC<Props> = ({ children, imageFn }) => {
  if (process.env.SKIP_IMAGES || !imageFn) {
    return null
  }
  return (
    <Jumbotron
      className="jumbotron jumbotron-fluid m-0 d-flex align-items-center"
      data-test="HeroImage"
      imageFn={imageFn}
    >
      <div className="container">
        <div className="title-container d-inline-block">
          <Title
            className="text-uppercase m-0 d-inline-block ls-5 text-center"
            title={children}
          >
            {children}
          </Title>
        </div>
      </div>
    </Jumbotron>
  )
}

export default HeroImage
