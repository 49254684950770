import * as React from 'react'
import { observer } from 'mobx-react'
import Wizard from 'organisms/Wizard'
import WizardPage from 'organisms/Wizard/Page'
import { IStepContent } from 'organisms/Main'
import OrderSummary from 'molecules/OrderSummary'
import ExperienceDetails from '../shared/ExperienceDetails'
import CheckfrontFormRenderer from '../shared/CheckfrontFormRenderer'
import { IContactDetails } from 'stores/Basket/ContactDetails'
import PaymentDetails from '../Screens/PaymentDetails'
import Alert from 'atoms/Alert'
import BoxContainer from 'atoms/Form/BoxContainer'
import Section from 'atoms/Form/Section'
import PrePopulatedInfo from 'atoms/PrePopulatedInfo'
import PaymentNotConfirmedMessage from 'molecules/PaymentNotConfirmedMessage'
import { startsWith, debounce } from 'lodash'
export interface IBuyExperienceForm {
  parameters: { [id: string]: number } // The parameters (quantity)
  date?: Date
  startTime?: Date
  endTime?: Date
  hasTimeSelection?: boolean
  billingAddress: IContactDetails
  informed: boolean
  agreed: boolean
}

const BuyExperience: React.SFC<IStepContent> = ({
  t,
  basket,
  navigation,
  catalogue
}) => {
  const { mainItem, billingAddress, createOrder, order } = basket
  const { nextStep } = navigation
  const entry = mainItem!

  const debouncedOnChange = debounce(values => {
    entry.item.setParametersValue(values.parameters)
    // Updating booking on every change of relevant properties
    entry.updateBooking({
      date: values.date,
      startTime: values.startTime,
      endTime: values.endTime,
      parameters: values.parameters
    })
  }, 500)

  const paymentBookingSuccessText = t('Payment.booking.success.text', {
    defaultValue: 'Your booking is being processed. Your booking reference is '
  })

  if (!entry) return null

  return (
    <Wizard
      initialValues={{
        parameters: entry.sku.defaultParameters,
        billingAddress: {
          email: '',
          contactNumber: '',
          firstName: '',
          lastName: '',
          addressLine1: '',
          addressLine2: '',
          locality: '',
          country: '',
          postalTown: '',
          adminDistrict: '',
          district: '',
          postalCode: ''
        },
        informed: false,
        agreed: false,
        date: entry.selectedDateRange
          ? entry.selectedDateRange.date
          : undefined,
        startTime: entry.selectedTimeRange
          ? entry.selectedTimeRange.startTime
          : undefined,
        endTime: entry.selectedTimeRange
          ? entry.selectedTimeRange.endTime
          : undefined
      }}
      onChange={values => {
        if (navigation.currentStep === 0) {
          // On change set the selected parameters
          debouncedOnChange(values)
        }
      }}
    >
      <WizardPage<IBuyExperienceForm>
        validate={values => {
          let errors: object = {}

          if (!values.date) {
            errors = {
              date: values.hasTimeSelection
                ? t('booking.date.noDateTime', {
                    defaultValue: 'Please select date and time for the booking.'
                  })
                : t('booking.date.noDate', {
                    defaultValue: 'Please select date for the booking.'
                  })
            }
          } else if (values.hasTimeSelection && !values.startTime) {
            errors = {
              time: t('booking.date.noTime', {
                defaultValue: 'Please select time for the booking.'
              })
            }
          }

          return errors
        }}
        onMount={({ values }) => {
          // Set selected parameters on basket
          entry.item.setParametersValue(values.parameters)
        }}
      >
        <ExperienceDetails basket={basket} navigation={navigation} t={t} />
      </WizardPage>
      <WizardPage>
        <OrderSummary basket={basket} catalogue={catalogue} />
      </WizardPage>
      <WizardPage<IBuyExperienceForm>
        onSubmit={async values => {
          try {
            await basket.saveForm(
              Object.keys(values)
                .filter(formKey => startsWith(formKey, 'basketForm_'))
                .map(formKey => {
                  const regex = formKey.match(/basketForm_(.*)/)
                  return {
                    id: regex ? regex[1] : formKey,
                    value: values[formKey].toString()
                  }
                })
            )
          } catch (e) {
            // I don't want to create order if there has been errors in the save form call.
            return
          }

          // Create order so that we will skip billing details on next screen
          await createOrder({
            contactConsentGiven: true,
            tosAccepted: true,
            checkPaymentManually: true
          })
          nextStep()
        }}
      >
        <CheckfrontFormRenderer basket={basket} navigation={navigation} t={t} />
      </WizardPage>
      <WizardPage<IBuyExperienceForm>
        top={
          order && (
            <PaymentNotConfirmedMessage
              orderStatus={order.status}
              phone={basket.contactNumber}
            ></PaymentNotConfirmedMessage>
          )
        }
        onMount={async () => {
          if (!order) return
          if (
            catalogue.paymentProvider === 'datacash' ||
            !catalogue.paymentProvider
          ) {
            await order.checkPaymentDone()
            if (order && order.status === 'paid') {
              nextStep()
            }
          }
        }}
        onUnmount={() => {
          order.cancelPaymentDonePoll()
        }}
      >
        {order && order.status !== 'error' && (
          <PaymentDetails
            basket={basket}
            catalogue={catalogue}
            navigation={navigation}
            t={t}
          />
        )}
      </WizardPage>
      <WizardPage<IBuyExperienceForm>
        top={
          <Alert
            type={order && order.status === 'paid' ? 'success' : null}
            title={
              order && order.status === 'paid'
                ? t('Payment.booking.success.title', {
                    defaultValue: 'THANK YOU FOR YOUR BOOKING.'
                  })
                : t('Payment.booking.timeout.title', {
                    defaultValue: 'OOOOPS, there was a problem.'
                  })
            }
            className="mb-4"
          >
            {order && order.status === 'paid'
              ? paymentBookingSuccessText + (order && order.orderReference)
              : t('Payment.booking.timeout.text')}
          </Alert>
        }
      >
        {billingAddress && (
          <BoxContainer>
            <Section>
              <PrePopulatedInfo
                title={t('prePolutedInfo.payee', {
                  defaultValue: 'Payee'
                })}
                info={billingAddress}
              />
            </Section>
          </BoxContainer>
        )}
      </WizardPage>
    </Wizard>
  )
}

export default observer(BuyExperience)
