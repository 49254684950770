import * as React from 'react'

export const Disableable = ({ children, disabled }) => {
  return (
    <div
      data-test="Disableable"
      style={{
        opacity: disabled ? 0.3 : 1,
        pointerEvents: disabled ? 'none' : 'all',
        cursor: disabled ? 'progress' : 'auto'
      }}
    >
      {children}
    </div>
  )
}
