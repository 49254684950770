import * as React from 'react'
import { ThemeProvider } from 'tools/theme'
import { I18nextProvider } from 'react-i18next'
import DebugTab from 'organisms/DebugTab'
import Main from 'organisms/Main'
import Initialisation from 'tools/Initialisation'
import ErrorBoundry from 'tools/ErrorBoundry'
import AdvocacyValidator from 'components/AdvocacyValidator'
import { inject, observer } from 'mobx-react'
import { IAppStore } from 'stores/AppStore'
import ModalMessage from 'organisms/ModalMessage'
import ProductUnaviable from 'organisms/ProductUnaviable'

const themeVars = require('jlr-bootstrap-theme/build/index.js').default // tslint:disable-line

interface Props {
  appStore?: IAppStore
}

const Application: React.SFC<Props> = props => {
  const { appStore } = props

  const mainItem = appStore.basket.mainItem

  return (
    <Initialisation>
      {({ theme, i18n, initialisationError }) => (
        <ThemeProvider theme={themeVars[theme]['theme-colors']}>
          <I18nextProvider i18n={i18n}>
            <div>
              <ModalMessage></ModalMessage>
              <DebugTab />
              {initialisationError ? (
                <ProductUnaviable
                  initialisationError={initialisationError}
                ></ProductUnaviable>
              ) : (
                <ErrorBoundry>
                  {!mainItem &&
                    appStore.configuration.campaign === 'advocacy' && (
                      <AdvocacyValidator></AdvocacyValidator>
                    )}
                  {mainItem && <Main />}
                </ErrorBoundry>
              )}
            </div>
          </I18nextProvider>
        </ThemeProvider>
      )}
    </Initialisation>
  )
}

export default inject(({ appStore }) => {
  return {
    appStore: appStore as IAppStore
  }
})(observer(Application))
