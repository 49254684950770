import * as React from 'react'
import classnames from 'classnames'
import styled, { IThemeInterface } from 'tools/theme'
interface Props {
  theme?: IThemeInterface
  label: string
  children: React.ReactElement<any>
  optional?: boolean
  className?: string
}

const OptionContainer = styled.div`
  font-size: 1.2rem;
  user-select: none;

  &:not(:last-child) {
    border-bottom: none !important;
  }

  .label {
    color: ${props => props.theme.darkCoolGrey};
    max-width: 65%;
  }
`

const Option: React.SFC<Props> = ({ children, label, optional, className }) => {
  return (
    <OptionContainer
      data-test="OptionContainer"
      className={classnames(
        className,
        'py-3 clearfix border-bottom border-top'
      )}
      aria-label={label}
    >
      <div className="d-inline-block align-middle label ls-5">
        {!optional && '*'}
        {label}
      </div>
      <div className="float-right">{children}</div>
    </OptionContainer>
  )
}

export default Option
