import * as React from 'react'
import styled, { media } from 'tools/theme'
import classnames from 'classnames'

interface Props {
  className?: string
  isHidden?: boolean
}

const Container = styled.div`
  border: ${(props: any) => `1px solid ${props.theme.mediumGrey}`};

  ${media.md`
    border: 0;
  `};

  & + & {
    margin-top: 4rem;
  }
`

const BoxContainer: React.SFC<Props> = ({ children, className, isHidden }) => (
  <Container
    data-test="BoxContainer"
    className={classnames('p-md-4', className, {
      'd-none': isHidden
    })}
  >
    {children}
  </Container>
)

export default BoxContainer
