import * as React from 'react'
import classnames from 'classnames'
import styled from 'tools/theme'
import { transparentize } from 'polished'
import ErrorDescription from 'atoms/Form/ErrorDescription'
import { isString } from 'lodash'

export interface IInput {
  name: string
  label: string
  labelAsPlaceholder?: boolean
  noMargin?: boolean
  error?: string
  optional?: string | boolean
  className?: string
  children?: any
  addon?: React.ReactNode
  helper?: React.ReactNode
}

const InputContainer = styled<IInput, any>('div')`
  position: relative;

  && {
    margin-top: 0;
    margin-bottom: ${props => (props.noMargin ? '0' : '4.3rem')};
  }

  input {
    background-color: ${props => props.theme.white};

    &.is-invalid {
      color: ${props => props.theme.danger};
    }
  }

  label {
    color: ${props =>
      props.error
        ? props.theme.danger
        : transparentize(0.3, props.theme.darkCoolGrey)};
    position: absolute;
    top: 0;
    font-weight: normal;
    line-height: 2;
    letter-spacing: 1.1px;
    padding: 9px 0 0 17px;
    margin: 0;
    transition: all 200ms;
    pointer-events: none;
  }

  .form-input:focus ~ label,
  .form-input.label-on-top ~ label {
    font-weight: 900;
    letter-spacing: 0.2px;
    transform: translate3d(0, -100%, 0);
    padding: 0;
    color: ${props => transparentize(0, props.theme.darkCoolGrey)};
    display: ${props => (props.labelAsPlaceholder ? 'none' : 'block')};
  }
`

const Input: React.SFC<IInput> = ({
  name,
  label,
  error,
  optional,
  className,
  addon,
  noMargin,
  helper,
  children,
  labelAsPlaceholder,
  ...otherProps
}) => {
  if (isString(optional)) {
    optional = JSON.parse(optional)
  }
  return (
    <InputContainer
      data-test="InputContainer"
      labelAsPlaceholder={labelAsPlaceholder}
      noMargin={noMargin}
      error={error}
      className={classnames('form-group', className, {
        'input-group': !!addon
      })}
    >
      {children &&
        React.cloneElement(children, {
          name,
          error,
          className: classnames(
            'form-input form-control',
            children.props.className
          ),
          ...otherProps
        })}
      {addon && <div className="input-group-append">{addon}</div>}
      <span className="w-100" />
      {helper && <div className="form-text mt-2">{helper}</div>}
      <label htmlFor={name}>
        {optional ? label : label ? `${label}*` : ''}
      </label>
      <ErrorDescription error={error} />
    </InputContainer>
  )
}

export default Input
