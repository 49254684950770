import 'react-app-polyfill/ie11'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import 'iframe-resizer' // tslint:disable-line
import { appStore, createRootStore } from 'stores'
import { IAppStore } from 'stores/AppStore'
import { Provider } from 'mobx-react'
import { getState } from 'tools/session'

// To enable msw add a query string with msw=true to your url.
if (
  process.env.NODE_ENV === 'development' &&
  window.parent.location.search.includes('msw')
) {
  const { worker } = require('./mocks/browser')
  worker.start()
}
let sessionStore = getState()
let store: any

if (sessionStore) {
  store = createRootStore(sessionStore)
} else {
  store = createRootStore(appStore)
}

const renderApp = (
  Application: React.StatelessComponent | React.ComponentClass,
  store: IAppStore
) => {
  ReactDOM.render(
    <Provider appStore={store}>
      <Application />
    </Provider>,
    document.getElementById('root') as HTMLElement
  )
}

// Initial rendering
try {
  renderApp(App, store)
} catch (error) {
  console.log('initail rendering error ', error)
}

// registerServiceWorker()

declare const module: { hot: any }

if (module.hot) {
  sessionStore = getState()
  module.hot.accept(['./stores'], () => {
    // Store definition changed, recreate a new one from old state
    try {
      renderApp(App, store)
    } catch (e) {
      console.log('hot accept stores error ', e)
    }
  })

  module.hot.accept(['./App'], () => {
    // Component definition changed, re-render app
    try {
      renderApp(App, store)
    } catch (e) {
      console.log('hot accept app error ', e)
    }
  })
}
