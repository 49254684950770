import * as React from 'react'
import styled from 'tools/theme'

export interface ISection {
  title?: string
  subtitle?: string
  containsForm?: boolean
  dataTest?: string
  borderTop?: boolean
}

const SectionContainer = styled<ISection, any>('div')`
  &:first-child {
    padding-top: 0 !important;
  }

  &:last-child {
    padding-bottom: 0 !important;
  }

  border-top: ${props =>
    props.borderTop ? `1px solid ${props.theme.mediumGrey}` : '0'};

  h3 {
    padding-bottom: ${props => (props.containsForm ? '4.3rem' : '1.5rem')};
  }
`

const Section: React.SFC<ISection & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  title,
  subtitle,
  dataTest,
  containsForm,
  ...props
}) => (
  <SectionContainer
    data-test={dataTest}
    containsForm={containsForm}
    className="py-2"
    {...props}
  >
    <h3 className="text-uppercase m-0 ls-8 sh-1" data-test="SectionTitle">
      {title}
      {subtitle && <small className="pt-2">{subtitle}</small>}
    </h3>
    {children}
  </SectionContainer>
)

export default Section
