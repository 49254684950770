import * as React from 'react'
import styled from 'tools/theme'
import { IAddOn } from 'stores/Basket/AddOn'
import { translate, InjectedTranslateProps } from 'react-i18next'
import AddonFlag from 'atoms/AddonFlag'
import { currencyToString } from 'tools/currency'
import Radio from 'atoms/Form/Radio'
import { observer } from 'mobx-react'
import { debounce } from 'lodash'

type CardProps = {
  addOn: IAddOn
  className?: string
}

const Container = styled.div`
  && {
    .card {
      border-color: ${props => props.theme.mediumGrey};
      width: 239px;
      /* max-height: 315px; */
      background-color: ${props => props.theme.extraLightGrey};

      .title {
        font-size: 1.6rem;
      }

      .description {
        color: ${props => props.theme.darkCoolGrey};
      }

      .price {
        font-size: 2.2rem;
        margin-bottom: 1rem;
        letter-spacing: -0.1px;
        text-align: right;
      }

      img {
        width: 245px;
        height: 142px;
      }

      .radio {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: ${props => `2px solid ${props.theme.mediumGrey}`};
      }
    }
  }
`

let alreadyDebounced: boolean
const createDebouncedSaveSelected = (addon: any) => {
  const debouncedSave = debounce(addon.saveSelected, 600)
  if (alreadyDebounced) {
    return alreadyDebounced
  }
  alreadyDebounced = debouncedSave
  return debouncedSave
}

const AddOnCard: React.SFC<CardProps & InjectedTranslateProps> = ({
  t,
  addOn,
  addOn: {
    imageUrl,
    name,
    description,
    discountedPrice,
    sku,
    selected,
    bundled,
    setSelected
  },
  className
}) => {
  const debouncedSaveSelected = createDebouncedSaveSelected(addOn)
  return (
    <Container className={className} data-test="AddonCard">
      <AddonFlag />
      <div className="card">
        <img
          className="img-fluid"
          srcSet={`${imageUrl('small', 245)} 1x, ${imageUrl('large', 490)} 2x`}
          alt={name}
        />
        <div className="card-body">
          <h5
            className="card-title title text-uppercase ls-8"
            data-test="CardTitle"
          >
            {name}
          </h5>
          <p className="card-text ls-2 description" data-test="Description">
            {description}
          </p>
          <h3 className="card-text price" data-test="AddonPrice">
            {currencyToString(discountedPrice)}
          </h3>
          {!bundled && (
            <Radio
              className="text-right d-block radio"
              id={sku}
              name={sku}
              aria-labelledby={name}
              checked={selected}
              data-test="AddonAddToBasket"
              onChange={() => {}} // tslint:disable-line
              onClick={() => {
                setSelected(!selected)
                debouncedSaveSelected()
              }}
            >
              {t('addOnCard.addToBasket', { defaultValue: 'Add to basket' })}
            </Radio>
          )}
        </div>
      </div>
    </Container>
  )
}

export default translate()(observer(AddOnCard))
