import * as React from 'react'
import Form from 'atoms/Form/Page'
import Section from 'atoms/Form/Section'

const NotImplemented: React.SFC<{}> = () => (
  <Form>
    <Section title="Step Functionality to be implemented" />
  </Form>
)

export default NotImplemented
