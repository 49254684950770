import { types, flow } from 'mobx-state-tree'
import Item, { IItem } from 'stores/Catalogue/Item'
import request from 'tools/request'
import { find } from 'lodash'

const Catalogue = types
  .model('Catalogue', {
    items: types.optional(types.array(Item), [])
  })
  .views(self => ({
    get item() {
      return self.items && self.items.length ? self.items[0] : null
    },
    get paymentProvider() {
      return this.item.paymentProvider
    },
    get paymentMethods() {
      return this.item.paymentMethods
    }
  }))
  .actions(self => {
    const actions: any = {
      loadItem: flow(function* loadItem(sku: string) {
        const { data } = yield request(`discovery/items/${sku}`)
        // Grab from active variant the SKU
        const mainSKU =
          data.sku ||
          (data.variants &&
            data.variants.length &&
            find(data.variants, (v: { active?: boolean }) => v.active) &&
            find(data.variants, (v: { active?: boolean }) => v.active).sku) ||
          sku
        actions.insertItem({ ...data, sku: mainSKU })
        return self.items[0]
      }),

      insertItem(item: any) {
        self.items.push(item)
      },

      getItemBySku(sku: string) {
        if (!self.items || !self.items.length) {
          return null
        }
        if (!sku) {
          return self.items[0]
        }

        return find(self.items, (item: IItem) => item.sku === sku)
      }
    }
    return actions
  })

export type ICatalogue = typeof Catalogue.Type
export type ICatalogueSnapshot = typeof Catalogue.SnapshotType
export default Catalogue
