import React from 'react'
import Alert from 'atoms/Alert'
import { translate } from 'react-i18next'

const OrderSuccessOrFailureMessage = ({ order, navigationId, t }) => {
  const isSuccess = order.status === 'paid'
  const paymentBookingSuccessText = t('Payment.booking.success.text', {
    defaultValue: 'Your booking is being processed. Your booking reference is '
  })

  let title: string
  let body: string

  if (!isSuccess) {
    title = t('Payment.booking.timeout.title', {
      defaultValue: 'OOOOPS, there was a problem.'
    })
    body = t('Payment.booking.timeout.text')
  } else {
    if (navigationId === 'BuyExperience') {
      title = t('Payment.booking.success.title', {
        defaultValue: 'THANK YOU FOR YOUR BOOKING.'
      })
      body = paymentBookingSuccessText + (order && order.orderReference)
    } else if (navigationId === 'GiftVoucher') {
      title = t('Payment.voucher.success.title', {
        defaultValue: 'THANK YOU FOR YOUR PURCHASE.'
      })
      body =
        t('Payment.voucher.success.text', {
          defaultValue:
            'Your voucher is being processed. Your order reference is '
        }) + order.orderReference
    }
  }

  return (
    <Alert type={isSuccess ? 'success' : null} title={title} className="mb-4">
      {body}
    </Alert>
  )
}

export default translate()(OrderSuccessOrFailureMessage)
