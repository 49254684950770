import * as React from 'react'
import Header from 'atoms/Basket/Header'
import PriceLine from 'atoms/Basket/PriceLine'
import DetailsLine from 'atoms/Basket/DetailsLine'
import Information from 'atoms/Basket/Information'

export const BasketUI = ({ centre,centrePhone }) => {
  return (
    <div>
      <Header
        imageFn={() => {
          return ''
        }}
        price={'free'}
        open={false}
        onToggle={() => {}}
      />
      <div>
        <PriceLine
          noPadding
          header
          title={'complimentary owner experience'}
          price={'£0.00'}
        />
        <DetailsLine topBorder={false} label={'Centre'} value={centre} />
        <DetailsLine topBorder={false} quantity={'1 Driver'} />
        <PriceLine
          className="thin-top-primary-border"
          noPadding
          total
          price={'£0.00'}
          title={'Order total'}
        />
        <Information phone={centrePhone} className="mt-3" />
      </div>
    </div>
  )
}
