import * as React from 'react'

const LockIcon: React.SFC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    className={className}
  >
    <defs>
      <path
        id="a"
        d="M10.484 15.476A1.474 1.474 0 0 1 11.957 14a1.478 1.478 0 0 1 1.456 1.449c.002.417-.174.793-.455 1.06v1.882a1 1 0 1 1-2 0v-1.864a1.44 1.44 0 0 1-.474-1.052zm8.486 5.459c0 .554-.451 1.005-1.006 1.005H6.012A1.013 1.013 0 0 1 5 20.928v-8.97H18.97v8.977zM7.068 6.918A4.924 4.924 0 0 1 11.987 2a4.923 4.923 0 0 1 4.918 4.918v3.04H7.068v-3.04zm11.837 3.04v-3.04A6.926 6.926 0 0 0 11.987 0c-3.815 0-6.92 3.104-6.92 6.918v3.04H3.002v10.97a3.014 3.014 0 0 0 3.01 3.012h11.953a3.009 3.009 0 0 0 3.006-3.005V9.958h-2.065z" // tslint:disable-line
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" xlinkHref="#a" />
      <g mask="url(#b)">
        <path d="M-24-24h72v72h-72z" />
        <path d="M-24-24h72v72h-72z" />
      </g>
    </g>
  </svg>
)

export default LockIcon
