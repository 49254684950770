import * as React from 'react'
import { hasMobileSize } from 'tools/breakpoints'

declare global {
  interface Window {
    parentIFrame: any
  }
}

export type IMeasure = {
  iframeHeight: number
  iframeWidth: number
  clientHeight: number
  clientWidth: number
  offsetLeft: number
  offsetTop: number
  scrollLeft: number
  scrollTop: number
}

type ICallback = (measure: IMeasure) => void

// Created subscription service for the pageInfo callback because it only supports the subscription
//  once and we want to use it in multiple components
let infoCallbacks: ICallback[] = []
let lastMeasure: IMeasure
let finalIframeSizeReached = false

export const addInfoCallback = (callback: ICallback) => {
  infoCallbacks.push(callback)
  // trigger callback with last measure when initialised
  if (lastMeasure) {
    callback(lastMeasure)
  }
}
export const removeInfoCallback = (callback: ICallback) => {
  infoCallbacks = infoCallbacks.filter(
    (infoCallback: ICallback) => callback !== infoCallback
  )
}

export const triggerInfoCallback = (measure: any = lastMeasure) => {
  // Re run the callback by scrolling down and up again to same position
  if ('parentIFrame' in window) {
    window.parentIFrame.scrollTo(measure.scrollLeft, measure.scrollTop + 1)
    window.parentIFrame.scrollTo(measure.scrollLeft, measure.scrollTop)
  }
}

const listenToScrollUpdates = (): boolean => {
  if ('parentIFrame' in window) {
    window.parentIFrame.getPageInfo((measure: any) => {
      if (!lastMeasure) {
        // First time running, make sure we refresh after a couple of ms until we have all element rendered
        // For some reason the measurement is not right if user scrolled down the iframe
        window.parentIFrame.scrollTo(measure.scrollLeft, measure.scrollTop + 1)
        window.parentIFrame.scrollTo(measure.scrollLeft, measure.scrollTop)
      }

      lastMeasure = measure
      if (measure.iframeHeight === 500 && !finalIframeSizeReached) {
        return listenToScrollUpdates()
      }
      finalIframeSizeReached = true
      infoCallbacks.forEach((callback: ICallback) => callback(measure))
    })
    return true
  }
  return false
}

const waitUntilInitialised = () => {
  if (!listenToScrollUpdates()) {
    setTimeout(waitUntilInitialised, 500)
  }
}

// Initialise listener
waitUntilInitialised()

export interface IWithStickyBehaviourProps {
  onStickyRefresh?: () => void
}

interface IWithStickyBehaviourOptions {
  zIndex?: number
  onCalculatePosition: (
    measure: IMeasure,
    containerRect: DOMRect | ClientRect,
    componentRect: DOMRect | ClientRect,
    props?: any,
    componentName?: string
  ) => { isSticky: boolean; newTop: number }
}

export const maintainTopPosition = (options?: {
  startAt?: number
  alwaysFullWidth?: boolean
}) => ({ scrollTop, offsetTop, clientWidth }: IMeasure, container: DOMRect) => {
  const opt = { startAt: 0, ...(options || {}) }

  return {
    // Calculate is should be sticky or not
    isSticky: scrollTop - offsetTop - container.top + opt.startAt > 0,
    newTop: scrollTop - offsetTop + opt.startAt,
    height: container.height, // Maintain the container height
    width: opt.alwaysFullWidth
      ? clientWidth - 30 /* row margin */
      : container.width,
    left: undefined
  }
}

export const maintainMobileBasketOpenPosition = (
  measure: IMeasure,
  container: DOMRect,
  component: DOMRect
) => {
  return {
    isSticky: true, // Always sticky on open
    newTop: 60, // Step only
    height: component.height - container.top, // add the needed space to render the basket correctly
    width: measure.clientWidth - 30,
    left: '15px'
  }
}

export const maintainBottomPosition = (
  measure: IMeasure,
  _container: DOMRect,
  { height }: DOMRect
) => {
  const { clientHeight, scrollTop, offsetTop } = measure

  // TODO
  // 4. How to work on phone landscape mode?
  // 5. Finally support stick to the top when open

  return {
    isSticky: true, // Always sticky on mobile
    newTop: clientHeight - height + scrollTop - offsetTop,
    height: 0, // The container should disappear
    width: measure.clientWidth - 30,
    left: '15px'
  }
}

export const maintainBasketStickyPosition = (
  measure: IMeasure,
  containerRect: DOMRect,
  componentRect: DOMRect,
  { open }: { open: boolean }
) => {
  // Check if mobile or desktop
  if (hasMobileSize(measure.clientWidth)) {
    return open
      ? maintainMobileBasketOpenPosition(measure, containerRect, componentRect)
      : maintainBottomPosition(measure, containerRect, componentRect)
  } else {
    // Desktop
    return maintainTopPosition({ startAt: 60 })(measure, containerRect)
  }
}

// The HOC that provides sticky behaviour to components
const withStickyBehaviour = (options: IWithStickyBehaviourOptions) => <
  P extends object
>(
  Component: React.ComponentType<P & IWithStickyBehaviourProps>
) => {
  const opt = { zIndex: 1000, ...(options || {}) }

  return class WithStickyBehaviour extends React.Component<
    P,
    { newTop: number; isSticky: boolean; width: number; left?: number }
  > {
    state = {
      isSticky: false,
      width: 0,
      height: 0,
      newTop: 0,
      left: undefined
    }
    private componentDOM: HTMLDivElement | null
    private containerDOM: HTMLDivElement | null

    componentDidMount() {
      addInfoCallback(this.subscribeToScrollInfo)
    }

    componentWillUnmount() {
      removeInfoCallback(this.subscribeToScrollInfo)
    }

    handleStickyRefresh = (): void => {
      triggerInfoCallback()
    }

    subscribeToScrollInfo = (measure: IMeasure): void => {
      const containerRect = this.containerDOM!.getBoundingClientRect()
      const componentRect = this.componentDOM!.getBoundingClientRect()

      // Execute customised calculations
      const calculations = options.onCalculatePosition(
        measure,
        containerRect,
        componentRect,
        this.props,
        Component.displayName
      )

      // if content goes beyond view, "freeze" it in the same place which is
      //  the max bottom of view (top as iframeHeight - height)
      if (calculations.newTop + componentRect.height > measure.iframeHeight) {
        // Beyond view, freeze on bottom
        calculations.newTop = measure.iframeHeight - componentRect.height
      }

      requestAnimationFrame(() => {
        this.setState(() => ({
          ...calculations
        }))
      })
    }

    render() {
      const { isSticky, width, height, newTop, left } = this.state

      return (
        <div
          style={isSticky ? { height, width } : {}} // Maintain same height and width to avoid page going down on scroll
          ref={ref => (this.containerDOM = ref)}
        >
          <div
            style={
              isSticky // Make it fixed if needs to be sticky
                ? {
                    position: 'fixed',
                    top: newTop,
                    transform: 'translateZ(0)',
                    transition: 'all 0.1s ease 0s',
                    zIndex: opt.zIndex,
                    width,
                    left
                  }
                : {}
            }
            ref={ref => (this.componentDOM = ref)}
          >
            <Component
              {...this.props}
              onStickyRefresh={this.handleStickyRefresh}
            />
          </div>
        </div>
      )
    }
  }
}

export default withStickyBehaviour
