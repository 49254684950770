import * as React from 'react'

const CloseIcon: React.SFC<{}> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="18"
    height="18"
    viewBox="0 0 12 12"
  >
    <defs>
      <path
        id="a"
        d="M6.5 5.5v-7a.5.5 0 1 0-1 0v7h-7a.5.5 0 1 0 0 1h7v7a.5.5 0 1 0 1 0v-7h7a.5.5 0 1 0 0-1h-7z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use transform="rotate(45 6 6)" fill="#0C121C" xlinkHref="#a" />
    </g>
  </svg>
)

export default CloseIcon
