import { types } from 'mobx-state-tree'

const Media = types.model('Media', {
  type: types.string,
  uri: types.string
})

export type IMedia = typeof Media.Type
export type IMediaSnapshot = typeof Media.SnapshotType
export default Media
