import * as React from 'react'
import DayPicker, { NavbarElementProps } from 'react-day-picker'
import 'react-day-picker/lib/style.css' // tslint:disable-line
import { transparentize } from 'polished'
import Arrow from 'atoms/Icons/Arrow'
import styled from 'tools/theme'
import { IAvailability } from 'stores/Availability'
import { IDateRange } from 'stores/Availability/DateRange'

interface ICalendarPicker {
  availability: IAvailability
  selectedDateRange?: IDateRange
  enableDeselect?: boolean
  onMonthChange?: (date: Date) => void
  onDateClick?: (date: IDateRange | undefined) => void
}

const Container = styled.div`
  &&&&&&& {
    background-color: ${props => props.theme.extraLightGrey};
    text-align: center;

    .DayPicker {
      background-color: ${props => props.theme.white};
      margin: 35px 0;
    }

.DayPicker-Month {
display: block;
}

    .DayPicker-wrapper,
    .DayPicker {
      width: 100%;
      outline: none;
      padding: 0;
    }

  .DayPicker-Body {
  display: flex;
  flex-direction: column;
  flex-grow:1;
  width: 100%;
  }

  .DayPicker-Weekdays {
  display: flex;
  width: 100%;
  }

  .DayPicker-Week {
  display: flex;
  flex-grow: 1;
  }

    .DayPicker-Month,
    .DayPicker-Weekdays,
    .DayPicker-WeekdaysRow {
      width: 100%;
      margin: 0;
  }
  .DayPicker-WeekdaysRow {
  display: flex;
  }

    .DayPicker-Weekday {
      color: ${props => props.theme.darkCoolGrey};
      font-size: 16px;
  flex-grow: 1;
      position: relative;
      display: block;
      padding: 0 0 0.1rem 0;

      &:after {
        content: '';
        display: block;
        margin-top: 100%;
        border-bottom: ${props => `2px solid ${props.theme.mediumGrey}`};
      }

      abbr {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${props => props.theme.darkCoolGrey};
        display: block;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }

    .DayPicker-Month {
      margin-top: 0;
    }

    .DayPicker-Day {
      padding: 0;
display: flex;
flex-grow: 1;
margin: 1px 3px;
      position: relative;
      background-color: transparent;
      cursor: auto;

      &:after {
        content: '';
        display: block;
        margin-top: 100%;
      }

      .unavailable-line {
        position: absolute;
        content: ' ';
        height: 50%;
        bottom: 0;
        right: 50%;
        width: 2px;
        background-color: ${props => props.theme.darkCoolGrey};
        transform: translate(50%, -50%) rotate(45deg);
      }

      .DayPicker-Day-Text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${props => props.theme.darkCoolGrey};
        display: block;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }

      &.DayPicker-Day--disabled {
        .DayPicker-Day-Text {
          color: ${props => transparentize(0.6, props.theme.dark)};
        }
      }

      &.DayPicker-Day--today {
        background-color: ${props => props.theme.mediumGrey};

        .DayPicker-Day-Text {
          font-weight: normal;
        }
      }

      &.DayPicker-Day--available {
        cursor: pointer;
        color: ${props => props.theme.darkCoolGrey};
        border-radius: 0;
        background-color: transparent;
        padding: 1px;
margin: 1px 0;
        border-width: 2px 0;
        border-style: solid;
        border-color: ${props => props.theme.primary};

        /* &:hover {
          .DayPicker-Day-Text {
            color: ${props => props.theme.white};
          }

          background-color: ${props => props.theme.primary};
        } */
      }

      &.DayPicker-Day--available-start {
        /* Add a border to the left of the block */
        border-left-width: 2px;
margin-left: 1px;
      }

      &.DayPicker-Day--available-end {
        /* Add a border to the right of the block */
        border-right-width: 2px;
margin-right: 1px;
      }

      &.DayPicker-Day--unavailable {
        outline: none !important;
margin: 1px;
      }

      &.DayPicker-Day--selected {
        cursor: pointer;
margin: 1px;
        border-radius: 0;
        background-color: ${props => props.theme.primary};

        &:not(.DayPicker-Day--available-start):not(.DayPicker-Day--available-end) {
          margin: 1px 0
        }

        &.DayPicker-Day--available-end:not(.DayPicker-Day--available-start) {
          margin: 1px 1px 1px 0;
        }

        &.DayPicker-Day--available-start:not(.DayPicker-Day--available-end) {
          margin: 1px 0 1px 1px;
        }

        .DayPicker-Day-Text {
          color: ${props => props.theme.white};
        }

      }

      &.DayPicker-Day--hovered {
        background-color: ${props => transparentize(0.7, props.theme.primary)};
      }
    }
  }
`

const NavBarContainer = styled.div`
  height: 50px;
  background-color: ${props => props.theme.mediumGrey};
  line-height: 50px;
  font-size: 14px;
  color: ${props => props.theme.dark};
  font-weight: 900;
  position: relative;
  padding: 0 44px;
  position: relative;
`

const ArrowButton = styled.button`
  height: 50px;
  position: absolute;
  .rotate {
    transform: rotate(180deg);
  }
  top: 0;

  &.pos-left {
    left: 0;
  }

  &.pos-right {
    right: 0;
  }
`

const NavBar: React.SFC<NavbarElementProps> = ({
  showPreviousButton,
  showNextButton,
  onPreviousClick,
  onNextClick,
  month,
  localeUtils: { formatMonthTitle }
}) => (
  <NavBarContainer className="text-center text-uppercase ls-12">
    {showPreviousButton && (
      <ArrowButton
        type="button"
        className="btn btn-link pos-left"
        onClick={() => {
          onPreviousClick()
        }}
      >
        <Arrow />
      </ArrowButton>
    )}
    {formatMonthTitle(month, 'en')}
    {showNextButton && (
      <ArrowButton
        type="button"
        className="btn btn-link pos-right"
        onClick={() => {
          onNextClick()
        }}
      >
        <Arrow className="rotate" />
      </ArrowButton>
    )}
  </NavBarContainer>
)

class CalendarPicker extends React.Component<
  ICalendarPicker,
  { hoverDateRange?: { to: Date; from: Date } }
> {
  state = { hoverDateRange: undefined }

  private pickerRef: DayPicker | null = null

  handleMouseEnter = (day: Date) => {
    // If range is available, set as hover
    // The style on how it will show is defined below at { selected } modifier,
    // If it is current selected, show as not selected
    // If it is not selected, show as selected
    const hoverDateRange = this.props.availability.dateRangeForDate(
      day,
      this.props.availability.available
    )

    this.setState(() => ({
      hoverDateRange: hoverDateRange ? hoverDateRange.toRange : undefined
    }))
  }

  handleMouseLeave = () => {
    this.setState(() => ({ hoverDateRange: undefined }))
  }

  componentDidUpdate(prevProps: ICalendarPicker) {
    // Make sure the calendar show the current select date month if
    //  date selection has changed
    // If date was unselected, move it to current month
    if (
      this.pickerRef &&
      this.props.selectedDateRange &&
      !this.props.selectedDateRange.equalsDate(prevProps.selectedDateRange)
    ) {
      this.pickerRef.showMonth(
        this.props.selectedDateRange
          ? this.props.selectedDateRange.date
          : new Date()
      )
    }
  }

  render() {
    const {
      availability,
      selectedDateRange,
      onDateClick,
      onMonthChange
    } = this.props
    const { hoverDateRange } = this.state

    const availableRange = availability.available.map(entry => entry.toRange)

    return (
      <Container data-test="CalendarPickerContainer">
        <DayPicker
          ref={picker => (this.pickerRef = picker)}
          onDayClick={(day, { available }) => {
            if (onDateClick && available) {
              // Only trigger if available
              const newSelectedRange =
                selectedDateRange && selectedDateRange.isWithinDateRange(day)
                  ? undefined
                  : availability.dateRangeForDate(day)

              if (
                newSelectedRange ||
                (!newSelectedRange && this.props.enableDeselect)
              ) {
                // Trigger callback if there is a new selected range or deselected if deselect is enabled
                onDateClick(newSelectedRange)
                // Remove hovered styles so that it won't override selected and mobile will work fine
                this.setState(() => ({ hoverDateRange: undefined }))
              }
            }
          }}
          onDayMouseEnter={this.handleMouseEnter}
          onDayMouseLeave={this.handleMouseLeave}
          onMonthChange={onMonthChange}
          firstDayOfWeek={1}
          fromMonth={new Date()}
          month={selectedDateRange && selectedDateRange.date}
          showOutsideDays
          navbarElement={NavBar}
          captionElement={() => null}
          renderDay={(date, { unavailable }) => {
            return (
              <>
                {unavailable && <span className="unavailable-line" />}
                <span className="DayPicker-Day-Text">{date.getDate()}</span>
              </>
            )
          }}
          modifiers={{
            selected: selectedDateRange && selectedDateRange.toRange,
            hovered: hoverDateRange,
            disabled: { before: new Date() },
            'available-start': availableRange.map(({ from }) => from),
            'available-end': availableRange.map(({ to }) => to),
            available: availableRange,
            unavailable: availability.unavailable.map(entry => entry.toRange)
          }}
        />
      </Container>
    )
  }
}

export default CalendarPicker
