import { types } from 'mobx-state-tree'
import i18nFactory from 'tools/i18n'
const differenceInHours = require('date-fns/difference_in_hours') // tslint:disable-line
const format = require('date-fns/format') // tslint:disable-line

const TimeRange = types
  .model('TimeRange', {
    startTime: types.Date,
    endTime: types.Date,
    inventory: types.number
  })
  .views(self => ({
    get asString(): string {
      return `${format(self.startTime, 'HH:mm')} - ${format(
        self.endTime,
        'HH:mm'
      )} (${differenceInHours(self.endTime, self.startTime)} ${i18nFactory().t(
        'TimeRange.hours',
        { defaultValue: 'Hours' }
      )})`
    }
  }))

export type ITimeRange = typeof TimeRange.Type
export type ITimeRangeSnapshot = typeof TimeRange.SnapshotType
export default TimeRange
