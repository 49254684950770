import { IMoney } from 'stores/Basket/Money'
import i18nFactory from 'tools/i18n'

const currencySymbol = (currencyCode: string): string =>
  currencyCode === 'GBP' ? '£' : currencyCode

export const currencyToString = (
  money?: IMoney | null,
  options: { literal?: boolean; isNegative?: boolean; showDash?: boolean } = {
    literal: false,
    isNegative: false,
    showDash: true
  }
): string => {
  if (!money) {
    if (options.showDash) {
      return '-'
    } else {
      return ''
    }
  }

  return !money.amount && options.literal
    ? i18nFactory().t('price.free', { defaultValue: 'Free' })
    : `${options.isNegative ? '- ' : ''}${currencySymbol(
        money.currency
      )}${money.amount.toFixed(2)}`
}
