import { IAppStore } from 'stores/AppStore'
import { toJS } from 'mobx'
import { find } from 'lodash'
import { getState } from 'tools/session'
import ReactPixel from 'react-facebook-pixel'

// For typescript to compile I have to let it know about the global
// gtag class on window, instantiated in /public/index.html
declare global {
  interface Window {
    gtag: any
    dataLayer: any
  }
}

function gtag(command: any, option: any, parameters?: any) {
  if (!analyticsEnabled()) return
  window.dataLayer.push(arguments)
}
let _opts: IAnalyticOptions = {}

const analyticsEnabled = () => {
  return !_opts.privacy && !!_opts.analyticsId
}

export const setPrivacy = (privacy: boolean) => {
  _opts.privacy = privacy
  // TODO?: If privacy was true when initialised and is set to false, it won't work
  // as GA was not initialised - I'm not sure we need support that scenario
}

// Type definitions
type IAnalyticOptions = {
  privacy?: boolean
  analyticsId?: string
  refererDomain?: string
  appId?: string
  hostnameOrigin?: string
  hostnameBuySdk?: string
  currency?: string
  appStore?: any
  fbpx?: string
}

enum GtagCommand {
  config = 'config',
  set = 'set',
  event = 'event'
}

interface GTItem {
  brand?: string
  category?: string
  creative_name?: string
  creative_slot?: string
  id: string
  location_id?: string
  name?: string
  price: string
  quantity: number
}

export enum ProductAndPromotionActions {
  click = 'click',
  detail = 'detail',
  add = 'add',
  remove = 'remove',
  checkout = 'checkout',
  checkoutOption = 'checkout_option',
  purchase = 'purchase',
  refund = 'refund',
  promoClick = 'promo_click',
  viewItem = 'view_item',
  viewItemList = 'view_item_list',
  selectContent = 'select_content',
  setCheckoutOption = 'set_checkout_option',
  addToCart = 'add_to_cart',
  removeFromCart = 'remove_from_cart',
  beginCheckout = 'begin_checkout',
  checkoutProgress = 'checkout_progress'
}

const config = (targetId: string, configParams?: object) => {
  gtag(GtagCommand.config, targetId, configParams)
}

export const set = (tagSet: object) => {
  gtag(GtagCommand.set, tagSet)
}

export const event = (eventName: string, eventParams?: object) => {
  gtag(GtagCommand.event, eventName, eventParams)
}

export const gteAddPaymentInfo = () => {
  event('add_payment_info')
}

const getBrand = () => {
  const store = getStore()

  return store.configuration.theme === 'landrover' ? 'Land Rover' : 'Jaguar'
}

const getCategory = () => {
  const store = getStore()

  return store.navigation.id === 'GiftVoucher'
    ? 'Gift Vouchers'
    : 'Drive Experiences'
}

const getItemsFromBasket = () => {
  const store = getStore()

  if (!store || !store.basket) return []

  const items = []

  store.basket.items.forEach((item, itemIndex) => {
    if (!item.sku.parameters) return
    item.sku.parameters.forEach((param, paramIndex) => {
      if (!param.value && !param.defaultValue) return
      items.push({
        brand: getBrand(),
        id: item.sku.sku,
        category: getCategory(),
        name: item.name,
        price: item.lineTotalPrice.amount,
        quantity: item.quantity,
        variant: getCategory(),
        action_list: 'Product Details'
      })
    })

    item.addOns.forEach((addon, addonIndex) => {
      addon.parameters.forEach((addonParam, addonParamIndex) => {
        if (!addonParam.value) return
        items.push({
          brand: getBrand(),
          id: addon.sku,
          category: getCategory(),
          name: addonParam.name,
          price: addon.lineTotalPrice.amount,
          quantity: addonParam.value,
          variant: getCategory(),
          action_list: 'Product Details'
        })
      })
    })
  })

  return items
}

export const gteViewItemList = () => {
  const items = getItemsFromBasket()

  event(ProductAndPromotionActions.viewItemList, { items })
}

export const gteSelectContent = () => {
  const items = getItemsFromBasket()

  event(ProductAndPromotionActions.selectContent, {
    content_type: getCategory(),
    items
  })
}

export const gteAddToCart = () => {
  const items = getItemsFromBasket()

  event(ProductAndPromotionActions.addToCart, { items })
}

export const gteRemoveFromCart = () => {
  const items = getItemsFromBasket()

  event(ProductAndPromotionActions.removeFromCart, {
    items
  })
}

export const gteBeginCheckout = () => {
  const store = getStore()
  const items = getItemsFromBasket()
  event(ProductAndPromotionActions.beginCheckout, {
    coupon: store.basket.discountCodeUsed,
    items
  })
}

export const gteCheckoutProgress = () => {
  const store = getStore()
  const items = getItemsFromBasket()

  event(ProductAndPromotionActions.checkoutProgress, {
    coupon: store.basket.discountCodeUsed,
    items
  })
}

export const gtePurchase = () => {
  const store = getStore()
  const items = getItemsFromBasket()

  const selectedDeliveryOption = find(
    store.basket.deliveryOptions,
    o => o.selected
  )
  event(ProductAndPromotionActions.purchase, {
    transaction_id: store.basket.order.orderReference,
    affiliation,
    value: store.basket.grossPrice.amount,
    currency: store.configuration.currency,
    tax: store.basket.vatAmount.amount,
    shipping: selectedDeliveryOption && selectedDeliveryOption.price.amount,
    items
  })
}

export const gteScreenView = (screen_name: string) => {
  event('screen_view', {
    screen_name
  })
}

export const gteSignUp = (method: string) => {
  event('sign_up', {
    method
  })
}

export const gtePromoClick = (name: string) => {
  event('select_content', {
    promotions: [
      {
        name
      }
    ]
  })
}

export const gteSetCheckoutOption = () => {
  const store = getStore()

  const selectedDeliveryOption = find(
    store.basket.deliveryOptions,
    o => o.selected
  )

  const fulfilmentType = selectedDeliveryOption.fulfilmentType

  event(ProductAndPromotionActions.setCheckoutOption, {
    checkout_option: 'shipping method',
    value: fulfilmentType
  })
}

const getFbpxData = () => {
  const store = getStore()
  const item = store.basket.items[0]
  return {
    content_category: item.sku.itemType,
    content_ids: item.id,
    contents: item.sku.name,
    currency: item.lineTotalPrice.currency,
    value: item.lineTotalPrice.amount
  }
}

export const fbpxPageView = () => {
  ReactPixel.pageView()
}

export const fbpxAddToCart = () => {
  ReactPixel.track('AddToCart', getFbpxData())
}

export const fbpxInitiateCheckout = () => {
  ReactPixel.track('InitiateCheckout', getFbpxData())
}

export const fbpxPurchase = () => {
  ReactPixel.track('Purchase', getFbpxData())
}

let ANALYTICS_ID: string
let FB_PIXEL: string
let STORE: IAppStore

const getStore = () => {
  const sessionSnap = getState()

  if (sessionSnap) {
    STORE = sessionSnap
  }
  return toJS(STORE)
}

const affiliation = window.location.hostname

export const initAnalytics = (opts: IAnalyticOptions = {}): void => {
  _opts = opts

  ANALYTICS_ID = _opts.analyticsId

  STORE = _opts.appStore

  FB_PIXEL = STORE.configuration.fbpx

  if (FB_PIXEL) {
    ReactPixel.init(FB_PIXEL, null, { autoConfig: true, debug: true })
  }
  if (!analyticsEnabled()) {
    console.info('Google analytics is disabled')
    return
  }

  const script = document.createElement('script')
  script.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_ID}`
  )

  script.setAttribute('async', '')
  script.setAttribute('id', 'GA_SCRIPT')

  const second: HTMLElement = document.createElement('script')
  second.setAttribute('id', 'GA_INLINE_INIT')

  if (!document.getElementById('GA_SCRIPT')) {
    document.head.append(script)
  }

  if (!document.getElementById('GA_INLINE_INIT')) {
    second.innerHTML = `
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      window.gtag = gtag
      gtag('js', new Date())

      gtag(
        'config',
        '${ANALYTICS_ID}',
        {
          'transport_type': 'beacon',
          'currency': '${_opts.currency}',
          'cookie_flags': 'max-age=7200;SameSite=None;Secure',
          'linker': {
            'domains': [
              '${_opts.hostnameBuySdk}', '${_opts.hostnameOrigin}'
            ]
          }
        },
      )`

    document.head.appendChild(second)
  }
}

export const pageView = (page_path: string) => {
  if (!analyticsEnabled()) return

  config(ANALYTICS_ID, {
    page_path
  })
}
