import * as React from 'react'
import Section from 'atoms/Form/Section'
import StepTextInput from 'molecules/Step/TextInput'
import { translate, InjectedTranslateProps } from 'react-i18next'

const DigitalDelivery: React.SFC<InjectedTranslateProps> = ({ t }) => (
  <Section
    containsForm
    title={t('content.delivery.digital.title', {
      defaultValue: 'Digital delivery details'
    })}
  >
    <div className="row">
      <StepTextInput
        name="deliveryDetail.firstName"
        label={t('content.delivery.firstName', {
          defaultValue: 'First name'
        })}
        className="col-md-6"
      />
      <StepTextInput
        name="deliveryDetail.lastName"
        label={t('content.delivery.lastName', {
          defaultValue: 'Last name'
        })}
        className="col-md-6"
      />
    </div>
    <div className="row">
      <StepTextInput
        className="col-md-12"
        name="deliveryDetail.email"
        label={t('content.delivery.email', {
          defaultValue: 'Email'
        })}
        type="email"
        noMargin
      />
    </div>
    <div className="row">
      <StepTextInput
        className="col-md-12"
        name="deliveryDetail.contactNumber"
        label={t('content.delivery.phone', {
          defaultValue: 'Phone number'
        })}
        type="phone"
        noMargin
      />
    </div>
  </Section>
)

export default translate()(DigitalDelivery)
