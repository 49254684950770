import * as React from 'react'
import styled from 'tools/theme'
import classnames from 'classnames'
import ErrorIcon from 'atoms/Icons/Error'

type Props = {
  error?: string
  className?: string
}

const Container = styled.div`
  margin-bottom: 0.3rem;

  svg {
    margin-right: 0.6rem;
  }
`

const ErrorDescription: React.SFC<Props> = ({ error, className }) => {
  if (!error) {
    return null
  }

  return (
    <Container
      data-test="ErrorDescriptionContainer"
      className={classnames('invalid-feedback ls-11', className)}
    >
      <ErrorIcon className="align-text-top" />
      {error}
    </Container>
  )
}

export default ErrorDescription
