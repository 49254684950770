import * as React from 'react'
import { Fragment } from 'react'
import { Formik, FormikActions, FormikProps, Form, FormikValues } from 'formik'
import FormPage from 'atoms/Form/Page'
import Button from 'atoms/Button'
import Text from 'atoms/Form/Text'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { INavigation } from 'stores/Navigation'
import LockIcon from 'atoms/Icons/Lock'
import { IBasket } from 'stores/Basket'
import Alert from 'atoms/Alert'
import { scrollPage } from 'tools/scroll'
import { PaymentError } from 'stores/Basket/Order'
import { log } from 'tools/log'
import FormikEffects from './FormikEffects'
import { IAppStore } from 'stores/AppStore'
import { hasMobileSize } from 'tools/breakpoints'
import { get } from 'lodash'

interface Props<FormFields> {
  initialValues: FormFields
  onChange?: (values: FormikValues) => void
  navigation?: INavigation
  basket?: IBasket
  appStore?: IAppStore
}

interface State<FormFields> {
  values: FormFields
  error?: Error
}

class Wizard<FormFields> extends React.Component<
  Props<FormFields> & InjectedTranslateProps,
  State<FormFields>
> {
  constructor(props: Props<FormFields> & InjectedTranslateProps) {
    super(props)
    this.state = {
      values: props.initialValues
    }
  }

  activePage = (): React.ReactElement<any> => {
    return React.Children.toArray(this.props.children)[
      this.props.navigation!.currentStep
    ] as React.ReactElement<any>
  }

  validate = (values: FormFields) => {
    const activePage = this.activePage()
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = async (
    values: FormFields,
    actions: FormikActions<FormFields>
  ) => {
    const activePage = this.activePage()
    // Reset status after submit
    // That is used by step controls
    // to make sure we validate only after form is submitted on step
    actions.setStatus(0)

    if (activePage.props.onSubmit) {
      this.setState(() => ({ error: undefined }))

      try {
        await activePage.props.onSubmit(values, actions)
      } catch (error) {
        log('[onSubmit Error]', error)
        // Error, set state and scroll to top
        scrollPage(0, 0)
        this.setState(() => ({ error }))
      }
    } else {
      // No onSubmit, advance to next step
      this.props.navigation!.nextStep()
    }

    // Finish submitting (error or success)
    actions.setSubmitting(false)
  }

  renderError() {
    const { t } = this.props
    const { error } = this.state

    if (!error) {
      return null
    }

    // TODO: this needs to be DRYer and cleaner
    if (get(error, 'response.data.errorType') === 'VALIDATION') {
      return (
        <Alert
          type="error"
          title={t('page.error.validation.title', {
            defaultValue: 'Validation error'
          })}
        >
          {t('page.error.validation.message', {
            defaultValue:
              'There was a problem validating the details you have provided. Please review that all the fields are correct.'
          })}
        </Alert>
      )
    }

    if (error instanceof PaymentError) {
      return (
        <Alert
          type="error"
          title={t('content.payment.error.title', {
            defaultValue: 'Failed to process payment'
          })}
        >
          {t('content.payment.error.description', {
            defaultValue:
              'Unable to process the payment. Please try again or contact administrator.'
          })}
        </Alert>
      )
    } else {
      return (
        <Alert
          type="error"
          title={t('page.error.alert.title', {
            defaultValue: 'Failed to continue'
          })}
        >
          {t('page.error.alert.content', {
            defaultValue:
              'Unable to progress to next step. Please try again, ' +
              'if error persists, contact administrator.'
          })}
        </Alert>
      )
    }
  }

  renderTop() {
    if (
      !this.activePage() ||
      !this.activePage().props ||
      !this.activePage().props.top
    ) {
      return null
    }

    return this.activePage().props.top
  }

  render() {
    const { t, navigation, basket, appStore, onChange } = this.props
    const { isAwaitingPayment } = basket!
    const {
      isConfirmStep,
      isPaymentStep,
      isLastStep,
      isShowingBasket
    } = navigation!
    const { values } = this.state
    const activePage = this.activePage()
    if (!activePage) {
      return null
    }
    const hasPendingRequests = appStore.hasPendingRequests

    const isMobileWidth = hasMobileSize(window.innerWidth)
    const buttonClass = isMobileWidth ? 'btn-block' : 'float-right'
    let mainItemUpdating: boolean
    if (basket && basket.items[0]) {
      mainItemUpdating = basket.items[0].isUpdating
    }

    const getButtonLabel = t => {
      if (isPaymentStep) {
        return t('page.button.finishAndPay', {
          defaultValue: 'Finish & Pay'
        })
      } else if (isConfirmStep) {
        return t('page.button.confirm', {
          defaultValue: 'Confirm'
        })
      } else {
        return t('page.button.continue', {
          defaultValue: 'Continue'
        })
      }
    }

    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={(formikProps: FormikProps<FormFields>) => {
          return (
            <Form
              onKeyDown={(ev: any) => {
                // Prevent ENTER from submitting the form.
                if ((ev.charCode || ev.keyCode) === 13) {
                  ev.preventDefault()
                }
              }}
              noValidate
            >
              {onChange && <FormikEffects onChange={onChange} />}
              <FormPage
                top={
                  <Fragment>
                    {this.renderError()}
                    {this.renderTop()}
                  </Fragment>
                }
                isShowingBasket={isShowingBasket}
                bottom={
                  !isLastStep &&
                  !isAwaitingPayment && (
                    <div>
                      {!!formikProps.status && // If form was submitted and there are errors, show message
                        !!Object.keys(formikProps.errors).length && (
                          <Text error bold className="mb-4 text-right">
                            {t('page.error', {
                              defaultValue:
                                'Please fix errors above to continue purchase'
                            })}
                          </Text>
                        )}

                      {basket.mainItem && (
                        <Button
                          icon={isPaymentStep && <LockIcon />}
                          isLoading={
                            hasPendingRequests ||
                            formikProps.isSubmitting ||
                            mainItemUpdating
                          }
                          onClick={() => {
                            // Counting submits
                            // That is used by step controls
                            // to make sure we validate only after form is submitted on step
                            formikProps.setStatus(
                              typeof formikProps.status === 'undefined'
                                ? 1
                                : formikProps.status + 1
                            )
                          }}
                          type="submit"
                          data-test="MainButton"
                          className={buttonClass}
                        >
                          {getButtonLabel(t)}
                        </Button>
                      )}
                    </div>
                  )
                }
              >
                {React.cloneElement(activePage, { formikProps })}
              </FormPage>
            </Form>
          )
        }}
      />
    )
  }
}

export default translate()(
  inject(({ appStore }) => {
    return {
      navigation: appStore.navigation as INavigation,
      basket: appStore.basket,
      appStore: appStore
    }
  })(observer(Wizard))
)
