import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { IConfiguration } from 'stores/Configuration'
import { IAppStore } from 'stores/AppStore'
import { AdvocacyCodeCheck } from 'components/AdvocacyCodeCheck'
import BoxContainer from 'atoms/Form/BoxContainer'
import HeroImage from 'molecules/HeroImage'
import { SelectInput } from 'atoms/Form/SelectInput'
import { Disableable } from 'atoms/Disableable'
import { BasketUI } from 'organisms/MyBasket/BasketUI'
import BeforeStartInfo, { BeforeStartVariants } from 'atoms/BeforeStartInfo'

interface IAdvocacyValidatorProps {
  appStore?: IAppStore
  configuration?: IConfiguration
}
const AdvocacyValidator: React.SFC<IAdvocacyValidatorProps> = props => {
  const { appStore, configuration } = props
  const { validCampaignCode } = configuration
  return (
    <Disableable disabled={appStore.hasPendingRequests}>
      <HeroImage imageFn={() => '/cdn/centre/hero/lre_sol_large_1280.jpg'}>
        Complimentary owner experience
      </HeroImage>
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <h4 className="text-uppercase ls-7 mt-5 mb-3">Welcome</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <BeforeStartInfo
              variant={BeforeStartVariants.BuyExperience}
              text={configuration.importantNotice}
            />
            <BoxContainer className="pb-0 my-5">
              <div className="d-flex">
                <h3 className="text-uppercase mr-3 sh-1 ls-8 m-0">Centre: </h3>
                <h3 className="text-uppercase sh-1 ls-8 m-0">
                  {appStore.configuration.centre}
                </h3>
              </div>
            </BoxContainer>
            {!validCampaignCode && (
              <div className="my-5">
                <AdvocacyCodeCheck config={configuration}></AdvocacyCodeCheck>
              </div>
            )}
            {validCampaignCode && (
              <div>
                <BoxContainer>
                  <h3 className="text-uppercase mr-3 mb-5 sh-1 ls-8">
                    Select a vehicle:
                  </h3>
                  <div className="flex-grow-1">
                    <SelectInput
                      isInline
                      label="Vehicle"
                      name="vehicle"
                      value={appStore.catalogue.selectedItem}
                      onChange={option => {
                        //@ts-ignore
                        const sku = option.value
                        appStore.catalogue.loadItem(sku).then(() => {
                          appStore.loadDefaultAvailability().then(() => {
                            appStore.createBasket()
                          })
                        })
                      }}
                      options={configuration.availableProducts}
                    ></SelectInput>
                  </div>
                </BoxContainer>
              </div>
            )}
          </div>
          <div className="col-md-6 col-sm-12">
            <div>
              <BasketUI centre={appStore.configuration.centre} centrePhone={appStore.configuration.centreNumber}></BasketUI>
            </div>
          </div>
        </div>
      </div>
    </Disableable>
  )
}

export default inject(({ appStore }) => {
  return {
    appStore: appStore as IAppStore,
    configuration: appStore.configuration as IConfiguration
  }
})(observer(AdvocacyValidator))
