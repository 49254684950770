import { types } from 'mobx-state-tree'

const Money = types.model('Money', {
  amount: types.number,
  currency: types.string
})

export type IMoney = typeof Money.Type
export type IMoneySnapshot = typeof Money.SnapshotType
export default Money
