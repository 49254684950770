export const env = 'dev'

const config = {
  local: {
    baseUrl: 'https://webstore.dev.experience.landrover.com',
  },
  localkitt: {
    baseUrl: 'http://localhost:8080',
  },
  dev: {
    baseUrl: 'https://int-alb.dev.experience.jaguarlandrover.com',
  },
  stage: {
    baseUrl: 'https://int-alb.staging.experience.jaguarlandrover.com',
  },
  prod: {
    baseUrl: 'https://int-alb.experience.jaguarlandrover.com',
  }
}

export const getConfig = () => config[env]
export const getEnv = () => env
