import { types } from 'mobx-state-tree'

const AddOnParameter = types.model('BasketAddOnParameter', {
  id: types.string,
  name: types.string,
  value: types.number
})

export type IAddOnParameter = typeof AddOnParameter.Type
export type IAddOnParameterSnapshot = typeof AddOnParameter.SnapshotType
export default AddOnParameter
