import * as React from 'react'

const WarningIcon: React.SFC<{ className?: string }> = ({ className }) => (
  <svg
    width="22px"
    height="20px"
    viewBox="0 0 22 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <defs>
      <path
        d="M12.8929135,13.5444034 C12.8929135,14.0714034 12.4649135,14.4994034 11.9379135,14.4994034 C11.4109135,14.4994034 10.9829135,14.0714034 10.9829135,13.5444034 L10.9829135,9.16040335 C10.9829135,8.63240335 11.4109135,8.20540335 11.9379135,8.20540335 C12.4649135,8.20540335 12.8929135,8.63240335 12.8929135,9.16040335 L12.8929135,13.5444034 Z M13.0579135,16.7654034 C13.0549135,16.1774034 12.5589135,15.6854034 11.9689135,15.6824034 C11.3639135,15.6794034 10.8629135,16.1814034 10.8669135,16.7854034 C10.8719135,17.3794034 11.3589135,17.8614034 11.9569135,17.8634034 C12.5689135,17.8654034 13.0609135,17.3704034 13.0579135,16.7654034 L13.0579135,16.7654034 Z M21.1179135,19.6384034 C21.1829135,19.7514034 11.9639135,3.82140335 11.9639135,3.78040335 C11.9639135,3.83740335 2.8079135,19.5804034 2.8079135,19.6384034 L21.1179135,19.6384034 Z M3.2909135,21.5134034 C1.5399135,21.5134034 0.418913497,19.7314034 1.3169135,18.1594034 C1.3869135,18.1514034 10.1179135,3.05740335 10.1179135,3.05740335 C10.9729135,1.65240335 13.0749135,1.65240335 13.8779135,3.02840335 C13.8779135,3.02840335 22.6879135,18.1514034 22.5899135,18.1344034 C23.5119135,19.7334034 22.4799135,21.5074034 20.6359135,21.5124034 L3.2909135,21.5124034 L3.2909135,21.5134034 Z" // tslint:disable-line
        id="path-1"
      />
    </defs>
    <g
      id="Experience-2-Drivers_Dskt"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="1.0-Adventure-Travel_Book-Now_Experience-Details"
        transform="translate(-108.000000, -412.000000)"
      >
        <g id="before-you-start" transform="translate(90.000000, 386.000000)">
          <g id="Group" transform="translate(17.000000, 24.000000)">
            <g id="01.-LR-Brand-/-04.-Icons-/-Warning">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <use
                id="Mask"
                fill="#000000"
                fillRule="evenodd"
                xlinkHref="#path-1"
              />
              <g
                id="01.-LR-Brand-/-01.-LR-Brand-/-02.-Swatch-/-01.-Slate-blue---#0C121C"
                mask="url(#mask-2)"
                fill="#0C121C"
                fillRule="evenodd"
              >
                <g
                  transform="translate(-24.000000, -24.000000)"
                  id="Slate-blue---#0C121C"
                >
                  <rect x="0" y="0" width="72" height="72" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default WarningIcon
