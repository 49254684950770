import React from 'react'
import stripeJs from '@stripe/stripe-js'
import { ElementsConsumer } from '@stripe/react-stripe-js'
import CardPaymentSection from './CardPaymentSection'
import Button from 'atoms/Button'
import { hasMobileSize } from '../../tools/breakpoints'
import LockIcon from '../Icons/Lock'
import { IStepContent } from '../../organisms/Main'
import * as Sentry from '@sentry/browser'

type CardCheckoutProps = IStepContent & {
  stripe: stripeJs.Stripe | null
  elements: stripeJs.StripeElements | null
  clientSecret: string
}

class CardPaymentForm extends React.Component<
  CardCheckoutProps,
  { isLoading: boolean }
> {
  constructor(props: Readonly<CardCheckoutProps>) {
    super(props)

    this.state = { isLoading: false }
  }

  handlePayButton = async () => {
    const {
      basket: { billingAddress },
      basket,
      navigation,
      stripe,
      elements,
      clientSecret,
      t
    } = this.props

    if (
      !clientSecret ||
      clientSecret === '' ||
      typeof clientSecret === 'undefined'
    ) {
      const stripeMsg =
        'Missing the required client secret for Stripe payment submission.'
      Sentry.captureMessage(stripeMsg)
      console.error(stripeMsg)
      return
    }

    if (!elements || !stripe) {
      // Stripe.js has not yet loaded.
      return
    }

    if (!billingAddress) {
      const stripeMsg = 'There is no billing address.'
      console.error(stripeMsg)
      Sentry.captureMessage(stripeMsg)
      return null
    }

    this.setState({ isLoading: true })

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement('card'),
        billing_details: {
          name: billingAddress.firstName + ' ' + billingAddress.lastName,
          email: billingAddress.email,
          address: {
            country: billingAddress.country,
            postal_code: billingAddress.postalCode,
            line1: billingAddress.addressLine1,
            line2: billingAddress.addressLine2,
            city: billingAddress.postalTown
          }
        }
      }
    })

    this.setState({ isLoading: false })

    if (result.error) {
      // Checks for a 3D Secure authentication failure
      if (result.error.code === 'payment_intent_authentication_failure') {
        const stripeMsg = t('Stripe.errors.notVerified', {
          defaultValue:
            'Your payment method could not be verified. ' +
            'Please check your payment and identity verification information and try again.'
        })
        alert(stripeMsg)
        Sentry.captureMessage(stripeMsg)
      } else if (result.error.decline_code === 'insufficient_funds') {
        const stripeMsg = t('Stripe.errors.insufficientFunds', {
          defaultValue:
            'Your payment method has insufficient funds. Please try a different payment method.'
        })
        alert(stripeMsg)
        Sentry.captureMessage(stripeMsg)
        // If we get a fraud or generic decline code, issue a generic alert and forward them to the next step.
        // The order will get marked as failed in the back-end.
      } else if (
        result.error.decline_code === 'fraudulent' ||
        result.error.decline_code === 'lost_card' ||
        result.error.decline_code === 'stolen_card' ||
        result.error.decline_code === 'generic_decline'
      ) {
        const stripeMsg = t('Stripe.errors.genericDecline', {
          defaultValue:
            'Your card has been declined. Please contact your card issuer for more information.'
        })
        alert(stripeMsg)
        Sentry.captureMessage(stripeMsg)
        navigation.nextStep()
      } else {
        const stripeMsg = t('Stripe.errors.notProcessed', {
          defaultValue:
            'We were unable to process your payment.' +
            ' Please check your payment information and try again.'
        })
        alert(stripeMsg)
        Sentry.captureMessage(stripeMsg)
      }
    } else {
      basket.order.setPaid()
      navigation.nextStep()
    }
  }

  render() {
    const isMobileWidth = hasMobileSize(window.innerWidth)
    const buttonClass = isMobileWidth ? 'btn-block' : 'float-right'

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <CardPaymentSection />
          </div>
        </div>
        <div className="row pos-container">
          <div className="col-md-12">
            <Button
              onClick={this.handlePayButton}
              className={buttonClass}
              icon={<LockIcon />}
              isLoading={this.state.isLoading}
            >
              Submit Payment
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

// @ts-ignore
export default function InjectedCardPaymentForm(props: any) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardPaymentForm
          stripe={stripe}
          elements={elements}
          basket={props.basket}
          navigation={props.navigation}
          t={props.t}
          clientSecret={props.clientSecret}
        />
      )}
    </ElementsConsumer>
  )
}
