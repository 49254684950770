/* eslint-disable */
import * as React from 'react'
import styled, { media } from 'tools/theme'
import classnames from 'classnames'

import Lock from './icons/lock.svg'
import Phone from './icons/phone.svg'

import Visa from './icons/visa.svg'
import Mastercard from './icons/mastercard.svg'
import Maestro from './icons/maestro.svg'

import { translate, InjectedTranslateProps } from 'react-i18next'

interface Props {
  phone?: string | null
  className?: string
}

const Container = styled.div`
  border: 1px solid #e9ecec;
  background-color: #ffffff;
`

const InfoBox = styled.div`
  font-size: 1.1rem;
  font-weight: bold;

  .icon {
    width: 14px;
    height: 14px;
  }

  &:last-child {
    border-left: 1px solid #d8d8d8;

    ${media.xs`border-left: 0;`};
  }

  p:last-child {
    margin-bottom: 0;
  }

  .phone {
    font-size: 1.8rem;

    a {
      color: ${props => props.theme.slateBlue};

      &:hover {
        text-decoration: none;
      }
    }
  }

  .logos {
    img {
      margin-left: 1.4rem;
      &:first-child {
        margin-left: 0;
      }
    }

    .visa {
      height: 28px;
    }

    .mastercard {
      height: 35px;
    }
  }
`

const Information: React.SFC<Props & InjectedTranslateProps> = ({
  phone,
  className,
  t
}) => {
  return (
    <Container
      data-test="BasketInformationContainer"
      className={classnames(
        'px-3 py-4 row text-center d-flex flex-wrap font-brand',
        className
      )}
    >
      <InfoBox className="col-sm px-2 pb-3 pb-md-0">
        <p className="text-uppercase ls-4 mb-1 mb-md-3 pl-2">
          <img src={Lock} className="icon align-text-top mr-2" />
          {t('basket.information.payments', {
            defaultValue: '100% SECURE online pAYMENTs'
          })}
        </p>
        <p className="logos">
          <img src={Visa} alt="Visa" className="visa" />
          <img src={Mastercard} alt="Mastercard" className="mastercard" />
          <img src={Maestro} alt="Maestro" className="mastercard" />
        </p>
      </InfoBox>
      <InfoBox className="col-sm px-2">
        <p className="text-uppercase ls-4 mb-1 mb-md-3">
          {t('basket.information.phone', {
            defaultValue: 'call our customer service team'
          })}
        </p>
        <p className="phone">
          <a href={`tel:${phone}`} data-test="BasketInformationPhone">
            <img src={Phone} className="icon align-baseline mr-2" />
            {phone || '-'}
          </a>
        </p>
      </InfoBox>
    </Container>
  )
}

export default translate()(Information)
