import * as queryString from 'query-string'

const reURLInformation = new RegExp(
  [
    '^(https?:)//', // protocol
    '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
    '(/{0,1}[^?#]*)', // pathname
    '(\\?[^#]*|)', // search
    '(#.*|)$' // hash
  ].join('')
)

export const get = (): { [s: string]: string } => {
  let parentURL = ''
  if (window && window.location && window.parent) {
    parentURL =
      window.location !== window.parent.location
        ? document.referrer
        : (document.location && document.location.href) || ''
  }

  const parentURLMatch = parentURL.match(reURLInformation)

  const parentQueryString = parentURL
    ? queryString.parse((parentURLMatch && parentURLMatch[6]) || '')
    : {}

  const query = {
    // Read parent iframe query string
    // That is to be able to change options (such as theme) in storybook
    ...queryString.parse(window.location.search), // options from query string
    ...parentQueryString
  }

  Object.keys(query).forEach(name => {
    if (query[name] === 'true') {
      query[name] = true
    } else if (query[name] === 'false') {
      query[name] = false
    }
  })

  return query
}

export const set = (newValues: { [s: string]: string }): void => {
  const { origin, pathname, search } = window.location

  window.location.replace(
    `${origin}${pathname}?${queryString.stringify({
      ...queryString.parse(search),
      ...newValues
    })}`
  )
}

export const getAsString = (): string =>
  Object.keys(get()).length ? `?${queryString.stringify(get())}` : ''
