import * as React from 'react'
import classnames from 'classnames'
import Input, { IInput } from 'atoms/Form/Input'

export type ITextInput = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  IInput & {
    makeTextarea?: boolean
  }

const TextInput: React.SFC<ITextInput> = props => {
  const {
    optional,
    noMargin,
    labelAsPlaceholder,
    makeTextarea,
    ...inputProps
  } = props

  return (
    <Input
      {...inputProps}
      optional={optional && optional.toString()}
      data-test="TextInput"
    >
      {React.createElement(makeTextarea ? 'textarea' : 'input', {
        ...inputProps,
        className: classnames('ls-11', {
          'is-invalid': !!props.error,
          'label-on-top': !!props.value
        })
      })}
    </Input>
  )
}

export default TextInput
