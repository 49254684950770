import { types } from 'mobx-state-tree'
import Money from 'stores/Basket/Money'

const TimetableParameter = types.model('TimetableParameter', {
  name: types.string,
  price: Money
})

export type ITimetableParameter = typeof TimetableParameter.Type
export type ITimetableParameterSnapshot = typeof TimetableParameter.SnapshotType
export default TimetableParameter
