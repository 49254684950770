import * as React from 'react'
import CloseIcon from 'atoms/Icons/Close'
import styled from 'react-emotion'

export const ModalMessageUi = ({ content, onClose }) => {
  if (!content) return null
  window.parentIFrame.scrollTo(0, 0)
  return (
    <Vale onClick={onClose}>
      <Panel>
        <Header>
          <CloseIcon></CloseIcon>
        </Header>
        <Title>{content.title}</Title>
        <span>{content.content}</span>
      </Panel>
    </Vale>
  )
}

const Vale = styled('div')({
  zIndex: 5000,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0,0,0,.8)'
})

const Panel = styled('div')({
  position: 'fixed',
  left: 100,
  right: 100,
  top: 200,
  background: 'white',
  padding: 20,
  boxShadow: '0px 1px 5px 1px'
})

const Header = styled('div')({
  cursor: 'pointer',
  fontSize: 10,
  display: 'flex',
  justifyContent: 'flex-end'
})

const Title = styled('h1')({
  textTransform: 'uppercase',
  fontSize: 14,
  letterSpacing: 1,
  marginBottom: 20
})
