import * as React from 'react'
import classnames from 'classnames'
import styled from 'tools/theme'

const Flag = styled.p`
  font-size: 1.2rem;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  font-weight: lighter;
  padding: 0.2rem 1rem;
  position: relative;
  height: 2.3rem;
  margin-right: 18px;

  &::after {
    content: '';
    width: 0;
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 2.3rem 0 0 1rem;
    border-color: ${props =>
      `transparent transparent transparent ${props.theme.primary}`};
    right: -1rem;
    top: 0;
  }
`

const Option: React.SFC<{
  className?: string
}> = ({ className }) => {
  return (
    <Flag className={classnames('ls-8 my-0 d-inline-block', className)}>
      Add-on
    </Flag>
  )
}

export default Option
