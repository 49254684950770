import * as React from 'react'
import classnames from 'classnames'
import styled, { IThemeInterface } from 'tools/theme'
import { Motion, spring } from 'react-motion'

interface Props {
  theme?: IThemeInterface
  steps: string[]
  currentStep: number
}

const Container = styled.nav`
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
`

const Step = styled<{ filling: number }, any>('div')`
  font-size: 1.3rem;
  background-color: ${props => props.theme.grey5};
  color: ${props => props.theme.dark};
  height: 60px;

  &:not(:last-child) {
    border-right: 1px solid rgba(12, 18, 28, 0.1);
  }

  &.disabled {
    background-color: ${props => props.theme.mediumGrey};
    > div {
      opacity: 0.4;
    }
  }

  &.active {
    border-right: ${props =>
      props.filling === 100
        ? 'undefined'
        : `1px solid ${props.theme.mediumGrey}`};
    background: ${props => props.theme.primary};
    background: ${props =>
      `linear-gradient(to right, ${props.theme.primary} ${props.filling}%, ${props.theme.mediumGrey} ${props.filling}%)`};
    color: ${props => props.theme.white};
    opacity: ${props => props.filling / 100}

    &:after {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: ${props =>
        props.filling === 100 ? `10px solid ${props.theme.primary}` : 'none'};
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 100%;
      z-index: 3;
    }

    &:before {
      content: ' ';
      display: ${props => (props.filling > 95 ? 'block' : 'none')};
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid rgba(12, 18, 28, 0.1);
      position: absolute;
      top: 50%;
      margin-top: -10px;
      margin-left: 1px;
      left: 100%;
      z-index: 3;
    }
  }
`

const ProgressBar: React.SFC<Props> = ({ steps, currentStep }) => (
  <Container className="row" aria-label="breadcrumb">
    {steps.map((step, idx) => (
      <Motion
        key={`${idx}-${currentStep}`}
        defaultStyle={{ filling: 0, currentStep }}
        style={{
          filling: spring(50, { stiffness: 120, damping: 20, precision: 0.1 }),
          currentStep
        }}
      >
        {interpolatingStyle => (
          <Step
            filling={interpolatingStyle.filling * 2}
            className={classnames(
              'col ls-7 text-center text-uppercase d-flex justify-content-center align-items-center',
              {
                active: idx === currentStep,
                disabled: idx > currentStep
              }
            )}
            aria-current={idx === currentStep ? 'step' : false}
          >
            <div className="align-self-center">
              <span className="d-md-none">{idx + 1}</span>
              <span className="d-none d-md-inline-block">
                {idx + 1}. {step}
              </span>
            </div>
          </Step>
        )}
      </Motion>
    ))}
  </Container>
)

export default ProgressBar
