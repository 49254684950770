import * as React from 'react'
import { observer } from 'mobx-react'
import Wizard from 'organisms/Wizard'
import WizardPage from 'organisms/Wizard/Page'
import { IStepContent } from 'organisms/Main'
import { debounce, startsWith } from 'lodash'
import ExperienceDetails from '../shared/ExperienceDetails'
import CheckfrontFormRenderer from '../shared/CheckfrontFormRenderer'
import ItemsTable from 'molecules/OrderSummary/ItemsTable'
import { CustomerDetails } from 'molecules/CustomerDetails'
import PaymentDetails from 'organisms/Scenarios/Screens/PaymentDetails'
import Alert from 'atoms/Alert'

const AdvocacyExperience: React.SFC<IStepContent> = ({
  t,
  basket,
  navigation,
  catalogue
}) => {
  const { createOrder, order, mainItem } = basket
  const { nextStep } = navigation

  const entry = mainItem!

  const debouncedOnChange = debounce(values => {
    basket.mainItem.item.setParametersValue(values.parameters)
    basket.mainItem.updateBooking({
      date: values.date,
      startTime: values.startTime,
      endTime: values.endTime,
      parameters: values.parameters
    })
  }, 500)

  const paymentBookingSuccessText = t('Payment.booking.success.text', {
    defaultValue: 'Your booking is being processed. Your booking reference is '
  })

  return (
    <Wizard
      initialValues={{
        parameters: entry.sku.defaultParameters,
        billingAddress: {
          email: '',
          contactNumber: '',
          firstName: '',
          lastName: '',
          addressLine1: '',
          addressLine2: '',
          locality: '',
          country: '',
          postalTown: '',
          adminDistrict: '',
          district: '',
          postalCode: ''
        },
        informed: false,
        agreed: false,
        date: entry.selectedDateRange
          ? entry.selectedDateRange.date
          : undefined,
        startTime: entry.selectedTimeRange
          ? entry.selectedTimeRange.startTime
          : undefined,
        endTime: entry.selectedTimeRange
          ? entry.selectedTimeRange.endTime
          : undefined
      }}
      onChange={values => {
        if (navigation.currentStep === 0) {
          // On change set the selected parameters
          debouncedOnChange(values)
        }
      }}
    >
      <WizardPage>
        <ExperienceDetails
          basket={basket}
          navigation={navigation}
          t={t}
          advocacyVariant
        />
      </WizardPage>
      <WizardPage
        onSubmit={async values => {
          try {
            await basket.saveForm(
              Object.keys(values)
                .filter(formKey => startsWith(formKey, 'basketForm_'))
                .map(formKey => {
                  const regex = formKey.match(/basketForm_(.*)/)
                  return {
                    id: regex ? regex[1] : formKey,
                    value: values[formKey].toString()
                  }
                })
            )
            await createOrder({
              contactConsentGiven: true,
              tosAccepted: true,
              checkPaymentManually: true
            })
            nextStep()
          } catch (e) {
            // I don't want to create order if there has been errors in the save form call.
            return
          }

          nextStep()
        }}
      >
        <CheckfrontFormRenderer basket={basket} navigation={navigation} t={t} />
      </WizardPage>
      <WizardPage
        top={
          <Alert
            type={'success'}
            title={t('Payment.booking.success.title', {
              defaultValue: 'THANK YOU FOR YOUR BOOKING.'
            })}
            className="mb-4"
          >
            {paymentBookingSuccessText + (order && order.orderReference)}
          </Alert>
        }
      >
        <ItemsTable basket={basket} catalogue={catalogue} />
        <CustomerDetails basket={basket}></CustomerDetails>
        {basket.hasSelectedAddons && order && order.status !== 'error' && (
          <PaymentDetails
            basket={basket}
            catalogue={catalogue}
            navigation={navigation}
            t={t}
          />
        )}
      </WizardPage>
    </Wizard>
  )
}

export default observer(AdvocacyExperience)
