import * as React from 'react'
import classnames from 'classnames'

import styled, { media } from 'tools/theme'
import { transparentize } from 'polished'

import CalendarIcon from 'atoms/Icons/Calendar'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { IDateRange } from 'stores/Availability/DateRange'

const Container = styled.div`
  border-top: ${props =>
    `1px solid ${transparentize(0.7, props.theme.darkCoolGrey)}`};
  border-bottom: ${props =>
    `1px solid ${transparentize(0.7, props.theme.darkCoolGrey)}`};
  border-left: ${props => `3px solid ${props.theme.primary}`};
  line-height: 65px;
  padding: 0 15px 0 15px;

  button {
    margin: 10px 0;
    border-width: 2px;
    font-size: 12px;
    width: 236px;

    ${media.lg`
      margin: 0 0 10px 0;
      width: 100%;
    `};
  }

  .title {
    font-size: 18px;
    font-weight: 900;
    color: ${props => props.theme.dark};
    display: inline-block;
    margin-left: 15px;
  }
`

export interface INextDatePicker {
  dateRange?: IDateRange
  selected?: boolean
  onClick: () => void
  className?: string
}

const NextDatePicker: React.SFC<INextDatePicker & InjectedTranslateProps> = ({
  className,
  t,
  dateRange,
  selected,
  onClick
}) => (
  <Container
    data-test="NextDayPickerContainer"
    className={classnames('clearfix', className)}
  >
    <CalendarIcon className="align-text-bottom" />
    <span className="title ls-10">
      {t('nextDatePicker.nextAvailable', { defaultValue: 'Next Available:' })}
    </span>
    <button
      type="button"
      disabled={!dateRange || selected}
      className={classnames(
        'btn btn-outline-primary text-uppercase ls-18 float-right',
        { active: selected }
      )}
      onClick={event => {
        event.preventDefault()
        onClick()
      }}
    >
      {dateRange
        ? dateRange.toString()
        : t('nextDatePicker.loading', {
            defaultValue: 'Please wait, loading...'
          })}
    </button>
  </Container>
)

export default translate()(NextDatePicker)
