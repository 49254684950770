import * as React from 'react'
import { Fragment } from 'react'
import { observer } from 'mobx-react'
import Option from 'atoms/Option'
import Section from 'atoms/Form/Section'
import Radio from 'atoms/Form/Radio'
import { currencyToString } from 'tools/currency'
import Wizard from 'organisms/Wizard'
import WizardPage from 'organisms/Wizard/Page'
import StepTextInput from 'molecules/Step/TextInput'
import { IStepContent } from 'organisms/Main'
import BoxContainer from 'atoms/Form/BoxContainer'
import OrderSummary from 'molecules/OrderSummary'
import { IContactDetails } from 'stores/Basket/ContactDetails'
import DigitalDelivery from 'organisms/Scenarios/Screens/DigitalDelivery'
import PhysicalDelivery from 'organisms/Scenarios/Screens/PhysicalDelivery'
import PrePopulatedInfo from 'atoms/PrePopulatedInfo'
import PaymentDetails from 'organisms/Scenarios/Screens/PaymentDetails'
import OrderSuccessOrFailureMessage from 'molecules/OrderSuccessOrFailureMessage'
import PaymentNotConfirmedMessage from 'molecules/PaymentNotConfirmedMessage'
import QuantityOption from 'molecules/QuantityOption'
import { FieldArray } from 'formik'
import { ICustomisations, ICustomisation } from 'stores/Basket/Customisation'
import { appStore } from 'stores'
import { throttle, times, get } from 'lodash'

export interface IGiftVoucherForm {
  [field: string]: any
  nameOnVoucher?: ICustomisation[]
  customisation: ICustomisations
  deliveryDetail: IContactDetails
  sameBillingDetails: boolean
  billingAddress: IContactDetails
  informed: boolean
  agreed: boolean
}

let throttledUpdatePrice: any = null

const GiftVoucher: React.SFC<IStepContent> = ({
  basket,
  catalogue,
  navigation,
  t
}) => {
  const {
    items,
    deliveryOptions,
    billingAddress,
    deliveryDetail,
    setDeliveryOption,
    setDeliveryDetail,
    setBillingAddress,
    isDigitalDelivery,
    createOrder
  } = basket
  const entry = items[0]
  if (!entry) return null
  const catalogueItem = catalogue!.item
  const catalogueItemQuantityParam = catalogueItem.getParametersValue(
    'quantity'
  )
  const { maxValue, minValue, label } = catalogueItemQuantityParam
  const isCustomisable = entry && entry.item.customisable
  if (!throttledUpdatePrice) {
    throttledUpdatePrice = throttle(entry.updatePrice, 1000)
  }

  const nameOnVoucher = entry.customisations.customisationsArray.map(
    (cust: any) => cust.customText
  )

  const initialCustomisations = nameOnVoucher

  const filteredDeliveryOptions = deliveryOptions.filter(
    ({ name, id }) => !name.includes('Presentation Pack')
  )

  return (
    <Wizard
      initialValues={{
        nameOnVoucher: initialCustomisations || [''],
        customisations: entry.customisations,
        billingAddress: {
          email: '',
          contactNumber: '',
          firstName: '',
          lastName: '',
          addressLine1: '',
          addressLine2: '',
          locality: '',
          country: '',
          postalTown: '',
          adminDistrict: '',
          district: '',
          postalCode: ''
        },
        deliveryDetail: {
          email: '',
          contactNumber: '',
          firstName: '',
          lastName: '',
          addressLine1: '',
          addressLine2: '',
          locality: '',
          // If no country available, set as default
          country:
            basket.deliveryCountries.length === 1
              ? basket.deliveryCountries[0]
              : '',
          postalTown: '',
          adminDistrict: '',
          district: '',
          postalCode: ''
        },
        sameBillingDetails: true,
        informed: false,
        agreed: false
      }}
    >
      <WizardPage<IGiftVoucherForm>
        onSubmit={async values => {
          if (isCustomisable && values.nameOnVoucher) {
            const customisations: any[] = []
            values.nameOnVoucher.forEach((name: any) => {
              if (name) {
                customisations.push({ customText: name })
              }
            })
            await entry.setCustomisations({
              customisationsArray: customisations
            })
          }
          appStore.navigation.nextStep()
        }}
      >
        <BoxContainer>
          <FieldArray
            name="nameOnVoucher"
            render={(arrayHelpers: any) => {
              const quantityArray = times(entry.quantity, () => '')
              return (
                <Fragment>
                  <Section
                    title={t('quantity.title', {
                      defaultValue: 'Select quantity'
                    })}
                  >
                    <QuantityOption
                      quantity={entry.quantity}
                      optional
                      min={minValue}
                      max={maxValue}
                      onAdd={() => {
                        arrayHelpers.push('')
                        entry.incrementQuantity()
                        throttledUpdatePrice()
                      }}
                      onRemove={() => {
                        arrayHelpers.pop()
                        entry.decrementQuantity()
                        throttledUpdatePrice()
                      }}
                      messageAtMaxQuantity={t('quantity.messageAtMaxQuantity', {
                        defaultValue:
                          'For quantities of 10 or more, please contact the Experience Centre team.'
                      })}
                    >
                      {`${label} - ${currencyToString({
                        amount: entry.listPrice.amount,
                        currency: entry.listPrice.currency
                      })}`}
                    </QuantityOption>
                  </Section>
                  {isCustomisable && (
                    <Section
                      containsForm
                      title={t('content.recipient.title', {
                        count: entry.quantity,
                        defaultValue: 'Recipient name'
                      })}
                    >
                      {quantityArray.map((_item: any, index: number) => (
                        <Fragment key={index}>
                          <StepTextInput
                            className="test-step-text-input"
                            maxLength={40}
                            onlyAllowAscii
                            autoComplete="disabled-please"
                            name={`nameOnVoucher.${index}`}
                            label={''}
                            optional
                          />
                        </Fragment>
                      ))}
                    </Section>
                  )}
                </Fragment>
              )
            }}
          />
          <Section
            title={t('content.deliverymethod.title', {
              defaultValue: 'delivery method'
            })}
            role="radiogroup"
          >
            {filteredDeliveryOptions
              .sort((opt1, opt2) => opt1.price.amount - opt2.price.amount) // Sort by price ascending
              .map(({ id, name, price, selected }) => {
                const description = `${name} ${
                  price.amount === 0 ? '' : ' - ' + currencyToString(price)
                }`
                return (
                  <Option key={id} label={description} optional>
                    {filteredDeliveryOptions.length > 1 && (
                      <Radio
                        id={id}
                        checked={selected}
                        name="delivery"
                        aria-labelledby={description}
                        onChange={() => {
                          setDeliveryOption(id)
                        }}
                      />
                    )}
                  </Option>
                )
              })}
          </Section>
        </BoxContainer>
      </WizardPage>
      <WizardPage>
        <OrderSummary basket={basket} catalogue={catalogue} />
      </WizardPage>
      <WizardPage<IGiftVoucherForm>
        validate={values => {
          let errors: object = {}

          if (!isDigitalDelivery && !values.deliveryDetail.addressLine1) {
            errors = {
              deliveryDetail: {
                postalCode: t('content.delivery.noAddress', {
                  defaultValue:
                    'You must search for a postcode or enter an address manually'
                })
              }
            }
          }

          return errors
        }}
        onMount={({ setSubmitting, setFieldValue }) => {
          // Reset order - that is for cases where user started the payment on step 4
          //  and hit back to return to this step - we should remove the existing order
          setSubmitting(false) // Reset set submitting
          setFieldValue('agreed', false) // Reset aggreement on terms
          basket.resetOrder() // Reset order
        }}
        onSubmit={async (values, { setFieldValue }) => {
          // TODO await
          await setDeliveryDetail({
            deliveryType: isDigitalDelivery ? 'email' : 'address',
            ...values.deliveryDetail
          })
          // If billing has the same values, then set them
          // TODO
          if (values.sameBillingDetails && !isDigitalDelivery) {
            setFieldValue('billingAddress', values.deliveryDetail)
            await setBillingAddress({
              deliveryType: isDigitalDelivery ? 'email' : 'address',
              ...values.deliveryDetail
            })
          }
          appStore.navigation.nextStep()
        }}
      >
        <BoxContainer>
          {isDigitalDelivery ? (
            <DigitalDelivery />
          ) : (
            <PhysicalDelivery basket={basket} navigation={navigation} t={t} />
          )}
        </BoxContainer>
      </WizardPage>
      <WizardPage<IGiftVoucherForm>
        top={
          basket.order && (
            <PaymentNotConfirmedMessage
              orderStatus={basket.order.status}
              phone={basket.contactNumber}
            ></PaymentNotConfirmedMessage>
          )
        }
        validate={values => {
          let errors: object = {}

          if (!values.billingAddress.addressLine1) {
            errors = {
              deliveryDetail: {
                postalCode: t('content.delivery.noAddress', {
                  defaultValue:
                    'You must search for a postcode or enter an address manually'
                })
              }
            }
          }

          return errors
        }}
        onSubmit={async values => {
          // If values for billing were different from delivery, save billing address
          if (!values.sameBillingDetails || isDigitalDelivery) {
            await setBillingAddress(values.billingAddress)
          }

          // Enable payment, that will grab a paymentURL that will be displayed in the payment box
          // Start listening to payment
          // Stop here and return only when payment check was done (paymentURL will be created and view rendered anyway)
          // If payment fails, don't advance to next step (that will be done by Wizard)
          await createOrder({
            contactConsentGiven: values.informed,
            tosAccepted: values.agreed,
            checkPaymentManually: catalogue.paymentProvider === 'stripe'
          })

          if (
            catalogue.paymentProvider === 'datacash' &&
            get(basket, 'order.status') === 'paid'
          ) {
            appStore.navigation.nextStep()
          }
        }}
      >
        {get(basket, 'order.status') !== 'error' && (
          <PaymentDetails
            basket={basket}
            catalogue={catalogue}
            navigation={navigation}
            t={t}
            billingAddressRequired
          />
        )}
      </WizardPage>
      <WizardPage<IGiftVoucherForm>
        top={
          <OrderSuccessOrFailureMessage
            order={basket.order}
            navigationId={navigation.id}
          ></OrderSuccessOrFailureMessage>
        }
      >
        <BoxContainer>
          <Section>
            <PrePopulatedInfo
              title={t('prePolutedInfo.payee', {
                defaultValue: 'Payee'
              })}
              info={billingAddress!}
            />
          </Section>
        </BoxContainer>
        <BoxContainer>
          <Section>
            <PrePopulatedInfo
              title={t('prePolutedInfo.recipient', {
                defaultValue: 'Recipient'
              })}
              nameOnVoucher={nameOnVoucher[0] && nameOnVoucher}
              info={deliveryDetail!}
            />
          </Section>
        </BoxContainer>
      </WizardPage>
    </Wizard>
  )
}

export default observer(GiftVoucher)
