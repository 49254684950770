import * as React from 'react'
import { Fragment } from 'react'
import FormText from 'atoms/Form/Text'
import { InjectedTranslateProps } from 'react-i18next'
import StepTextInput from 'molecules/Step/TextInput'
import StepCountrySelectInput from 'molecules/Step/CountrySelectInput'
import Button from 'atoms/Button'
import Link from 'atoms/Link'
import StepSelectInput from 'molecules/Step/SelectInput'
import { FormikProps, connect } from 'formik'
import request from 'tools/request'
import { IGiftVoucherForm } from 'organisms/Scenarios/GiftVoucher'
import { forIn } from 'lodash'

type Address = {
  id: string
  full: string
}

class ContactInput extends React.Component<
  {
    formik: FormikProps<IGiftVoucherForm>
    fieldName: string
    availableCountries?: string[]
  } & InjectedTranslateProps,
  {
    isSearching: boolean
    foundAddresses: boolean
    hiddenValidationErrorsExist: boolean
    addresses: Address[] | undefined
    selectedAddress: Address | undefined
  }
> {
  state = {
    isSearching: false,
    foundAddresses: false,
    hiddenValidationErrorsExist: false,
    addresses: undefined,
    selectedAddress: undefined
  }

  handleAddressSearch = async () => {
    const { fieldName, formik } = this.props

    this.setState(() => ({
      isSearching: true
    }))

    let addresses: Address[] = []

    try {
      const { data } = await request('commerce/address/find', {
        query: {
          country: formik.values[fieldName].country,
          postal_code: formik.values[fieldName].postalCode
        }
      })

      // Format is {{ 0:
      // { address: "1 Merrydale Slaithwaite, Huddersfield, HD7 5UZ",
      //   id: "GB|RM|B|10668221",
      //   nextCode: "RETRIEVE"
      // }}

      const dataKeys = Object.keys(data)
      if (dataKeys.length === 1) {
        this.handleSelectAddress({ value: data[0].id })
      }
      addresses = dataKeys
        .map(key => data[key])
        .map(({ address, id }: { address: string; id: string }) => ({
          id,
          full: address
        }))
    } catch (error) {
      // Continue because default addresses is empty
      // TODO Should we deal with error condition by displaying a different message?
      // it will be displayed with same message as empty addresses
      console.log('[Exception on search address]', error) // tslint:disable-line
    }

    if (!addresses.length) {
      // If no address was found, open it to be edited manually
      this.handleEnterAddressManually()
    }

    this.setState(() => ({
      isSearching: false,
      addresses
    }))
  }

  handleEnterAddressManually = (
    event?: React.MouseEvent<HTMLAnchorElement>
  ) => {
    if (event) {
      event.preventDefault()
    }
    // Set empty address so address area is displayed
    this.setState(() => ({
      selectedAddress: {
        id: '',
        full: '',
        city: '',
        houseName: '',
        streetName: '',
        postcode: ''
      }
    }))
  }

  /* Pass an array of keys whose values you would like to
   * reset on the form */
  resetAddress = (
    fieldsToReset: string[] = [
      'postalCode',
      'addressLine1',
      'addressLine2',
      'postalTown',
      'locality'
    ]
  ) => {
    const {
      fieldName,
      formik: { setFieldValue }
    } = this.props
    this.setState(() => ({ selectedAddress: undefined, addresses: undefined }))

    fieldsToReset.forEach((field: string) => {
      setFieldValue(`${fieldName}.${field}`, '')
    })
  }

  handleResetAddress = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    // Reset selected address
    this.resetAddress()
  }

  handleSelectAddress = async (option: any) => {
    this.setState(() => ({
      isSearching: true
    }))

    const {
      formik: { values, setFieldValue },
      fieldName
    } = this.props

    // Initialise with values we have
    const selectedAddress: any = {
      id: option.value,
      ...values[fieldName]
    }

    try {
      const {
        data: {
          firstName,
          lastName,
          deliveryType,
          contactNumber,
          ...relevantData
        }
      } = await request('commerce/address/retrieve', {
        query: {
          provider_id: option.value
        }
      })

      // Set selected address by merging data acquired and existing values
      forIn(relevantData, (value, key) => {
        selectedAddress[key] = value
      })
    } catch (error) {
      // TODO Handle error
      console.log('[Exception on loading specific address]', error) // tslint:disable-line
    }

    // If succeded or not, we set the selectedAddress (so user will be able to edit form)
    //  and set search is over
    this.setState(() => ({
      selectedAddress,
      isSearching: false
    }))

    // Fill correspondent fields
    setFieldValue(fieldName, selectedAddress)
  }

  render() {
    const {
      t,
      formik: { values },
      fieldName,
      availableCountries
    } = this.props

    const { isSearching, addresses, selectedAddress } = this.state

    return (
      <Fragment>
        <div className="row">
          <StepTextInput
            name={`${fieldName}.firstName`}
            label={t('content.delivery.firstName', {
              defaultValue: 'First name'
            })}
            className="col-md-6"
          />
          <StepTextInput
            name={`${fieldName}.lastName`}
            label={t('content.delivery.lastName', {
              defaultValue: 'Last name'
            })}
            className="col-md-6"
          />
        </div>
        <StepCountrySelectInput
          available={availableCountries}
          name={`${fieldName}.country`}
          label={t('content.delivery.country', {
            defaultValue: 'Country'
          })}
        />

        {!selectedAddress && (
          <Fragment>
            <StepTextInput
              name={`${fieldName}.postalCode`}
              disabled={isSearching || !values[fieldName].country}
              label={t('content.delivery.postalCodeSearch', {
                defaultValue: 'Postcode'
              })}
              addon={
                <Button
                  disabled={
                    !values[fieldName].postalCode ||
                    isSearching ||
                    !values[fieldName].country
                  }
                  onClick={this.handleAddressSearch}
                >
                  {t('content.delivery.findAddress', {
                    defaultValue: 'Find address'
                  })}
                </Button>
              }
              helper={
                <Fragment>
                  <Link
                    onClick={this.handleEnterAddressManually}
                    data-test="EnterAddressManually"
                  >
                    {t('content.delivery.enterManually', {
                      defaultValue: 'Enter Address Manually'
                    })}
                  </Link>
                </Fragment>
              }
            />
            {(isSearching ||
              (addresses && !!(addresses as Address[]).length)) && (
              <StepSelectInput
                name={`addressSelect`}
                isLoading={isSearching}
                isDisabled={isSearching}
                label={
                  isSearching
                    ? t('content.delivery.loading', {
                        defaultValue: 'Please wait, loading addresses...'
                      })
                    : t('content.delivery.selectAddress', {
                        defaultValue: 'Select Address'
                      })
                }
                options={
                  Array.isArray(addresses)
                    ? addresses.map(address => ({
                        label: address.full,
                        value: address.id
                      }))
                    : []
                }
                optional
                onChange={this.handleSelectAddress}
                helper={
                  <Link onClick={this.handleEnterAddressManually}>
                    {t('content.delivery.cantSeeAddress', {
                      defaultValue: "I can't see my address?" // tslint:disable-line
                    })}
                  </Link>
                }
              />
            )}
          </Fragment>
        )}

        {addresses && !(addresses as Address[]).length && (
          <FormText bold>
            {t('content.delivery.addressNotFound', {
              defaultValue:
                "Sorry, we couldn't find your address. Please enter it below." // tslint:disable-line
            })}
          </FormText>
        )}
        {(selectedAddress ||
          values[fieldName].addressLine1 ||
          values[fieldName].addressLine2 ||
          values[fieldName].postalTown ||
          this.state.hiddenValidationErrorsExist) && (
          <Fragment>
            <StepTextInput
              name={`${fieldName}.addressLine1`}
              label={t('content.delivery.addressLine1', {
                defaultValue: 'House Name/No'
              })}
            />
            <StepTextInput
              optional
              name={`${fieldName}.addressLine2`}
              label={t('content.delivery.addressLine2', {
                defaultValue: 'Street Name'
              })}
            />
            <StepTextInput
              optional
              name={`${fieldName}.postalTown`}
              label={t('content.delivery.locality', {
                defaultValue: 'Town/City'
              })}
            />
            <StepTextInput
              name={`${fieldName}.postalCode`}
              label={t('content.delivery.postalCode', {
                defaultValue: 'Postcode'
              })}
              helper={
                <Link onClick={this.handleResetAddress}>
                  {t('content.delivery.searchAnotherAddress', {
                    defaultValue: 'Search for another address'
                  })}
                </Link>
              }
            />
          </Fragment>
        )}
        <StepTextInput
          name={`${fieldName}.email`}
          label={t('content.delivery.email', {
            defaultValue: 'Email'
          })}
          type="email"
        />
        <StepTextInput
          name={`${fieldName}.contactNumber`}
          label={t('content.delivery.phone', {
            defaultValue: 'Phone number'
          })}
          type="phone"
        />
      </Fragment>
    )
  }
}

export default connect<any, any>(ContactInput)
