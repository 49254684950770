/* eslint-disable */
import * as React from 'react'
import ThemeSelect from 'organisms/DebugTab/ThemeSelect'
import PrivacySelect from 'organisms/DebugTab/PrivacySelect'
import LanguageSelect from 'organisms/DebugTab/LanguageSelect'
import { ICatalogue } from 'stores/Catalogue'
import { inject, observer } from 'mobx-react'
import { IConfiguration } from 'stores/Configuration'

type State = {
  sku: string
}
export class DebugTab extends React.Component<
  {
    catalogue?: ICatalogue
    configuration?: IConfiguration
  },
  State
> {
  state = {
    sku: this.props.configuration.sku
  }

  handleSkuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const sku = event.target.value
    this.setState(() => ({ sku }))
  }

  handleChange = (
    name: string,
    value: string | React.ChangeEvent<HTMLButtonElement>
  ) => {
    if (name === 'sku') {
      this.props.configuration.update(name, this.state.sku)
    } else {
      this.props.configuration.update(name, value)
    }
  }

  render() {
    const {
      configuration: { debug, sku, theme, privacy, language }
    } = this.props

    if (!debug) {
      return null
    }

    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light border-top border-bottom">
        <a className="navbar-brand" href="#">
          DEBUG
        </a>
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <ThemeSelect
              theme={theme}
              onChange={this.handleChange.bind(this, 'theme')}
            />
          </li>
          <li className="nav-item">
            <LanguageSelect
              language={language}
              onChange={this.handleChange.bind(this, 'language')}
            />
          </li>
          <li className="nav-item">
            <PrivacySelect
              privacy={privacy}
              onChange={this.handleChange.bind(this, 'privacy')}
            />
          </li>
          <form className="form-inline my-2 my-lg-0">
            <div className="input-group input-group-sm">
              <input
                type="text"
                className="form-control"
                placeholder="Item Id"
                aria-label="Item Id"
                value={sku}
                onChange={this.handleSkuChange}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.handleChange.bind(this, 'sku')}
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </ul>

        <span className="ml-2 navbar-text">
          git:{process.env.REACT_APP_GIT_VERSION || '?'} npm:
          {process.env.REACT_APP_PACKAGE_VERSION || '?'}
        </span>
      </nav>
    )
  }
}

export default inject(({ appStore }) => {
  return {
    catalogue: appStore.catalogue as ICatalogue,
    configuration: appStore.configuration as IConfiguration
  }
})(observer(DebugTab))
