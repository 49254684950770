import * as React from 'react'
import classnames from 'classnames'
import Input, { IInput } from 'atoms/Form/Input'
import ReactSelect from 'react-select'
import { Props } from 'react-select/lib/Select' // tslint:disable-line
import { OptionsType } from 'react-select/lib/types' // tslint:disable-line
import styled, { withTheme, IThemeInterface } from 'tools/theme'
import { transparentize } from 'polished'

type IInternalSelectInput<OptionType> = Props<OptionType> & {
  initial?: any
  options?: OptionsType<OptionType>
} & IInput

// ReactSelect<{ value: string; label: string }> &

export type ISelectInput = IInternalSelectInput<{
  value: string
  label: string
}>

const SelectInputContainer = styled(Input)`
  && {
    .form-control {
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
`

export class SelectInput extends React.Component<
  ISelectInput & {
    theme?: IThemeInterface
  },
  {
    hasMenuOpen: boolean
  }
> {
  state = {
    hasMenuOpen: false
  }

  handleOnMenuOpen = () => {
    this.setState(() => ({ hasMenuOpen: true }))
  }

  handleOnMenuClose = () => {
    this.setState(() => ({ hasMenuOpen: false }))
  }

  render() {
    const { onBlur, onFocus, isInline, ...others } = this.props
    const { hasMenuOpen } = this.state
    const theme = {
      danger: 'red',
      darkCoolGrey: 'black'
    }

    const propValue = this.props.value || { value: '', label: '' }

    /* TODO: // Make sure label on top works with automcomplete */
    return (
      <SelectInputContainer
        {...others}
        className={classnames({
          'mb-0': isInline
        })}
      >
        <ReactSelect<{ value: string; label: string }>
          data-test="SelectInputContainer"
          placeholder=""
          className={classnames({
            'is-invalid': !!this.props.error,
            'label-on-top': hasMenuOpen || !!propValue // If has value or menu is open (and value is empty)
          })}
          classNamePrefix="select"
          name={this.props.name}
          onMenuOpen={this.handleOnMenuOpen}
          onMenuClose={this.handleOnMenuClose}
          styles={{
            singleValue: (base /*, state*/) => {
              return {
                ...base,
                letterSpacing: '1.1px',
                color: this.props.error ? theme!.danger : 'inherit'
              }
            },
            valueContainer: (base /*, state*/) => {
              return {
                ...base,
                padding: '0.4rem 1.4rem'
              }
            },
            menuList: base => {
              return { ...base, paddingBottom: 0, paddingTop: 0 }
            },
            indicatorsContainer: base => {
              return {
                ...base,
                zIndex: 500,
                transform: hasMenuOpen ? 'rotateX(180Deg)' : 'rotateX(0Deg)'
              }
            },
            indicatorSeparator: base => {
              return { ...base, width: 0 }
            },
            option: (base, state) => {
              return {
                ...base,
                letterSpacing: '0.7px',
                backgroundColor: 'transparent',
                color: state.isFocused
                  ? theme!.darkCoolGrey
                  : transparentize(0.3, theme!.darkCoolGrey)
              }
            },
            menu: base => {
              return {
                ...base,
                top: '1px',
                // transition: 'all 1000ms',
                borderRadius: 0,
                marginTop: 45,
                boxShadow: 'none',
                zIndex: 200,
                border: `1px solid ${transparentize(0.6, theme!.darkCoolGrey)}`,
                borderTop: 'none'
              }
            },
            control: base => {
              return {
                ...base,
                '&:hover': {
                  borderColor: this.props.error
                    ? theme!.danger
                    : transparentize(0.6, theme!.darkCoolGrey)
                },
                borderColor: this.props.error
                  ? theme!.danger
                  : transparentize(0.6, theme!.darkCoolGrey),
                backgroundColor: 'transparent',
                borderRadius: 0,
                minHeight: 46,
                boxShadow: 'none'
              }
            }
          }}
          options={this.props.options}
          value={propValue}
        />
      </SelectInputContainer>
    )
  }
}

export default withTheme(SelectInput)
