import React from 'react'
import {CardElement} from '@stripe/react-stripe-js'

// Stripe Style object properties: https://stripe.com/docs/js/appendix/style
const style = {
  style: {
    base: {
      color: "#212529",
      fontFamily: '"AvenirLTPro", Arial, Helvetica, Sans-Serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#8c8c8c"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
}

const CardPaymentSection = () => {
  // We're using Stripe Elements (prebuilt front-end components). The CardElement is the basic all-in-one that adds
  // necessary card info fields to the form.
  // If we want to customize how the fields appear, or if we want to add or remove fields, we can do that with
  // different Elements. See: https://github.com/stripe/react-stripe-elements
  return (
    <CardElement className="StripeCardElement" options={style} />
  )
}

export default CardPaymentSection
