import styled, { media } from 'tools/theme'
import { transparentize } from 'polished'

export const Container = styled.div`
  ${media.xs`
    padding: 1.6rem 0 0;
  `};

  padding: 3.5rem 3.2rem 0;
  background-color: ${props => props.theme.extraLightGrey};

  .addon {
    border-top: ${props => `1px solid ${props.theme.mediumGrey}`};

    &.first {
      border-top: ${props =>
        `2px solid ${transparentize(0.7, props.theme.darkCoolGrey)}`};
    }
  }
`
