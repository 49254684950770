import { types, getRoot } from 'mobx-state-tree'
import Parameter, { IParameter } from 'stores/Catalogue/Parameter'
import { IImageUrl } from 'stores/Configuration'
import { find } from 'lodash'

const Media = types.model('media', {
  type: types.string,
  uri: types.string
})

const Location = types.model('Location', {
  uuid: types.string,
  name: types.string
})

const Organisation = types.model('Organisation', {
  paymentProvider: types.maybe(types.string),
  paymentMethods: types.maybe(types.array(types.string)),
  stripePublishableKey: types.maybe(types.string)
})

const Item = types
  .model('CatalogueItem', {
    sku: types.identifier(types.string),
    itemType: types.enumeration('ItemType', [
      'EVENT',
      'EVENT_GROUP',
      'PRODUCT'
    ]),
    name: types.string,
    media: types.optional(types.array(Media), []),
    description: types.maybe(types.string),
    parameters: types.optional(types.array(Parameter), []),
    customisable: types.optional(types.boolean, false),
    allocation: types.maybe(
      types.enumeration('Allocation', ['TIMESLOT', 'DAY'])
    ),
    unit: Location,
    importantNotice: types.maybe(types.string),
    organisation: types.maybe(Organisation)
  })
  .views(self => ({
    get isTimeslot(): boolean {
      return self.allocation === 'TIMESLOT'
    },
    get imageUrl(): IImageUrl {
      return getRoot(self).configuration.imageUrl({
        category: 'centre',
        placement: 'hero',
        identifier: self.unit.uuid
      })
    },
    get isEvent(): boolean {
      return self.itemType === 'EVENT_GROUP' || self.itemType === 'EVENT'
    },
    get isAdventureTravel(): boolean {
      return self.itemType === 'EVENT_GROUP'
    },
    get defaultParameters(): { [s: string]: number } {
      return self.parameters.reduce((acc, parameter) => {
        acc[parameter.id] = parameter.defaultValue
        return acc
      }, {})
    },
    get parameterSelection(): string {
      return self.parameters
        .filter(param => param.isVisible)
        .map(({ label, value }) => (value ? `${value} ${label}` : ''))
        .filter(empty => !!empty)
        .join(' / ')
    },
    get paymentProvider(): string {
      return self.organisation.paymentProvider
    },
    get paymentMethods() {
      return self.organisation.paymentMethods
    }
  }))
  .actions(self => ({
    setParametersValue: (parameters: { [s: string]: number }) => {
      self.parameters.forEach(param => {
        if (typeof parameters[param.id] !== 'undefined') {
          param.value = parameters[param.id]
        }
      })
    },
    getParametersValue(id: string): IParameter | undefined {
      return find(self.parameters, { id, isVisible: true })
    }
  }))

export type IItem = typeof Item.Type
export type IItemSnapshot = typeof Item.SnapshotType
export default Item
