/* eslint-disable */
import * as React from 'react'
import { Field, FieldProps } from 'formik'
import TextInput, { ITextInput } from 'atoms/Form/TextInput'
import { omit, isString } from 'lodash'
import * as Yup from 'yup'

interface ValidationProps {
  maxLength?: number
  onlyAllowAscii?: any
}

const StepTextInput: React.SFC<ITextInput & ValidationProps> = ({
  name,
  ...props
}) => (
  <Field
    name={name}
    validate={async (value: string) => {
      let schema = Yup.string().label(props.label)

      if (props.maxLength) {
        schema.max(props.maxLength)
      }

      let optional = props.optional
      if (isString(optional)) {
        optional = JSON.parse(optional)
      }
      if (!optional) {
        schema = schema.required()
      }

      if (props.type) {
        if (props.type === 'email') {
          schema = schema.email()
        }
      }

      if (props.onlyAllowAscii) {
        schema = schema.matches(
          /^[\x00-\x7F]*$/,
          'Please use only standard characters.'
        )
      }

      try {
        await schema.validate(value)
      } catch (error) {
        return error.errors[0]
      }
    }}
    render={({ field, form }: FieldProps<{ [s: string]: string }>) => {
      if (field.value === undefined) {
        field.value = ''
      }
      const propsForTextInput = omit(props, 'onlyAllowAscii')
      return (
        <TextInput
          {...propsForTextInput}
          {...field}
          error={
            !!form.status
              ? name.split('.').reduce((acc: any, partialName: string) => {
                  return acc && partialName ? acc[partialName] : undefined
                }, form.errors)
              : ''
          }
        />
      )
    }}
  />
)

export default StepTextInput
