import { types, clone } from 'mobx-state-tree'
import TimeRange from './TimeRange'
import TimetableParameter from './TimetableParameter'
import i18nFactory from 'tools/i18n'
import {
  isSameDay,
  addDays,
  isWithinRange,
  startOfDay,
  endOfDay,
  format
} from 'date-fns'

const DateRange = types
  .model('DateRange', {
    available: types.boolean,
    date: types.Date,
    type: types.enumeration('dateRangeType', ['multiday', 'timeslot', 'day']),
    duration: types.number,
    eventTimes: types.array(TimeRange),
    baseInventory: types.maybe(types.number),
    parameters: types.optional(types.array(TimetableParameter), [])
  })
  .views(self => ({
    get toRange(): { from: Date; to: Date } {
      return {
        from: startOfDay(self.date),
        to: endOfDay(addDays(self.date, self.duration - 1))
      }
    }
  }))
  .views(self => ({
    isWithinDateRange: (date?: Date): boolean => {
      if (!date) {
        return false
      }
      const { from, to } = self.toRange
      return isWithinRange(date, from, to)
    },
    equalsDate: (dateRange?: IDateRange): boolean => {
      if (!dateRange) {
        return false
      }
      const compareDate = clone(dateRange)
      return (
        isSameDay(compareDate.date, self.date) &&
        compareDate.duration === self.duration
      )
    },
    toString(
      {
        showYear,
        showDuration
      }: { showYear?: boolean; showDuration?: boolean } = {
        showYear: false,
        showDuration: false
      }
    ): string {
      const formatString = `ddd D MMM${showYear ? ' YYYY' : ''}`
      const { from, to } = self.toRange

      return !isSameDay(from, to)
        ? `${format(from, formatString)} - ${format(to, formatString)} ${
            showDuration
              ? `(${self.duration} ${i18nFactory().t('DateRange.days', {
                  defaultValue: 'days'
                })})`
              : ''
          }`
        : format(from, formatString)
    }
  }))

type DateRangeType = typeof DateRange.Type
export interface IDateRange extends DateRangeType {}
export type IDateRangeSnapshot = typeof DateRange.SnapshotType
export default DateRange
