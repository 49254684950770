import { types } from 'mobx-state-tree'

const Parameter = types
  .model('CatalogueItemParameter', {
    id: types.identifier(types.string),
    label: types.string,
    minValue: types.number,
    maxValue: types.number,
    inventoryLinked: types.boolean,
    defaultValue: types.number,
    required: types.boolean,
    value: types.maybe(types.number),
    visibility: types.enumeration('Visibility', ['HIDDEN', 'VISIBLE'])
  })
  .views(self => ({
    get isVisible() {
      return self.visibility === 'VISIBLE'
    }
  }))

export type IParameter = typeof Parameter.Type
export type IParameterSnapshot = typeof Parameter.SnapshotType
export default Parameter
