import * as React from 'react'

const MinusIcon: React.SFC<{}> = () => (
  <svg
    width="11px"
    height="1px"
    viewBox="0 0 11 1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" fill="none">
      <g transform="translate(0.000000, -5.000000)">
        <path
          d="M5.95833333,5.04166667 L5.04166667,5.04166667 L0.458333333,5.04166667 C0.204875,5.04166667 0,5.24654167 0,5.5 C0,5.75345833 0.204875,5.95833333 0.458333333,5.95833333 L5.04166667,5.95833333 L5.95833333,5.95833333 L10.5416667,5.95833333 C10.795125,5.95833333 11,5.75345833 11,5.5 C11,5.24654167 10.795125,5.04166667 10.5416667,5.04166667 L5.95833333,5.04166667 Z" // tslint:disable-line
          fill="#0C121C"
        />
      </g>
    </g>
  </svg>
)

export default MinusIcon
