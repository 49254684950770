import * as React from 'react'
import { IConfiguration } from 'stores/Configuration'
import { IAppStore } from 'stores/AppStore'
import { initTheme } from 'tools/theme'
import i18nFactory from 'tools/i18n'
import i18n from 'i18next'
import { initAnalytics } from 'tools/analytics'
import { inject, observer } from 'mobx-react'
import { log } from 'tools/log'
import { getEnv } from 'config'
import * as Sentry from '@sentry/browser'

const env = getEnv()

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    release: `BuySdk_v_${process.env.REACT_APP_GIT_VERSION}`,
    environment: env,
    dsn: 'https://ee704d0e14a44f7a94ea6f14b91ae7d0@sentry.io/1449567'
  })
}

type RenderPropsArgs = IConfiguration & { i18n: i18n.i18n }

type RenderProps = (args: RenderPropsArgs) => JSX.Element

type Props = {
  isInitialising?: boolean
  initialisationError?: string
  config?: IConfiguration
  children: RenderProps
  appStore?: IAppStore
}

type State = {
  resourcesInitialised: boolean
  renderingError?: string
}

class Initialisation extends React.Component<Props, State> {
  state = {
    resourcesInitialised: false,
    renderingError: undefined
  }

  initialiseResources = () => {
    const {
      config: {
        theme,
        privacy,
        language,
        appId,
        fbpx,
        currency,
        analyticsId,
        hostnameOrigin,
        hostnameBuySdk
      }
    } = this.props

    log('>>> Initialising now!!!')

    // Initialise theme for application
    initTheme({ theme })

    // Initiliase Analytics
    initAnalytics({
      appStore: this.props.appStore,
      privacy,
      appId,
      currency,
      analyticsId,
      hostnameBuySdk,
      hostnameOrigin,
      fbpx
    })

    // Initialise i18n support
    i18nFactory({ language })

    // Setting as initialised
    this.setState(() => ({ resourcesInitialised: true }))
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    log('[Render Error]', error, info)
    this.setState(() => ({
      renderingError: `An error occurred while rendering: ${error} ${info}`
    }))
  }

  componentDidMount() {
    // If store is not initialising after mount, load resource
    if (!this.props.isInitialising) {
      this.initialiseResources()
    }
  }

  componentDidUpdate(prevProps: Props) {
    // If become initialised after rendered, then initialise resources
    if (prevProps.isInitialising && !this.props.isInitialising) {
      this.initialiseResources()
    }
  }

  render() {
    const { children, config, initialisationError } = this.props
    const { resourcesInitialised, renderingError } = this.state

    if (!resourcesInitialised) {
      // TODO maybe show something for user to wait or configure pace to wait for that
      log('>>> Waiting for initialisation')
      return null
    }

    log('>>> Initialised, rendering...')

    return (
      <div className="initialised">
        {children({
          ...config,
          i18n: i18nFactory(),
          initialisationError: initialisationError || renderingError
        })}
      </div>
    )
  }
}

export default inject(({ appStore }) => {
  return {
    isInitialising: appStore.isInitialising as boolean,
    initialisationError: appStore.initialisationError,
    config: appStore.configuration as IConfiguration,
    appStore
  }
})(observer(Initialisation))
